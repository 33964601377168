import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation } from "react-router-dom";
import api from '../../utils/api';
import {notify} from "../../utils/notify";
import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Painel extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			range: "7",
			days: [],
			g: [],
			i: [],
			f: [],
			y: [],
			k: [],
			t: [],
			w: [],
			visitors: 0,
			limit: ["2022-11-10", new Date().toISOString().split('T')[0]]
		}
	}

	onload() {
		document.title = "Painel • BackOffice";
	}

	setGraph() {

		window.setBoChart('chart-traffic',
			{
				chart: {
					type: "line",
					fontFamily: 'inherit',
					height: 250,
					parentHeightOffset: 0,
					toolbar: {
						show: false,
					},
					animations: {
						enabled: false
					},
				},
				fill: {
					opacity: 1,
				},
				stroke: {
					width: 2,
					lineCap: "round",
					curve: "smooth",
				},
				series: [
					{
						name: "Google e outros",
						data: this.state.g
					},
					{
						name: "Instagram",
						data: this.state.i
					},
					{
						name: "Facebook",
						data: this.state.f
					},
					{
						name: "Youtube",
						data: this.state.y
					},
					{
						name: "TikTok",
						data: this.state.k
					},
					{
						name: "Twitter",
						data: this.state.t
					},
					{
						name: "WhatsApp",
						data: this.state.w
					}
				],
				grid: {
					padding: {
						top: -20,
						right: 0,
						left: -4,
						bottom: -4
					},
					strokeDashArray: 4,
					xaxis: {
						lines: {
							show: true
						}
					},
				},
				xaxis: {
					type: 'category',
					categories: [],
					tickAmount: undefined,
					tickPlacement: 'between',
					min: undefined,
					max: undefined,
					range: undefined,
					floating: false,
					decimalsInFloat: undefined,
					overwriteCategories: undefined,
					position: 'bottom',
					labels: {
						show: true,
						rotate: -20,
						rotateAlways: false,
						hideOverlappingLabels: true,
						showDuplicates: false,
						trim: false,
						maxHeight: 70
					},
					axisTicks: {
						show: true,
						borderType: 'solid',
						color: '#78909C',
						height: 6,
						offsetX: 0,
						offsetY: 0
					},
					tooltip: {
						enabled: false
					},
				},
				yaxis: {
					labels: {
						padding: 4
					},
				},
				labels: this.state.days,
				colors: ["#f59f00", "#ae3ec9", "#206bc4", "#d63939", "#232e3c", "#4299e1", "#2fb344"],
				legend: {
					show: false
				},
			}
		);
	}

	componentDidMount() {
		this.onload();

		this.setGraph();
		this.initGraph(this.state.range);
	}

	componentDidUpdate() {
		this.onload();
	}

	pct(value, get) {
		if (this.state.days.length) {
			var total = 0;
			this.state[value].map(item => {
				total += item;
			})
			if (get) {
				return total;
			} else {
				var valor = Number(total) / this.state.visitors;
				var pct = valor * 100;

				return !isNaN(Number(pct).toFixed(2)) ? Number(pct).toFixed(2) + '%' : '0%';
			}
		}
	}

	initGraph(range) {
		const _this = this;

		this.setState({ range: range }, () => {
			notify('loading', 'A carregar...', Infinity, false)
			api.post("trafficgraph", { range: _this.state.range }, {
				headers: {
					"X-Requested-With": "XMLHttpRequest"
				}
			}).then((response) => {
				_this.graph(response.data);
			}, (error) => {
				alert('Erro');
			});
		});
	}

	graph(data) {
		var datakeys = Object.keys(data);

		var obj = {
			days: [],
			g: [],
			i: [],
			f: [],
			y: [],
			k: [],
			t: [],
			w: [],
			visitors: 0
		}


		datakeys.forEach(key => {
			const month = key.split('-')[1] + '/' + key.split('-')[0]

			Object.keys(data[key]).forEach(k => {
				const zDay = parseInt(k) < 10 ? '0' + k : k;
				obj.days.push(zDay + '/' + month);
				obj.g.push(data[key][k].g);
				obj.i.push(data[key][k].i);
				obj.f.push(data[key][k].f);
				obj.y.push(data[key][k].y);
				obj.k.push(data[key][k].k);
				obj.t.push(data[key][k].t);
				obj.w.push(data[key][k].w);

				obj.visitors += data[key][k].g + data[key][k].i + data[key][k].f + data[key][k].y + data[key][k].k + data[key][k].t + data[key][k].w;
			});
		});


		this.setState({
			days: obj.days,
			g: obj.g,
			i: obj.i,
			f: obj.f,
			y: obj.y,
			k: obj.k,
			t: obj.t,
			w: obj.w,
			visitors: obj.visitors
		}, () => {
			window['chart_traffic'].destroy();
			this.setGraph();
		})
	}

	validatecustom(a, value) {
		if (value < this.state.limit[0] || value > this.state.limit[1]) {
			if (a == "start") {
				document.getElementById('traffic-datepicker-start').value = "";
			} else {
				document.getElementById('traffic-datepicker-end').value = "";
			}
		}
	}

	applyInterval() {
		const start = document.getElementById('traffic-datepicker-start').value;
		const end = document.getElementById('traffic-datepicker-end').value;

		var diffInMs   = new Date(end) - new Date(start)
		var diffInDays = diffInMs / (1000 * 60 * 60 * 24);
		

		if(start > end) {
			alert('A data de começo não pode ser superior à data do fim.')
		} else if(diffInDays > 90) {
			alert('O intervalo de tempo não pode ser maior que 90 dias.')
		} else {
			notify('loading', 'A carregar...', Infinity, false)
			const _this = this;

			api.post("trafficgraph", {
				range: _this.state.range,
				start: start,
				end: end
			}, {
				headers: {
					"X-Requested-With": "XMLHttpRequest"
				}
			}).then((response) => {
				_this.graph(response.data);
			}, (error) => {
				alert('Erro');
			});
		}
	}

    dirIndicator() {
        var u = Number(this.state.g[this.state.g.length - 1]) + Number(this.state.i[this.state.i.length - 1]) + Number(this.state.f[this.state.f.length - 1]) + Number(this.state.y[this.state.y.length - 1]) + Number(this.state.k[this.state.k.length - 1]) + Number(this.state.t[this.state.t.length - 1]) + Number(this.state.w[this.state.w.length - 1]);
		var p = Number(this.state.g[this.state.g.length - 2]) + Number(this.state.i[this.state.i.length - 2]) + Number(this.state.f[this.state.f.length - 2]) + Number(this.state.y[this.state.y.length - 2]) + Number(this.state.k[this.state.k.length - 2]) + Number(this.state.t[this.state.t.length - 2]) + Number(this.state.w[this.state.w.length - 2]);
        var pct = null;
        var color = null;
        var dir = null;
        if (p < u) {
            pct = Math.ceil(((u - p) / p) * 100) + '%';
            color = "text-green";
            dir = <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="3 17 9 11 13 15 21 7" /><polyline points="14 7 21 7 21 14" /></svg>;
        } else if (p > u) {
            pct = Math.ceil(((u - p) / p) * 100) + "%";
            color = "text-red";
            dir = <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="3 7 9 13 13 9 21 17" /><polyline points="21 10 21 17 14 17" /></svg>;
        } else {
            pct = "0%";
            color = "text-yellow";
            dir = <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1={5} y1={12} x2={19} y2={12} /></svg>;
        }
        return <span className={`${color} d-inline-flex align-items-center lh-1`}>{pct}{dir}</span>
    }

	render() {
		return (
			<>
				<div className="col-12">
					<div className="card">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<h3 className="card-title">Tráfego analytics</h3>
								{
                                    this.state.range != "custom" &&

                                    <div className="me-auto ms-2" style={{marginTop: '-15px'}}>{this.dirIndicator()}</div>
                                }
								<div className="ms-auto lh-1" style={{ marginTop: "-15px" }}>
									<div className="dropdown">
										<a className="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{
												{
													"7": "Últimos 7 dias",
													"30": "Últimos 30 dias",
													"month": "Desde o início do mês",
													"custom": "Intervalo personalizado"
												}[this.state.range]
											}
										</a>
										<div className="dropdown-menu dropdown-menu-end">
											<a className={`dropdown-item ${!this.state.range || this.state.range == "7" ? 'active' : ''}`} onClick={() => this.initGraph("7")}>Últimos 7 dias</a>
											<a className={`dropdown-item ${this.state.range == "30" ? 'active' : ''}`} onClick={() => this.initGraph("30")}>Últimos 30 dias</a>
											<a className={`dropdown-item ${this.state.range == "month" ? 'active' : ''}`} onClick={() => this.initGraph("month")}>Desde o início do mês</a>
											<a className={`dropdown-item ${this.state.range == "custom" ? 'active' : ''}`} onClick={() => this.initGraph("custom")}>Intervalo personalizado</a>
										</div>
									</div>
								</div>
							</div>
							{
								this.state.range == "custom" &&

								<div className="row">
									<div className="col">
										<label className="form-label">De</label>
										<div className="input-icon mb-2">
											<span className="input-icon-addon">
												<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<rect x={4} y={5} width={16} height={16} rx={2} />
													<line x1={16} y1={3} x2={16} y2={7} />
													<line x1={8} y1={3} x2={8} y2={7} />
													<line x1={4} y1={11} x2={20} y2={11} />
													<line x1={11} y1={15} x2={12} y2={15} />
													<line x1={12} y1={15} x2={12} y2={18} />
												</svg>
											</span>
											<input className="form-control" id="traffic-datepicker-start" type="date" min={this.state.limit[0]} max={this.state.limit[1]} onChange={e => this.validatecustom('start', e.target.value)} />
										</div>
									</div>
									<div className="col">
										<label className="form-label">Até</label>
										<div className="row">
											<div className="col">
												<div className="input-icon mb-2">
													<span className="input-icon-addon">
														<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
															<path stroke="none" d="M0 0h24v24H0z" fill="none" />
															<rect x={4} y={5} width={16} height={16} rx={2} />
															<line x1={16} y1={3} x2={16} y2={7} />
															<line x1={8} y1={3} x2={8} y2={7} />
															<line x1={4} y1={11} x2={20} y2={11} />
															<line x1={11} y1={15} x2={12} y2={15} />
															<line x1={12} y1={15} x2={12} y2={18} />
														</svg>
													</span>
													<input className="form-control" id="traffic-datepicker-end" type="date" min={this.state.limit[0]} max={this.state.limit[1]} onChange={e => this.validatecustom('end', e.target.value)} />
												</div>
											</div>
											<div className="col-auto">
												<button className="btn btn-primary btn-icon" onClick={() => this.applyInterval()}>
													<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<path d="M5 12l5 5l10 -10"></path>
													</svg>
												</button>
											</div>
										</div>
									</div>
								</div>
							}

							<div id="chart-traffic" className="chart-lg" />
						</div>
					</div>
				</div>

				<div className="col-12">
					<div className="card">
						<div className="card-body cursor-default">
							<p className="mb-3">
								<strong>{this.state.visitors} visitas </strong>
								{
									{
										"7": "nos últimos 7 dias",
										"30": "nos últimos 30 dias",
										"month": "desde o início do mês",
										"custom": "neste intervalo de tempo"
									}[this.state.range]
								}
							</p>
							<div className="progress progress-separated mb-3">
								<div className="progress-bar" role="progressbar" title="Google e outros" style={{ width: this.pct('g'), background: '#f59f00' }} />
								<div className="progress-bar" role="progressbar" title="Instagram" style={{ width: this.pct('i'), background: '#ae3ec9' }} />
								<div className="progress-bar" role="progressbar" title="Facebook" style={{ width: this.pct('f'), background: '#206bc4' }} />
								<div className="progress-bar" role="progressbar" title="Youtube" style={{ width: this.pct('y'), background: '#d63939' }} />
								<div className="progress-bar" role="progressbar" title="TikTok" style={{ width: this.pct('k'), background: '#232e3c' }} />
								<div className="progress-bar" role="progressbar" title="Twitter" style={{ width: this.pct('t'), background: '#4299e1' }} />
								<div className="progress-bar" role="progressbar" title="WhatsApp" style={{ width: this.pct('w'), background: '#2fb344' }} />
							</div>
							<div className="row">
								<div className="col-auto d-flex align-items-center ps-2">
									<span className="legend me-2 bg-yellow" />
									<span>Google e outros</span>
									<span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted"><div className="subheader"><span className="text-dark">{this.pct('g', 'total')}</span> {' (' + this.pct('g') + ')'}</div></span>
								</div>
								<div className="col-auto d-flex align-items-center pe-2">
									<span className="legend me-2 bg-purple" />
									<span>Instagram</span>
									<span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted"><div className="subheader"><span className="text-dark">{this.pct('i', 'total')}</span> {' (' + this.pct('i') + ')'}</div></span>
								</div>
								<div className="col-auto d-flex align-items-center ps-2">
									<span className="legend me-2 bg-blue" />
									<span>Facebook</span>
									<span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted"><div className="subheader"><span className="text-dark">{this.pct('f', 'total')}</span> {' (' + this.pct('f') + ')'}</div></span>
								</div>
								<div className="col-auto d-flex align-items-center px-2">
									<span className="legend me-2 bg-red" />
									<span>Youtube</span>
									<span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted"><div className="subheader"><span className="text-dark">{this.pct('y', 'total')}</span> {' (' + this.pct('y') + ')'}</div></span>
								</div>
								<div className="col-auto d-flex align-items-center px-2">
									<span className="legend me-2 bg-dark" />
									<span>TikTok</span>
									<span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted"><div className="subheader"><span className="text-dark">{this.pct('k', 'total')}</span> {' (' + this.pct('k') + ')'}</div></span>
								</div>
								<div className="col-auto d-flex align-items-center ps-2">
									<span className="legend me-2 bg-azure" />
									<span>Twitter</span>
									<span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted"><div className="subheader"><span className="text-dark">{this.pct('t', 'total')}</span> {' (' + this.pct('t') + ')'}</div></span>
								</div>
								<div className="col-auto d-flex align-items-center ps-2">
									<span className="legend me-2 bg-green" />
									<span>WhatsApp</span>
									<span className="d-none d-md-inline d-lg-none d-xxl-inline ms-2 text-muted"><div className="subheader"><span className="text-dark">{this.pct('w', 'total')}</span> {' (' + this.pct('w') + ')'}</div></span>
								</div>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Painel;