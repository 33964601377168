function fontag(params, type) {
    if (params) {
        if (type == "t") {
            if (params.indexOf('<font ') > -1) {
                var parsed = new DOMParser().parseFromString(params, 'text/html');

                return parsed.querySelector('font').innerHTML
            } else {
                return params;
            }
        }
        if (type == "c") {
            if (params.indexOf('<font ') > -1) {
                var parsed = new DOMParser().parseFromString(params, 'text/html');

                return parsed.querySelector('font').getAttribute('color')
            } else {
                return "#000000";
            }
        }

    } else {
        return "";
    }

}

export default fontag;