import React from 'react';
import { useParams } from "react-router-dom";

import Page from '../views/workplace/Page';
import Search from '../views/workplace/Search';

function Res(props) {
    const { id } = useParams();

    switch (props.type) {
        case "Page":
            return <Page id={id} />;
            break;
        case "Search":
            return <Search id={id} />;
            break;
        default:
            return (null)
    }
}

export default Res;