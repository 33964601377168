import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation, Link } from "react-router-dom";

import api from "../../utils/api";

import { notify, closeNotify } from "../../utils/notify";
import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Page extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			languages: [],
			translations: [],
			editRow: 0,
			allLanguages: {},
			allFlags: [],
			importado: null
		}
	}

	onload() {
		const _this = this;
		api.post("translations", {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.setState({
				languages: response.data.languages,
				translations: response.data.translations
			})
		}, (error) => {
		});
	}

	componentDidMount() {
		document.title = this.props.target + " • BackOffice";
		document.getElementsByClassName('page-pretitle')[0].innerHTML = "Painel > " + this.props.target;
		document.getElementsByClassName('page-title-text')[0].innerHTML = this.props.target;
		
		this.onload();
	}
	componentDidUpdate() {
		//this.onload();
	}

	rows() {
		return this.state.translations.map(item => {
			return <tr>
				{
					this.state.languages.map(obj => {
						return <td>
							{
								this.state.editRow != item.id ?
									<>
										<span className={`flag flag-xs flag-country-${obj.flag.toLowerCase()}`} />
										{item.field[obj.code]}
									</>
									:
									<input type="text" name={'field_' + obj.code} className="form-control form-control-sm bg-white" defaultValue={item.field[obj.code]} />
							}
						</td>
					})
				}
				<td className="text-end">
					{
						this.state.editRow != item.id ?
							<a className="btn me-3" onClick={() => this.editRow(item.id)}>
								<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-edit" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none" />
									<path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
									<path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
									<line x1={16} y1={5} x2={19} y2={8} />
								</svg>
								Editar
							</a>
							:
							<a className="btn btn-success me-3" onClick={() => this.saveRow(item.id)}>
								<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-device-floppy" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
									<path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
									<circle cx={12} cy={14} r={2}></circle>
									<polyline points="14 4 14 8 8 8 8 4"></polyline>
								</svg>
								Guardar
							</a>
					}
				</td>
			</tr>
		})
	}

	editRow(params) {
		this.setState({ editRow: params })
	}
	saveRow(params) {
		const _this = this;

		var res = {};
		this.state.languages.map((item, i) => {
			res[item.code] = document.querySelector('input[name="field_' + item.code + '"]').value;
		})

		notify('loading', 'Aguarde um momento...', Infinity, false)
		api.post("update_matriz", {
			id: params,
			field: JSON.stringify(res)
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			var translations = _this.state.translations;

			translations[parseInt(_this.state.editRow) - 1].field = res;

			_this.setState({
				translations: translations
			}, () => {
				_this.setState({ editRow: 0 })
			})

			notify('success', 'Atualizado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}
	loadAllLanguages() {
		const _this = this;

		api.post("list_languages", {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.setState({
				allLanguages: response.data.languages,
				allFlags: response.data.flags
			}, () => {
				window.choices();
			})
		}, (error) => {
		});
	}
	addLang() {
		const _this = this;

		var res = {
			flag: document.getElementById('select-flags').value,
			code: document.getElementById('select-language').value,
			lang: document.querySelector('#select-language option:checked').textContent
		}

		notify('loading', 'Aguarde um momento...', Infinity, false)
		if (res.flag.length + res.code.length == 4) {
			api.post("insert_language", res, {
				headers: {
					"X-Requested-With": "XMLHttpRequest"
				}
			}).then((response) => {
				var languages = this.state.languages;
				languages.push(res);

				_this.setState({ languages: languages })

				notify('success', 'Atualizado com sucesso!', 6000, false)
			}, (error) => {
				notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
			});
		}
	}


	importExcel(params) {
		notify('loading', 'A importar excel...', Infinity, false);
		window.importxlsx(params.files[0]);
		
		setTimeout(() => {
			this.setState({ importado: document.querySelector('#import-xlsx-lang').dataset.importstore }, () => {
				if(this.state.importado) {
					const _this = this;
					api.post("import_matriz", {
						importar: this.state.importado
					}, {
						headers: {
							"X-Requested-With": "XMLHttpRequest"
						}
					}).then((response) => {
						notify('success', 'Atualizado com sucesso!', 6000, false);
						_this.onload();
					}, (error) => {
						notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
					});
				} else {
					notify('error', 'Algo correu mal com a importação. Verifique se o ficheiro está corretamente preenchido.', 6000, false)
				}
			});
		}, 3000);
	}

	exportExcel() {

		notify('loading', 'A exportar excel...', 6000, false);
		const exportToCsv = (filename, rows) => {
			var processRow = function(row) {
			  var finalVal = '';
			  for (var j = 0; j < row.length; j++) {
				var innerValue = row[j] === null ? '' : row[j].toString();
				if (row[j] instanceof Date) {
				  innerValue = row[j].toLocaleString();
				};
				var result = innerValue.replace(/"/g, '""');
				if (result.search(/("|,|\n)/g) >= 0)
				  result = '"' + result + '"';
				if (j > 0)
				  finalVal += ',';
				finalVal += result;
			  }
			  return finalVal + '\n';
			};
	  
			var csvFile = '';
			for (var i = 0; i < rows.length; i++) {
			  csvFile += processRow(rows[i]);
			}
	  
			var blob = new Blob([csvFile], {
			  type: 'text/csv;charset=utf-8;'
			});
			if (navigator.msSaveBlob) {
			  navigator.msSaveBlob(blob, filename);
			} else {
			  var link = document.createElement("a");
			  if (link.download !== undefined) {
				var url = URL.createObjectURL(blob);
				link.setAttribute("href", url);
				link.setAttribute("download", filename);
				link.style.visibility = 'hidden';
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			  }
			}
		  }
	  
	  

			var th = document.querySelector('table.datatable thead tr');
			var tr = document.querySelectorAll('table.datatable tbody tr');
	  
			var res = [];
			var columns = [];
			for (var i = 0; i < th.cells.length; i++) {
			  if(i != th.cells.length-1) {
				  columns.push(th.cells[i].dataset.code)
			  }
			}
			res.push(columns)
	  
			for (var i = 0; i < tr.length; i++) {
			  var thisrow = [];
			  for (var k = 0; k < th.cells.length; k++) {
				if(k != th.cells.length-1) {
				  thisrow.push(tr[i].cells[k].textContent)
				}
			  }
	  
			  res.push(thisrow)
			}
	  	  
			exportToCsv('traducoes.xlsx', res)
	}

	render() {
		return (
			<>
				<div className="container-xl">
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<div className="page-pretitle"></div>
								<h2 className="page-title">
									<Icon svg={this.props.target} me="1" />
									<span className="page-title-text">{this.props.target}</span>
								</h2>
							</div>
							<div className="col-auto ms-auto">
								<div className="btn-list">
									<a className="btn btn-primary d-none d-sm-inline-block" data-bs-toggle="modal" data-bs-target="#modal-add-language" onClick={() => this.loadAllLanguages()}>
										<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<line x1="12" y1="5" x2="12" y2="19"></line>
											<line x1="5" y1="12" x2="19" y2="12"></line>
										</svg>
										Novo idioma
									</a>
									<a className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-add-language" onClick={() => this.loadAllLanguages()}>
										<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<line x1="12" y1="5" x2="12" y2="19"></line>
											<line x1="5" y1="12" x2="19" y2="12"></line>
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="page-body">
					<div className="container-xl">
						<div className="row">
							<div className="col-md-3 d-sm-none">
								<label className="form-label">Idiomas disponíveis</label>

								{
									this.state.languages.length > 0 &&

									<div className="btn-group w-100">
										<button type="button" className="btn">
											<span className={`flag flag-xs flag-country-${this.state.languages[0].flag.toLowerCase()}`} />
											{this.state.languages[0].lang}
										</button>
										{
											this.state.languages.length > 1 &&

											<button type="button" className="btn">
												<span className={`flag flag-xs flag-country-${this.state.languages[1].flag.toLowerCase()}`} />
												{this.state.languages[1].lang}
											</button>
										}
										{
											this.state.languages.length > 2 &&

											<div className="btn-group " role="group">
												<button id="btnGroupDrop1" type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Outros</button>
												<div className="dropdown-menu dropdown-menu-end" style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(-87px, 38px)' }} data-popper-placement="bottom-end">
													{
														this.state.languages.map((item, i) => {
															if (i > 1) {
																return <a className="dropdown-item">
																	<span className={`flag flag-xs flag-country-${item.flag.toLowerCase()}`} />
																	{item.lang}
																</a>
															}
														})
													}
												</div>
											</div>
										}
									</div>
								}

							</div>

							<div className="col-auto d-none d-sm-inline-block">
								<label className="form-label">Idiomas disponíveis</label>
								<div className="btn-group w-50">
									{
										this.state.languages.map(item => {
											return <button type="button" className="btn">
												<span className={`flag flag-xs flag-country-${item.flag.toLowerCase()}`} />
												{item.lang}
											</button>
										})
									}
								</div>
							</div>
						</div>
						<div className="row gx-lg-4 mt-4">
							<div className="col-12">
								<div class="alert alert-warning mb-3">
									Na tabela abaixo poderá fazer a tradução do website para vários idiomas.
									Importante realçar que esta tabela só faz a tradução do "esqueleto" do website, e não dos conteúdos de cada página. Para fazer a tradução de páginas terá de ir ao menu "Páginas", escolher a página que deseja traduzir, e de seguida adicionar os textos traduzidos aos blocos, no respetivo idioma.
									Se quiser ainda fazer a tradução dos packs/serviços deverá ir ao menu "{<Link to="products"><b>Produtos</b></Link>}" e adicionar a tradução no respetivo idioma.
								</div>
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Traduções</h3>
										<div className="col-auto ms-auto">
											<a id="import-xlsx-lang" className="btn me-2" data-importstore="" onClick={() => document.querySelector('#import-xlsx-lang input').click()}>
												<input className="d-none" type="file" accept=".xlsx" onChange={e => this.importExcel(e.target)} />
												<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon">
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<ellipse cx={12} cy={6} rx={8} ry={3} />
													<path d="M4 6v8m5.009 .783c.924 .14 1.933 .217 2.991 .217c4.418 0 8 -1.343 8 -3v-6" />
													<path d="M11.252 20.987c.246 .009 .496 .013 .748 .013c4.418 0 8 -1.343 8 -3v-6m-18 7h7m-3 -3l3 3l-3 3" />
												</svg>
												Importar
											</a>
											<a id="export-xlsx-lang" className="btn btn-teal text-white" onClick={() => this.exportExcel()}>
												<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="#ffffff" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<ellipse cx={12} cy={6} rx={8} ry={3}></ellipse>
													<path d="M4 6v6c0 1.657 3.582 3 8 3a19.84 19.84 0 0 0 3.302 -.267m4.698 -2.733v-6"></path>
													<path d="M4 12v6c0 1.599 3.335 2.905 7.538 2.995m8.462 -6.995v-2m-6 7h7m-3 -3l3 3l-3 3"></path>
												</svg>
												Exportar
											</a>
										</div>
									</div>
									<div className="table-responsive">
										<table className="table card-table table-vcenter datatable">
											<thead>
												<tr>
													{
														this.state.languages.map(item => {
															return <th data-code={item.code}><span className={`flag flag-xs flag-country-${item.flag.toLowerCase()}`} />{item.lang}</th>
														})
													}
													<th />
												</tr>
											</thead>
											<tbody>
												{
													this.rows()
												}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-add-language" tabIndex={-1} role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Novo idioma</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								<div className="row mb-3 align-items-end">
									<div className="col">
										<label className="form-label">Escolha o idioma</label>
										<select class="form-select" id="select-language">
											{
												Object.keys(this.state.allLanguages).map(item => {
													return <option value={item}>{this.state.allLanguages[item].name}</option>
												})
											}
										</select>
									</div>
								</div>
								<div className="row mb-3 align-items-end">
									<div className="col">
										<label className="form-label">Escolha a bandeira</label>
										<select className="form-select" id="select-flags">
											{
												this.state.allFlags.map(item => {
													return <option value={item.code} data-custom-properties={`<span class="flag flag-xs flag-country-${item.code.toLowerCase()}"></span>`}>{item.name}</option>
												})
											}
										</select>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.addLang()}>
									<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<line x1={12} y1={5} x2={12} y2={19}></line>
										<line x1={5} y1={12} x2={19} y2={12}></line>
									</svg>
									Adicionar
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Page;