import React from 'react';
import save from "./save";

import Icon from '../../Icons';
import Badge from '../../Badges';

class Header extends React.Component {
    title(params) {
        switch (params) {
            case "Banners":
                return "Banners"
                break;
            case "Info":
                return "Informação"
                break;
            case "Grid":
                return "Grelha"
                break;
            case "Swiper":
                return "Swiper"
                break;
            case "Accordion":
                return "Acordeão"
                break;
            default:
                return (null)
        }
    }

    render() {
        return (
            <div className="modal-header">
                <h5 className="modal-title">
                    <Icon svg={this.props.type} me="2" />
                    {this.title(this.props.type)}
                    <Badge type={this.props.type} id={this.props.id} ms="2" />
                </h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ boxShadow: "none" }} />
            </div>
        )
    }
}

export default Header;