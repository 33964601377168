import React from 'react';

import api from "../../utils/api";

import { notify, closeNotify } from "../../utils/notify";

import Icon from '../../components/Icons';

class Page extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			active: [],
			done: [],
			count: 0,
			page: 1,
			perpage: 10,
			numpages: 1,
			modal: null,
			modal_contact: {
				type: null,
				contact: null
			}
		}
	}

	onload() {
		document.title = this.props.target + " • BackOffice";
		if (document.getElementsByClassName('page-pretitle')[0]) {
			document.getElementsByClassName('page-pretitle')[0].innerHTML = "Painel > " + this.props.target;
		}
		if (document.getElementsByClassName('page-title-text')[0]) {
			document.getElementsByClassName('page-title-text')[0].innerHTML = this.props.target;
		}

		const _this = this;
		api.post("reports", {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.setState({
				active: response.data.active,
				done: response.data.done,
				count: response.data.count,
				page: 1,
				numpages: Math.ceil(response.data.count / _this.state.perpage)
			})
		}, (error) => {

		});
	}

	componentDidMount() {
		window.paginationTimer = null;
		this.onload();
	}

	pagination() {
		var pages = [];
		var numpages = this.state.numpages;
		if (numpages > 5) {
			if (this.state.page < 4) {
				for (var i = 0; i < 5; i++) {
					var index = i + 1;
					pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
				}
			} else if (this.state.page > 3 && this.state.page < ((numpages - 3) + 1)) {
				for (var i = 0; i < 5; i++) {
					var index = i + 1 + (this.state.page - 3);
					pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
				}
			} else {
				for (var i = 0; i < 5; i++) {
					var index = i + 1 + (numpages - 5);
					pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
				}
			}
		} else {
			for (var i = 0; i < numpages; i++) {
				var index = i + 1;
				pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
			}
		}



		return pages;
	}


	switchPage(params) {
		const _this = this;
		if (window.paginationTimer) {
			clearTimeout(window.paginationTimer);
		}

		this.setState({
			page: params
		}, () => {
			window.paginationTimer = setTimeout(function () {
				notify('loading', 'Aguarde um momento...', Infinity, false)
				api.post("reports", {
					page: params.toString()
				}, {
					headers: {
						"X-Requested-With": "XMLHttpRequest"
					}
				}).then((response) => {
					_this.setState({
						done: response.data.done,
						count: response.data.count
					})
					window.paginationTimer = null;
					closeNotify();
				}, (error) => {
					notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
				});
			}, 1000);
		})
	}

	previousAndNext(params, direction) {
		if (!params.classList.contains('disabled')) {
			if (direction == "b") {
				this.switchPage(this.state.page - 1)
			} else {
				this.switchPage(this.state.page + 1)
			}
		}
	}

	arquivar() {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		const _this = this;
		api.post("archivereport", {
			id: this.state.modal
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.setState({
				modal: null
			}, () => {
				_this.onload()
			})
			notify('success', 'Arquivado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	desarquivar(params) {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		const _this = this;
		api.post("unarchivereport", {
			id: params
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.onload()
			notify('success', 'Desarquivado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	checkMobile() {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
			(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform))) {
			return true
		} else {
			return false
		}
	}

	render() {
		return (
			<>
				<div className="container-xl">
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<div className="page-pretitle"></div>
								<h2 className="page-title">
									<Icon svg={this.props.target} me="1" />
									<span className="page-title-text">{this.props.target}</span>
								</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="page-body">
					<div className="container-xl">
						<div className="row mt-1">
							{
								this.state.active.map(item => {
									return <div className="col-sm-6 col-lg-3 mb-4">
										<div className="card card-report">
											<div className="card-body">
												<div className="card-title">
													Processo: {item.id}
													<div className="dropdown float-end">
														<a href="#" className="card-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
															<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																<path stroke="none" d="M0 0h24v24H0z" fill="none" />
																<circle cx={12} cy={12} r={1} />
																<circle cx={12} cy={19} r={1} />
																<circle cx={12} cy={5} r={1} />
															</svg>
														</a>
														<div className="dropdown-menu dropdown-menu-end">
															{
																item.status == 1 ?
																	this.checkMobile() ?
																		<a className="dropdown-item" href={`tel:${item.contacto}`}>Contactar pessoa que achou</a>
																		:
																		<a className="dropdown-item" onClick={() => this.setState({ modal_contact: { type: 'Contacto da pessoa que achou', contact: item.contacto } })} data-bs-toggle="modal" data-bs-target="#modal-contact">Contactar pessoa que achou</a>
																	:
																	(null)
															}
															{
																this.checkMobile() ?
																	<a className="dropdown-item" href={`tel:${item.dono.dono_contacto}`}>Contactar dono</a>
																	:
																	<a className="dropdown-item" onClick={() => this.setState({ modal_contact: { type: 'Contacto do dono', contact: item.dono.dono_contacto } })} data-bs-toggle="modal" data-bs-target="#modal-contact">Contactar dono</a>
															}
															<a className="dropdown-item" onClick={() => this.setState({ modal: item.id })} data-bs-toggle="modal" data-bs-target="#modal-warning">Arquivar processo</a>
														</div>
													</div>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<circle cx="8.5" cy="8.5" r={1} fill="currentColor"></circle>
														<path d="M4 7v3.859c0 .537 .213 1.052 .593 1.432l8.116 8.116a2.025 2.025 0 0 0 2.864 0l4.834 -4.834a2.025 2.025 0 0 0 0 -2.864l-8.117 -8.116a2.025 2.025 0 0 0 -1.431 -.593h-3.859a3 3 0 0 0 -3 3z"></path>
													</svg>
													Cartão ID: <strong>{item.cartao}</strong>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none" />
														<rect x={4} y={5} width={16} height={16} rx={2} />
														<line x1={16} y1={3} x2={16} y2={7} />
														<line x1={8} y1={3} x2={8} y2={7} />
														<line x1={4} y1={11} x2={20} y2={11} />
														<line x1={11} y1={15} x2={12} y2={15} />
														<line x1={12} y1={15} x2={12} y2={18} />
													</svg>
													Data de início: <strong>{item.inicio}</strong>
												</div>
												<div className="mb-2">
													{
														item.status == 1 ?
															<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																<path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4"></path>
																<line x1={12} y1={8} x2={12} y2={11}></line>
																<line x1={12} y1={14} x2={12} y2="14.01"></line>
															</svg>
															:
															<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																<circle cx={12} cy={7} r={4}></circle>
																<path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
															</svg>
													}
													Iniciado por: <strong>{item.status == 1 ? item.nome : <a href={item.dono.dono_id}>{item.dono.dono_nome}</a>}</strong>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2"></path>
													</svg>
													Contacto: <strong><a href={`tel:${item.contacto}`}>{item.contacto}</a></strong>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none" />
														<circle cx={12} cy={11} r={3} />
														<path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
													</svg>
													Local: <strong>{item.local}</strong>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<circle cx={12} cy={7} r={4}></circle>
														<path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
													</svg>
													Dono: <strong>{item.dono.dono_nome}</strong>
												</div>
											</div>
											<div className={`d-flex ${item.status == 0 ? 'bg-red' : 'bg-green'}`}>
												<a className="card-btn text-white text-uppercase">
													{
														item.status == 0 ?
															<>
																<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 icon-infco icon-infco-alert-triangle" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																	<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																	<path d="M12 9v2m0 4v.01"></path>
																	<path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
																</svg>
																Perdido
															</>
															:
															<>
																<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 icon-infco icon-infco-zoom-check" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																	<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																	<circle cx={10} cy={10} r={7}></circle>
																	<path d="M21 21l-6 -6"></path>
																	<path d="M7 10l2 2l4 -4"></path>
																</svg>
																Encontrado
															</>
													}
												</a>
											</div>
										</div>
									</div>
								})
							}
						</div>
						<div className="row gx-lg-4">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Processos arquivados</h3>
									</div>
									<div className="table-responsive">
										<table className="table card-table table-vcenter datatable">
											<thead>
												<tr>
													<th>Processo</th>
													<th>Cartão ID</th>
													<th>Data de início</th>
													<th>Encontrado por</th>
													<th>Contacto</th>
													<th>Local</th>
													<th>Dono</th>
													<th>Status</th>
													<th>Data de conclusão</th>
													{
														this.state.done.length > 0 &&
														<th>Desarquivar</th>
													}
												</tr>
											</thead>
											<tbody>
												{
													this.state.done.map(item => {
														return <tr>
															<td>{item.id}</td>
															<td><strong>{item.cartao}</strong></td>
															<td>{item.inicio}</td>
															<td>{item.nome}</td>
															<td><strong><a href={`tel:${item.contacto}`}>{item.contacto}</a></strong></td>
															<td>{item.local}</td>
															<td>{item.dono.dono_nome}</td>
															<td>{item.status == 0 ? <span className="badge bg-red-lt">Perdido</span> : <span className="badge bg-green-lt">Encontrado</span>}</td>
															<td>{item.fim}</td>
															<td>
																<a className="btn btn-dark btn-icon" onClick={() => this.desarquivar(item.id)}>
																	<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back-up" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																		<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																		<path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1"></path>
																	</svg>
																</a>
															</td>
														</tr>
													})
												}
											</tbody>
										</table>
									</div>
									<div className="card-footer d-flex align-items-center">
										<p className="m-0 text-muted"><b>Total:</b> &nbsp;{this.state.count} processo{this.state.count != 1 ? 's' : ''}</p>
										{
											Number(this.state.count) > this.state.perpage &&
											<ul className="pagination m-0 ms-auto scroll-x" style={{ maxWidth: '50%' }}>
												<li onClick={e => this.previousAndNext(e.target, 'b')} className={`page-item ${this.state.page == 1 ? 'disabled' : 'cursor-pointer'}`}>
													<a className="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="15 6 9 12 15 18" /></svg>
														Anterior
													</a>
												</li>
												{this.pagination()}
												<li onClick={e => this.previousAndNext(e.target, 'f')} className={`page-item ${this.state.page == this.state.numpages ? 'disabled' : 'cursor-pointer'}`}>
													<a className="page-link">
														Próxima
														<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="9 6 15 12 9 18" /></svg>
													</a>
												</li>
											</ul>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-contact" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-sm modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-status bg-blue"></div>
							<div className="modal-body text-center py-4">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
								<svg className="icon mb-2" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 202.592 202.592" style={{ enableBackground: 'new 0 0 202.592 202.592', width: '2.5rem', height: '2.5rem' }} xmlSpace="preserve">
									<g>
										<g>
											<path style={{ fill: '#206bc4' }} d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
			c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
			c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
			C193.5,169.446,193.31,173.537,190.683,176.164z" />
										</g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
								</svg>

								<div className="modal-title">{this.state.modal_contact.type}</div>
								<strong><a href={`tel:${this.state.modal_contact.contact}`}>{this.state.modal_contact.contact}</a></strong>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-warning" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-sm modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-status bg-yellow"></div>
							<div className="modal-body text-center py-4">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
								<svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-yellow icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
								<div className="modal-title">Atenção!</div>
								<div>Tem a certeza que pretende arquivar este processo?</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-yellow" data-bs-dismiss="modal" onClick={() => this.arquivar()}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Page;