import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import api from '../../utils/api';


import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Painel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            orders: []
        }
    }

    onload() {
        document.title = "Painel • BackOffice";
    }

    componentDidMount() {
        const _this = this;
        _this.onload();

        api.post("activeorders", {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then((response) => {
            _this.setState({
                orders: response.data
            })
        }, (error) => {
            alert('Erro');
        });
    }
    componentDidUpdate() {
        this.onload();
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="d-flex align-items-center w-full">
                        <div className="col">
                            <h3 className="card-title">Encomendas</h3>
                        </div>
                        <div className="col-auto">
                            <Link className="btn btn-outline-white" to="/workplace/orders">
                                <Icon me="2" svg="Encomendas" />
                                Ver todas
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="card-table table-responsive" style={{ maxHeight: "20rem" }}>
                    <table className="table table-vcenter">
                        <thead>
                            <tr>
                                <th>Encomenda</th>
                                <th>Nome</th>
                                <th>Produtos</th>
                                <th>Status</th>
                                <th>Total</th>
                                <th>Data de início</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.orders.map(item => {
                                    return <tr>
                                        <td className="w-1">{item.order_id}</td>
                                        <td>{item.name}</td>
                                        <td className="text-muted text-nowrap">
                                           {item.cart}
                                        </td>
                                        <td className="text-muted">
                                            <span className="badge bg-yellow-lt">Aguardando pagamento</span>
                                        </td>
                                        <td>
                                            <b>€{item.total_cost}</b>
                                        </td>
                                        <td className="text-nowrap text-muted">{item.created_at}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Painel;