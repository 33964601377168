import React from 'react';
import { Link, useLocation } from "react-router-dom";
import api from '../../utils/api';

class Pages extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            pages: []
        }
    }

    componentDidMount() {
        let _this = this;

		api.post("pages", {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
            _this.setState({
                pages: response.data
            });
		}, (error) => {
		});
    }

    render() {
        const { pathname } = window.location;
        const active = pathname.split('/')[3];

        return this.state.pages.map(column => {
            return <div className="dropdown-menu-column">
                {
                    column.map(page => {
                        return <Link className={`dropdown-item ${active === page.id && "active"}`} data-id={page.id} to={"/workplace/page/" + page.id}>{page.title}</Link>
                    })
                }
            </div>
        })
    }
}

export default Pages;