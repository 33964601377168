import React from 'react';

function Footer() {
  return (
    <footer className="footer footer-transparent d-print-none">
      <div className="container">
        <div className="row text-center align-items-center flex-row-reverse">
          <div className="col-lg-auto ms-lg-auto">
            <ul className="list-inline list-inline-dots mb-0">
              <li className="list-inline-item">Ajuda e suporte: <a href="mailto:hes@infoeco.com" className="link-secondary">hes@infoeco.com</a></li>
            </ul>
          </div>
          <div className="col-12 col-lg-auto mt-3 mt-lg-0">
            <ul className="list-inline list-inline-dots mb-0">
              <li className="list-inline-item">
                Copyright © { new Date().getFullYear() } <a href="https://infoeco.com/" className="link-secondary">Infoeco</a>.
                Todos os direitos reservados
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer;