import React from 'react';

import api from "../../utils/api";

import { notify, closeNotify } from "../../utils/notify";

import Icon from '../../components/Icons';

class Page extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			active: [],
			done: [],
			count: 0,
			page: 1,
			perpage: 10,
			numpages: 1,
			stock_tab: 0,
			stock_last: null,
			stock_k: [],
			stock_k_page: 1,
			stock_k_count: 0,
			stock_k_numpages: 1,
			stock_s: [],
			stock_s_page: 1,
			stock_s_count: 0,
			stock_s_numpages: 1,
			modal: null,
			modal_contact: {
				type: null,
				contact: null
			},
			modal_stock_tab: 0,
			modal_stock_tab_z: false,
			modal_stock_tab_o: false
		}
	}

	onload() {
		document.title = this.props.target + " • BackOffice";
		if (document.getElementsByClassName('page-pretitle')[0]) {
			document.getElementsByClassName('page-pretitle')[0].innerHTML = "Painel > " + this.props.target;
		}
		if (document.getElementsByClassName('page-title-text')[0]) {
			document.getElementsByClassName('page-title-text')[0].innerHTML = this.props.target;
		}

		const _this = this;
		api.post("tallorders", {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.setState({
				active: response.data.active,
				done: response.data.done,
				count: response.data.count,
				page: 1,
				numpages: Math.ceil(response.data.count / _this.state.perpage)
			})
		}, (error) => {

		});

		api.post("stock_supply", {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.setState({
				stock_k: response.data.stock_k.stocks,
				stock_k_count: response.data.stock_k.count,
				stock_k_numpages: Math.ceil(response.data.stock_k.count / _this.state.perpage),
				stock_s: response.data.stock_s.stocks,
				stock_s_count: response.data.stock_s.count,
				stock_s_numpages: Math.ceil(response.data.stock_s.count / _this.state.perpage),
				stock_last: response.data.last
			})
		}, (error) => {

		});
	}

	componentDidMount() {
		window.paginationTimer = null;
		this.onload();
	}


	pagination() {
		var pages = [];
		var numpages = this.state.numpages;
		if (numpages > 5) {
			if (this.state.page < 4) {
				for (var i = 0; i < 5; i++) {
					var index = i + 1;
					pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
				}
			} else if (this.state.page > 3 && this.state.page < ((numpages - 3) + 1)) {
				for (var i = 0; i < 5; i++) {
					var index = i + 1 + (this.state.page - 3);
					pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
				}
			} else {
				for (var i = 0; i < 5; i++) {
					var index = i + 1 + (numpages - 5);
					pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
				}
			}
		} else {
			for (var i = 0; i < numpages; i++) {
				var index = i + 1;
				pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
			}
		}



		return pages;
	}

	paginationStockK() {
		var pages = [];
		var numpages = this.state.stock_k_numpages;
		var page = this.state.stock_k_page;
		if (numpages > 5) {
			if (page < 4) {
				for (var i = 0; i < 5; i++) {
					var index = i + 1;
					pages.push(<li className={`page-item cursor-pointer ${page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPageStockK(e.target.textContent)}>{index}</a></li>);
				}
			} else if (page > 3 && page < ((numpages - 3) + 1)) {
				for (var i = 0; i < 5; i++) {
					var index = i + 1 + (page - 3);
					pages.push(<li className={`page-item cursor-pointer ${page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPageStockK(e.target.textContent)}>{index}</a></li>);
				}
			} else {
				for (var i = 0; i < 5; i++) {
					var index = i + 1 + (numpages - 5);
					pages.push(<li className={`page-item cursor-pointer ${page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPageStockK(e.target.textContent)}>{index}</a></li>);
				}
			}
		} else {
			for (var i = 0; i < numpages; i++) {
				var index = i + 1;
				pages.push(<li className={`page-item cursor-pointer ${page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPageStockK(e.target.textContent)}>{index}</a></li>);
			}
		}



		return pages;
	}

	paginationStockS() {
		var pages = [];
		var numpages = this.state.stock_s_numpages;
		var page = this.state.stock_s_page;
		if (numpages > 5) {
			if (page < 4) {
				for (var i = 0; i < 5; i++) {
					var index = i + 1;
					pages.push(<li className={`page-item cursor-pointer ${page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPageStockS(e.target.textContent)}>{index}</a></li>);
				}
			} else if (page > 3 && page < ((numpages - 3) + 1)) {
				for (var i = 0; i < 5; i++) {
					var index = i + 1 + (page - 3);
					pages.push(<li className={`page-item cursor-pointer ${page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPageStockS(e.target.textContent)}>{index}</a></li>);
				}
			} else {
				for (var i = 0; i < 5; i++) {
					var index = i + 1 + (numpages - 5);
					pages.push(<li className={`page-item cursor-pointer ${page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPageStockS(e.target.textContent)}>{index}</a></li>);
				}
			}
		} else {
			for (var i = 0; i < numpages; i++) {
				var index = i + 1;
				pages.push(<li className={`page-item cursor-pointer ${page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPageStockS(e.target.textContent)}>{index}</a></li>);
			}
		}



		return pages;
	}


	switchPage(params) {
		const _this = this;
		if (window.paginationTimer) {
			clearTimeout(window.paginationTimer);
		}

		this.setState({
			page: params
		}, () => {
			window.paginationTimer = setTimeout(function () {
				notify('loading', 'Aguarde um momento...', Infinity, false)
				api.post("tallorders", {
					page: params.toString()
				}, {
					headers: {
						"X-Requested-With": "XMLHttpRequest"
					}
				}).then((response) => {
					_this.setState({
						done: response.data.done,
						count: response.data.count
					})
					window.paginationTimer = null;
					closeNotify();
				}, (error) => {
					notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
				});
			}, 1000);
		})
	}

	previousAndNext(params, direction) {
		if (!params.classList.contains('disabled')) {
			if (direction == "b") {
				this.switchPage(this.state.page - 1)
			} else {
				this.switchPage(this.state.page + 1)
			}
		}
	}


	switchPageStockK(params) {
		const _this = this;
		if (window.paginationTimer) {
			clearTimeout(window.paginationTimer);
		}

		this.setState({
			stock_k_page: params
		}, () => {
			window.paginationTimer = setTimeout(function () {
				notify('loading', 'Aguarde um momento...', Infinity, false)
				api.post("stock_supply", {
					page: params.toString(),
					type: "0"
				}, {
					headers: {
						"X-Requested-With": "XMLHttpRequest"
					}
				}).then((response) => {
					_this.setState({
						stock_k: response.data.stocks,
						stock_k_count: response.data.count,
						stock_k_numpages: Math.ceil(response.data.count / _this.state.perpage),
						stock_last: response.data.last
					})
					window.paginationTimer = null;
					closeNotify();
				}, (error) => {
					notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
				});
			}, 1000);
		})
	}

	previousAndNextStockK(params, direction) {
		if (!params.classList.contains('disabled')) {
			if (direction == "b") {
				this.switchPageStockK(this.state.stock_k_page - 1)
			} else {
				this.switchPageStockK(this.state.stock_k_page + 1)
			}
		}
	}


	switchPageStockS(params) {
		const _this = this;
		if (window.paginationTimer) {
			clearTimeout(window.paginationTimer);
		}

		this.setState({
			stock_s_page: params
		}, () => {
			window.paginationTimer = setTimeout(function () {
				notify('loading', 'Aguarde um momento...', Infinity, false)
				api.post("stock_supply", {
					page: params.toString(),
					type: "1"
				}, {
					headers: {
						"X-Requested-With": "XMLHttpRequest"
					}
				}).then((response) => {
					_this.setState({
						stock_s: response.data.stocks,
						stock_s_count: response.data.count,
						stock_s_numpages: Math.ceil(response.data.count / _this.state.perpage),
						stock_last: response.data.last
					})
					window.paginationTimer = null;
					closeNotify();
				}, (error) => {
					notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
				});
			}, 1000);
		})
	}

	previousAndNextStockS(params, direction) {
		if (!params.classList.contains('disabled')) {
			if (direction == "b") {
				this.switchPageStockS(this.state.stock_s_page - 1)
			} else {
				this.switchPageStockS(this.state.stock_s_page + 1)
			}
		}
	}


	deleteCard() {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		const _this = this;
		const id = this.state.delete_card;

		api.post("stock_manager", {
			id: id,
			act: "2"
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this[_this.state.stock_tab == 0 ? 'switchPageStockK' : 'switchPageStockS'](_this.state[_this.state.stock_tab == 0 ? 'stock_k_page' : 'stock_s_page']);
			notify('success', 'Eliminado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	guardar(tab) {
		const _this = this;

		notify('loading', 'Aguarde um momento...', Infinity, false)
		if (tab == 0) {
			var t = document.querySelector('[name="stock-type-radio"]:checked').value;
			var de = document.querySelector('[name="stock-add-start"]').value;
			var a = document.querySelector('[name="stock-add-end"]').value;

			if (t && de && a) {
				api.post("stock_manager", {
					de: de,
					a: a,
					t: t,
					act: "1"
				}, {
					headers: {
						"X-Requested-With": "XMLHttpRequest"
					}
				}).then((response) => {
					_this[_this.state.stock_tab == 0 ? 'switchPageStockK' : 'switchPageStockS'](_this.state[_this.state.stock_tab == 0 ? 'stock_k_page' : 'stock_s_page']);
					_this.setState({ modal_stock_tab_z: false, modal_stock_tab_o: false })

					notify('success', 'Stock atualizado com sucesso!', 6000, false)
				}, (error) => {
					notify('error', 'Não é permitido duplicar cartões. Cada cartão tem de ter um ID único.', 6000, false)
				});
			}
		} else if (tab == 1) {
			var de = document.querySelector('[name="stock-delete-start"]').value;
			var a = document.querySelector('[name="stock-delete-end"]').value;

			if (de && a) {
				api.post("stock_manager", {
					de: de,
					a: a,
					act: "0"
				}, {
					headers: {
						"X-Requested-With": "XMLHttpRequest"
					}
				}).then((response) => {
					_this[_this.state.stock_tab == 0 ? 'switchPageStockK' : 'switchPageStockS'](_this.state[_this.state.stock_tab == 0 ? 'stock_k_page' : 'stock_s_page']);
					_this.setState({ modal_stock_tab_z: false, modal_stock_tab_o: false })

					notify('success', 'Stock atualizado com sucesso!', 6000, false)
				}, (error) => {
					notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
				});
			}
		} else {
			var t = document.querySelector('[name="stock-export-client"]').value;
			var de = document.querySelector('[name="stock-export-start"]').value;
			var a = document.querySelector('[name="stock-export-end"]').value;

			if (t && de && a) {
				api.post("stock_manager", {
					de: de,
					a: a,
					t: t,
					act: "3"
				}, {
					headers: {
						"X-Requested-With": "XMLHttpRequest"
					}
				}).then((response) => {
					_this[_this.state.stock_tab == 0 ? 'switchPageStockK' : 'switchPageStockS'](_this.state[_this.state.stock_tab == 0 ? 'stock_k_page' : 'stock_s_page']);
					_this.setState({ modal_stock_tab_z: false, modal_stock_tab_o: false })

					notify('success', 'Stock atualizado com sucesso!', 6000, false)
				}, (error) => {
					notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
				});
			}
		}
	}

	checkMobile() {
		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.userAgent) ||
			(/Android|webOS|iPhone|iPad|iPod|BlackBerry/i.test(navigator.platform))) {
			return true
		} else {
			return false
		}
	}


	getpct(params) {
		//return Math.ceil(((u-p) / p)*100)+'%';
	}

	toggleOrder(params) {
		if (document.querySelector('#order_collapse_' + params + '.d-none')) {
			document.getElementById('order_collapse_btn_' + params).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="icon ms-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
			<polyline points="6 15 12 9 18 15"></polyline>
		 </svg>`;
			document.getElementById('order_collapse_' + params).setAttribute('class', 'mb-2 ps-0')
		} else {
			document.getElementById('order_collapse_btn_' + params).innerHTML = `<svg xmlns="http://www.w3.org/2000/svg" class="icon ms-1" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
			<polyline points="6 9 12 15 18 9"></polyline>
		 </svg>`;
			document.getElementById('order_collapse_' + params).setAttribute('class', 'mb-2 ps-0 d-none')
		}
	}

	archive(params) {
		if (window.confirm('Tem a certeza que pretende arquivar esta encomenda?')) {
			const _this = this;
			notify('loading', 'Aguarde um momento...', Infinity, false)
			api.post("archive_order", {
				id: params
			}, {
				headers: {
					"X-Requested-With": "XMLHttpRequest",
				}
			}).then((response) => {
				notify('success', 'Encomenda arquivada com sucesso!', 6000, false)
				_this.onload();
			}, (error) => {
				notify('error', 'Algo correu mal! Tente novamente ou verifique a sua conexão.', 6000, true)
			});
		}
	}

	desarquivar(params) {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		const _this = this;
		api.post("unarchive_order", {
			id: params
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			notify('success', 'Encomenda desarquivada com sucesso!', 6000, false)
			_this.onload();
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente ou verifique a sua conexão.', 6000, true)
		});
	}

	validDatePrompt(dateStr) {
		const regex = /^\d{4}-\d{2}-\d{2}$/;

		if (dateStr.match(regex) === null) {
			return false;
		}

		const date = new Date(dateStr);

		const timestamp = date.getTime();

		if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
			return false;
		}

		return date.toISOString().startsWith(dateStr);
	}

	changeStatus(o, id, client, params) {
		const _this = this;

		switch (o) {
			case 'd':
				var message = window.prompt('Tem a certeza que esta encomenda já foi entregue? Se sim, insira a data de entrega no seguinte formato AAAA-MM-DD. (Ex. 2021-11-29)');

				if (message && this.validDatePrompt(message)) {
					notify('loading', 'Aguarde um momento...', Infinity, false);
					api.post("change_order_status", {
						o: 'd',
						order: id,
						client: client,
						date: message
					}, {
						headers: {
							"X-Requested-With": "XMLHttpRequest",
						}
					}).then((response) => {
						notify('success', 'Status alterado com sucesso!', 6000, false)
						_this.onload();
					}, (error) => {
						notify('error', 'Algo correu mal! Tente novamente ou verifique a sua conexão.', 6000, true)
					});
				} else {
					if (message) {
						notify('error', 'O formato da data que inseriu não é válido!', 6000, true)
					}
				}
				break;
			case 'p':
				if (window.confirm('Tem a certeza que esta encomenda já foi paga?')) {
					notify('loading', 'Aguarde um momento...', Infinity, false);
					api.post("change_order_status", {
						o: 'p',
						order: id,
						client: client
					}, {
						headers: {
							"X-Requested-With": "XMLHttpRequest",
						}
					}).then((response) => {
						notify('success', 'Status alterado com sucesso!', 6000, false)
						_this.onload();
					}, (error) => {
						notify('error', 'Algo correu mal! Tente novamente ou verifique a sua conexão.', 6000, true)
					});
				}
				break;
			case 'r':
				var date1 = params;

				var today = new Date();
				var yyyy = today.getFullYear();
				var mm = today.getMonth() + 1;
				var dd = today.getDate();

				if (dd < 10) dd = '0' + dd;
				if (mm < 10) mm = '0' + mm;

				var date2 = yyyy + '-' + mm + '-' + dd;

				var date1 = date1.split('-');
				var date2 = date2.split('-');
				date1 = new Date(date1[0], date1[1], date1[2]);
				date2 = new Date(date2[0], date2[1], date2[2]);

				var date1_unixtime = parseInt(date1.getTime() / 1000);
				var date2_unixtime = parseInt(date2.getTime() / 1000);

				var message = Number(((date2_unixtime - date1_unixtime) / 60 / 60) / 24) <= 14 ? 'Tem a certeza que pretende reembolsar esta encomenda?' : 'Já decorreram os 14 dias estipulados por lei para devoluções. Tem a certeza que pretende reembolsar esta encomenda?';

				if (window.confirm(message)) {
					notify('loading', 'Aguarde um momento...', Infinity, false);
					api.post("change_order_status", {
						o: 'r',
						order: id,
						client: client
					}, {
						headers: {
							"X-Requested-With": "XMLHttpRequest",
						}
					}).then((response) => {
						notify('success', 'Status alterado com sucesso!', 6000, false)
						_this.onload();
					}, (error) => {
						notify('error', 'Algo correu mal! Tente novamente ou verifique a sua conexão.', 6000, true)
					});
				}
				break;
		}
	}

	undoStatus(order, client, status) {
		const _this = this;
		if (window.confirm('Tem a certeza que pretende retroceder uma etapa do status?')) {
			notify('loading', 'Aguarde um momento...', Infinity, false);
			api.post("undo_order_status", {
				order: order,
				client: client,
				status: status
			}, {
				headers: {
					"X-Requested-With": "XMLHttpRequest",
				}
			}).then((response) => {
				notify('success', 'Status alterado com sucesso!', 6000, false)
				_this.onload();
			}, (error) => {
				notify('error', 'Algo correu mal! Tente novamente ou verifique a sua conexão.', 6000, true)
			});
		}
	}

	render() {
		return (
			<>
				<div className="container-xl">
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<div className="page-pretitle"></div>
								<h2 className="page-title">
									<Icon svg={this.props.target} me="1" />
									<span className="page-title-text">{this.props.target}</span>
								</h2>
							</div>
						</div>
					</div>
				</div>
				<div className="page-body">
					<div className="container-xl">
						<div className="row mt-1">
							{
								this.state.active.map(item => {
									return <div className="col-sm-6 col-lg-3 mb-4">
										<div className="card card-report">
											<div className="card-body">
												<div className="card-title">
													Encomenda: {item.order_id}
													<div className="dropdown float-end">
														<a href="#" className="card-dropdown" data-bs-toggle="dropdown" aria-expanded="false">
															<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																<path stroke="none" d="M0 0h24v24H0z" fill="none" />
																<circle cx={12} cy={12} r={1} />
																<circle cx={12} cy={19} r={1} />
																<circle cx={12} cy={5} r={1} />
															</svg>
														</a>
														<div className="dropdown-menu dropdown-menu-end">
															{
																this.checkMobile() ?
																	<a className="dropdown-item" href={`tel:${item.contact}`}>Contactar cliente</a>
																	:
																	<a className="dropdown-item" onClick={() => this.setState({ modal_contact: { type: 'Contacto do cliente', contact: item.contact } })} data-bs-toggle="modal" data-bs-target="#modal-contact">Contactar cliente</a>

															}
															<a className="dropdown-item" onClick={() => this.archive(item.order_id)}>Arquivar encomenda</a>
															<div className="dropdown-divider" />
															<span className="dropdown-header">Editar status manualmente</span>
															{
																item.order_status > 0 &&
																<a className="dropdown-item" onClick={() => this.undoStatus(item.order_id, item.client, item.order_status)}>
																	<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																		<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																		<path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1"></path>
																	</svg>
																	Undo
																</a>
															}
															{
																item.order_status == 0 &&
																<a className="dropdown-item" onClick={() => this.changeStatus('p', item.order_id, item.client, null)}>Pagamento efetuado</a>
															}
															{
																item.order_status == 1 &&
																<a className="dropdown-item" onClick={() => this.changeStatus('d', item.order_id, item.client, item.payment_date)}>Encomenda entregue</a>
															}
															{
																item.order_status == 2 &&
																<a className="dropdown-item" onClick={() => this.changeStatus('r', item.order_id, item.client, item.delivery_date)}>Reembolsar cliente</a>
															}
														</div>
													</div>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none" />
														<rect x={4} y={5} width={16} height={16} rx={2} />
														<line x1={16} y1={3} x2={16} y2={7} />
														<line x1={8} y1={3} x2={8} y2={7} />
														<line x1={4} y1={11} x2={20} y2={11} />
														<line x1={11} y1={15} x2={12} y2={15} />
														<line x1={12} y1={15} x2={12} y2={18} />
													</svg>
													Data: <strong>{item.created_at}</strong>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<rect x={3} y={4} width={18} height={16} rx={3}></rect>
														<circle cx={9} cy={10} r={2}></circle>
														<line x1={15} y1={8} x2={17} y2={8}></line>
														<line x1={15} y1={12} x2={17} y2={12}></line>
														<line x1={7} y1={16} x2={17} y2={16}></line>
													</svg>
													Cliente ID: <strong><a>{item.client}</a></strong>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<circle cx={12} cy={7} r={4}></circle>
														<path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
													</svg>
													Nome: <strong><a>{item.name}</a></strong>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<circle cx={12} cy={12} r={4}></circle>
														<path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28"></path>
													</svg>
													Email: <strong><a href={`mailto:${item.email}`}>{item.email}</a></strong>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<circle cx={7} cy={17} r={2}></circle>
														<circle cx={17} cy={17} r={2}></circle>
														<path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5"></path>
														<line x1={3} y1={9} x2={7} y2={9}></line>
													</svg>
													Opção de envio: <strong>{item.delivery_option == "0" ? 'Correio Normal' : 'CTT Expresso'}</strong>
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
														<path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
														<path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
													</svg>
													Morada de entrega: <strong>
														{
															item.delivery_location.address + ', ' + item.delivery_location.zip + ', ' + item.delivery_location.locality
														}
													</strong>
												</div>
												{
													item.delivery_location.address_2 &&
													<div className="mb-2">
														<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
															<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
															<polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
															<path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
															<path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
														</svg>
														Morada 2: <strong>
															{
																item.delivery_location.address_2
															}
														</strong>
													</div>
												}
												{
													item.extra &&
													<div className="mb-2">
														<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
															<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
															<path d="M12 9v2m0 4v.01"></path>
															<path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
														</svg>
														Nota extra: <strong>{item.extra}</strong>
													</div>
												}
												{
													item.nif ?
														<div className="mb-2">
															<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																<path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"></path>
																<path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5"></path>
															</svg>
															NIF: <strong>{item.nif}</strong>
														</div>
														:
														(null)
												}
												{
													item.billing_address &&
													<div className="mb-2">
														<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
															<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
															<path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"></path>
														</svg>
														Morada de faturação:<br /><strong>{item.billing_address.name}<br />{item.billing_address.contact}<br />{item.billing_address.email}<br />{item.billing_address.address + ', ' + item.billing_address.zip + ', ' + item.billing_address.locality}</strong>
													</div>
												}
												<hr className="mt-3 mb-3" />
												<div className="d-flex mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<circle cx={6} cy={19} r={2}></circle>
														<circle cx={17} cy={19} r={2}></circle>
														<path d="M17 17h-11v-14h-2"></path>
														<path d="M6 5l14 1l-1 7h-13"></path>
													</svg>
													Produtos:
													<span id={`order_collapse_btn_` + item.order_id} className="badge bg-dark-lt cursor-pointer ms-auto" onClick={() => this.toggleOrder(item.order_id)}>
														<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon ms-1">
															<path stroke="none" d="M0 0h24v24H0z" fill="none" />
															<polyline points="6 9 12 15 18 9" />
														</svg>
													</span>

												</div>
												<div className="mb-2 ps-0 d-none" id={`order_collapse_` + item.order_id}>
													{
														item.invoice.artigos.map(elem => {
															var item = elem;
															return <div className="alert p-1 ps-2 mb-1">
																<strong>{item.artigo_descricao}</strong>
																<br />
																<li className="list-unstyled"><span className="subheader text-muted">{item.artigo_codigo}</span></li>
																<div className="subheader">
																	<ul className="mb-1 list-unstyled text-dark">
																		{
																			Object.keys(item).map(element => {
																				if (element != "artigo_codigo" && element != "artigo_descricao") {
																					return <li>{element}: <span className="text-muted">{item[element]}</span></li>
																				}
																			})
																		}
																	</ul>
																</div>
																<span className="badge bg-green me-1">Preço: {item.preco_unit_c_desconto_c_iva}€</span>
															</div>
														})
													}
												</div>
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<rect x={4} y={3} width={16} height={18} rx={2}></rect>
														<rect x={8} y={7} width={8} height={3} rx={1}></rect>
														<line x1={8} y1={14} x2={8} y2="14.01"></line>
														<line x1={12} y1={14} x2={12} y2="14.01"></line>
														<line x1={16} y1={14} x2={16} y2="14.01"></line>
														<line x1={8} y1={17} x2={8} y2="17.01"></line>
														<line x1={12} y1={17} x2={12} y2="17.01"></line>
														<line x1={16} y1={17} x2={16} y2="17.01"></line>
													</svg>
													Subtotal: <strong>{item.invoice.total}€</strong>
												</div>
												{
													item.referral &&
													<div className="mb-2">
														<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
															<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
															<line x1={9} y1={15} x2={15} y2={9}></line>
															<circle cx="9.5" cy="9.5" r=".5" fill="currentColor"></circle>
															<circle cx="14.5" cy="14.5" r=".5" fill="currentColor"></circle>
															<path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
														</svg>
														Acerto: <strong>{item.invoice.acerto}€</strong>
														<span className="badge bg-yellow-lt ms-2">CP: {item.referral}</span>
													</div>
												}
												<div className="mb-2">
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<path d="M17.2 7a6 7 0 1 0 0 10"></path>
														<path d="M13 10h-8m0 4h8"></path>
													</svg>
													Total: <strong>{item.invoice.custo_total}€</strong>
												</div>
											</div>
											{
												item.order_status == 0 && item.references && item.references.expired == "true" ?
													<div className={`d-flex bg-red`}>
														<a className="card-btn text-white text-uppercase">
															Expirada
														</a>
													</div>
													:
													{
														0: <div className={`d-flex bg-yellow`}>
															<a className="card-btn text-white text-uppercase">
																Aguardando pagamento
															</a>
														</div>,
														1: <div className={`d-flex bg-blue`}>
															<a className="card-btn text-white text-uppercase">
																{
																	item.payment_date &&
																	`Pagamento efetuado (${item.payment_date.split(' ')[0]})`
																}
															</a>
														</div>,
														2: <div className={`d-flex bg-green`}>
															<a className="card-btn text-white text-uppercase">
																{
																	item.delivery_date &&
																	`Encomenda entregue (${item.delivery_date.split(' ')[0]})`
																}
															</a>
														</div>,
														3: <div className={`d-flex bg-dark`}>
															<a className="card-btn text-white text-uppercase">
																{
																	item.refund_date &&
																	`Reembolso (${item.refund_date.split(' ')[0]})`
																}
															</a>
														</div>
													}[item.order_status]
											}
										</div>
									</div>
								})
							}
						</div>
						<div className="row gx-lg-4 mb-4">
							<div className="col-12">
								<div className="card shadow-none border-none">
									<div className="card-header">
										<h3 className="card-title">Lista de stocks</h3>
										<a className="btn btn-white ms-auto" data-bs-toggle="modal" data-bs-target="#modal-stocks-manager">
											<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3" /><line x1={12} y1={12} x2={20} y2="7.5" /><line x1={12} y1={12} x2={12} y2={21} /><line x1={12} y1={12} x2={4} y2="7.5" /><line x1={16} y1="5.25" x2={8} y2="9.75" /></svg>
											Gestor de stocks
										</a>
									</div>
									<div className="card shadow-none border-none border-none-0">
										<ul className="nav nav-tabs nav-fill" data-bs-toggle="tabs">
											<li className="nav-item">
												<a href="#tabs-keys" className="nav-link active" data-bs-toggle="tab" onClick={() => this.setState({ stock_tab: 0 })}>
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<circle cx="8.5" cy="8.5" r={1} fill="currentColor"></circle>
														<path d="M4 7v3.859c0 .537 .213 1.052 .593 1.432l8.116 8.116a2.025 2.025 0 0 0 2.864 0l4.834 -4.834a2.025 2.025 0 0 0 0 -2.864l-8.117 -8.116a2.025 2.025 0 0 0 -1.431 -.593h-3.859a3 3 0 0 0 -3 3z"></path>
													</svg>
													Porta-chaves
												</a>
											</li>
											<li className="nav-item">
												<a href="#tabs-standards" className="nav-link" data-bs-toggle="tab" onClick={() => this.setState({ stock_tab: 1 })}>
													<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<rect x={3} y={5} width={18} height={14} rx={3}></rect>
														<line x1={3} y1={10} x2={21} y2={10}></line>
														<line x1={7} y1={15} x2="7.01" y2={15}></line>
														<line x1={11} y1={15} x2={13} y2={15}></line>
													</svg>
													Cartões standard
												</a>
											</li>
										</ul>
										<div className="tab-content">
											<div className="tab-pane active" id="tabs-keys">
												<table className="table card-table table-vcenter datatable p-0">
													<thead>
														<tr>
															<th>Cartões disponíveis</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{
															this.state.stock_k.map((item) => {
																return <tr><td>ID: {item}</td><td className="w-1"><a className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#modal-confirmation" onClick={() => this.setState({ delete_card: item })}><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1={4} y1={7} x2={20} y2={7} /><line x1={10} y1={11} x2={10} y2={17} /><line x1={14} y1={11} x2={14} y2={17} /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>Eliminar</a></td></tr>
															})
														}
													</tbody>
												</table>
											</div>
											<div className="tab-pane" id="tabs-standards">
												<table className="table card-table table-vcenter datatable p-0">
													<thead>
														<tr>
															<th>Cartões disponíveis</th>
															<th></th>
														</tr>
													</thead>
													<tbody>
														{
															this.state.stock_s.map((item) => {
																return <tr><td>ID: {item}</td><td className="w-1"><a className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#modal-confirmation" onClick={() => this.setState({ delete_card: item })}><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1={4} y1={7} x2={20} y2={7} /><line x1={10} y1={11} x2={10} y2={17} /><line x1={14} y1={11} x2={14} y2={17} /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>Eliminar</a></td></tr>
															})
														}
													</tbody>
												</table>
											</div>
										</div>
									</div>

									<div className="card-footer d-flex align-items-center">
										<ul class="list-inline list-inline-dots mb-0">
											<b>Total:&nbsp;&nbsp;</b><li class="list-inline-item">{this.state.stock_k_count} porta-chaves</li><li class="list-inline-item">{this.state.stock_s_count} cartões standard</li>
										</ul>
										{
											this.state.stock_tab == 0 ?
												Number(this.state.stock_k_count) > this.state.perpage &&
												<ul className="pagination m-0 ms-auto scroll-x" style={{ maxWidth: '50%' }}>
													<li onClick={e => this.previousAndNextStockK(e.target, 'b')} className={`page-item ${this.state.stock_k_page == 1 ? 'disabled' : 'cursor-pointer'}`}>
														<a className="page-link">
															<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="15 6 9 12 15 18" /></svg>
															Anterior
														</a>
													</li>
													{
														this.paginationStockK()
													}
													<li onClick={e => this.previousAndNextStockK(e.target, 'f')} className={`page-item ${this.state.stock_k_page == this.state.stock_k_numpages ? 'disabled' : 'cursor-pointer'}`}>
														<a className="page-link">
															Próxima
															<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="9 6 15 12 9 18" /></svg>
														</a>
													</li>
												</ul>
												:
												Number(this.state.stock_s_count) > this.state.perpage &&
												<ul className="pagination m-0 ms-auto scroll-x" style={{ maxWidth: '50%' }}>
													<li onClick={e => this.previousAndNextStockS(e.target, 'b')} className={`page-item ${this.state.stock_s_page == 1 ? 'disabled' : 'cursor-pointer'}`}>
														<a className="page-link">
															<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="15 6 9 12 15 18" /></svg>
															Anterior
														</a>
													</li>
													{
														this.paginationStockS()
													}
													<li onClick={e => this.previousAndNextStockS(e.target, 'f')} className={`page-item ${this.state.stock_s_page == this.state.stock_s_numpages ? 'disabled' : 'cursor-pointer'}`}>
														<a className="page-link">
															Próxima
															<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="9 6 15 12 9 18" /></svg>
														</a>
													</li>
												</ul>
										}
									</div>
								</div>
							</div>
						</div>
						<div className="row gx-lg-4">
							<div className="col-12">
								<div className="card">
									<div className="card-header">
										<h3 className="card-title">Encomendas arquivadas</h3>
									</div>
									<div className="table-responsive">
										<table className="table card-table table-vcenter datatable">
											<thead>
												<tr>
													<th>Encomenda</th>
													<th>Nome</th>
													<th>Morada</th>
													<th>Produtos</th>
													<th>Status</th>
													<th>Total</th>
													<th>Data de início</th>
													{
														this.state.done.length > 0 &&
														<th>Desarquivar</th>
													}
												</tr>
											</thead>
											<tbody>
												{
													this.state.done.map(item => {
														return <tr>
															<td className="w-1">{item.order_id}</td>
															<td>{item.name}</td>
															<td className="text-muted">
																{item.delivery_location.address + ', ' + item.delivery_location.zip + ', ' + item.delivery_location.locality}
															</td>
															<td className="text-muted text-nowrap">
																{item.cart}
															</td>
															<td className="text-muted">
																{
																	item.order_status == 0 && item.references && item.references.expired == "true" ?
																		<span className="badge bg-red">Expirada</span>
																		:
																		{
																			0: <span className="badge bg-yellow">Aguardando pagamento</span>,
																			1: item.payment_date &&
																				<span className="badge bg-blue">{`Pagamento efetuado (${item.payment_date.split(' ')[0]})`}</span>,
																			2: item.delivery_date &&
																				<span className="badge bg-green">{`Encomenda entregue (${item.delivery_date.split(' ')[0]})`}</span>,
																			3: item.refund_date &&
																				<>
																					<span className="badge bg-dark">{`Reembolso (${item.refund_date.split(' ')[0]})`}</span>
																					<span style={{ whiteSpace: 'normal', height: 'auto', textAlign: 'left' }} className="badge bg-dark">"{item.refund_reason}"</span>
																				</>
																		}[item.order_status]
																}
															</td>
															<td>
																<b>€{item.total_cost}</b>
															</td>
															<td className="text-nowrap text-muted">{item.created_at}</td>
															<td className="w-1">
																<a className="btn btn-dark btn-icon" onClick={() => this.desarquivar(item.order_id)}>
																	<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-arrow-back-up" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																		<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																		<path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1"></path>
																	</svg>
																</a>
															</td>
														</tr>
													})
												}
											</tbody>
										</table>
									</div>
									<div className="card-footer d-flex align-items-center">
										<p className="m-0 text-muted"><b>Total:</b> &nbsp;{this.state.count} encomenda{this.state.count != 1 ? 's' : ''}</p>
										{
											Number(this.state.count) > this.state.perpage &&
											<ul className="pagination m-0 ms-auto scroll-x" style={{ maxWidth: '50%' }}>
												<li onClick={e => this.previousAndNext(e.target, 'b')} className={`page-item ${this.state.page == 1 ? 'disabled' : 'cursor-pointer'}`}>
													<a className="page-link">
														<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="15 6 9 12 15 18" /></svg>
														Anterior
													</a>
												</li>
												{this.pagination()}
												<li onClick={e => this.previousAndNext(e.target, 'f')} className={`page-item ${this.state.page == this.state.numpages ? 'disabled' : 'cursor-pointer'}`}>
													<a className="page-link">
														Próxima
														<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="9 6 15 12 9 18" /></svg>
													</a>
												</li>
											</ul>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>



				<div className="modal modal-blur fade" id="modal-stocks-manager" tabIndex={-1} role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Gestor de stocks</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								<div className="card">
									<ul className="nav nav-tabs nav-fill" data-bs-toggle="tabs">
										<li className="nav-item">
											<a href="#tabs-stock-add" className="nav-link active" data-bs-toggle="tab" onClick={() => this.setState({ modal_stock_tab: 0, modal_stock_tab_o: false, modal_stock_tab_z: false })}>Adicionar em massa</a>
										</li>
										<li className="nav-item">
											<a href="#tabs-stock-delete" className="nav-link" data-bs-toggle="tab" onClick={() => this.setState({ modal_stock_tab: 1, modal_stock_tab_o: false, modal_stock_tab_z: false })}>Eliminar em massa</a>
										</li>
										<li className="nav-item">
											<a href="#tabs-stock-export" className="nav-link" data-bs-toggle="tab" onClick={() => this.setState({ modal_stock_tab: 2, modal_stock_tab_o: false, modal_stock_tab_z: false })}>Exportar para cliente</a>
										</li>
									</ul>
									<div className="card-body">
										<div className="tab-content">
											<div className="tab-pane active" id="tabs-stock-add">
												<div className="col mb-3">
													<div className="alert alert-warning mb-3">Poderá adicionar um máximo de 1000 cartões de cada vez.</div>
												</div>
												<div className="col mb-3">
													<label className="form-label">Tipo de cartão</label>
													<div className="mb-1">
														<label className="form-check form-check-inline">
															<input className="form-check-input" name="stock-type-radio" type="radio" defaultValue={0} defaultChecked />
															<span className="form-check-label">Porta-chaves</span>
														</label>
														<label className="form-check form-check-inline">
															<input className="form-check-input" name="stock-type-radio" type="radio" defaultValue={1} />
															<span className="form-check-label">Cartões standard</span>
														</label>
													</div>
												</div>

												<div className="row">
													{
														this.state.stock_last &&
														<>
															<div className="col-lg-6">
																<div className="mb-3">
																	<label className="form-label">De (Cartão ID:)</label>
																	<input type="number" className="form-control" name="stock-add-start" defaultValue={Number(this.state.stock_last) + 1} />
																</div>
															</div>
															<div className="col-lg-6">
																<div className="mb-3">
																	<label className="form-label">Até (Cartão ID:)</label>
																	<input type="number" className="form-control" name="stock-add-end" defaultValue={Number(this.state.stock_last) + 1 + 200} />
																</div>
															</div>
														</>
													}
												</div>
												{
													this.state.modal_stock_tab_z && this.state.modal_stock_tab == 0 &&
													<div>
														<div className="modal-body text-center py-4">
															<svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-yellow icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
															<h3>Tem a certeza?</h3>
															<div className="text-muted">
																Tem a certeza que deseja adicionar estes cartões ao stock?
															</div>
														</div>
														<div className="modal-footer">
															<div className="w-100">
																<div className="row">
																	<div className="col">
																		<a className="btn btn-white w-100" onClick={() => this.setState({ modal_stock_tab_z: false, modal_stock_tab_o: false })}>
																			Não
																		</a>
																	</div>
																	<div className="col">
																		<a className="btn btn-yellow w-100" data-bs-dismiss="modal" onClick={() => this.guardar(0)}>
																			Sim
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												}
											</div>
											<div className="tab-pane" id="tabs-stock-delete">
												<div className="row">
													{
														this.state.stock_last &&
														<>
															<div className="col-lg-6">
																<div className="mb-3">
																	<label className="form-label">De (Cartão ID:)</label>
																	<input type="number" className="form-control" name="stock-delete-start" defaultValue={Number(this.state.stock_last)} />
																</div>
															</div>
															<div className="col-lg-6">
																<div className="mb-3">
																	<label className="form-label">Até (Cartão ID:)</label>
																	<input type="number" className="form-control" name="stock-delete-end" defaultValue={Number(this.state.stock_last)} />
																</div>
															</div>
														</>
													}
												</div>
												{
													this.state.modal_stock_tab_o &&
													<div>
														<div className="modal-body text-center py-4">
															<svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-red icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
															<h3>Tem a certeza?</h3>
															<div className="text-muted">
																Tem a certeza que deseja eliminar estes cartões do stock?
															</div>
														</div>
														<div className="modal-footer">
															<div className="w-100">
																<div className="row">
																	<div className="col">
																		<a className="btn btn-white w-100" onClick={() => this.setState({ modal_stock_tab_z: false, modal_stock_tab_o: false })}>
																			Não
																		</a>
																	</div>
																	<div className="col">
																		<a className="btn btn-red w-100" data-bs-dismiss="modal" onClick={() => this.guardar(1)}>
																			Sim
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												}
											</div>
											<div className="tab-pane" id="tabs-stock-export">
												<div className="col mb-3">
													<div className="alert alert-info mb-3">Apesar deste processo ser automático logo após o pagamento efetuado, poderá haver a necessidade de adicionar cartões a uma conta de forma manual.</div>
												</div>
												<div className="row">
													{
														this.state.stock_last &&
														<>
															<div className="col-lg-6">
																<div className="mb-3">
																	<label className="form-label">De (Cartão ID:)</label>
																	<input type="number" className="form-control" name="stock-export-start" defaultValue={Number(this.state.stock_last)} />
																</div>
															</div>
															<div className="col-lg-6">
																<div className="mb-3">
																	<label className="form-label">Até (Cartão ID:)</label>
																	<input type="number" className="form-control" name="stock-export-end" defaultValue={Number(this.state.stock_last)} />
																</div>
															</div>
															<div className="col-12">
																<label className="form-label">Cliente alvo</label>
																<div className="input-icon mb-3">
																	<span className="input-icon-addon">
																		<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx={12} cy={7} r={4} /><path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" /></svg>
																	</span>
																	<input type="text" className="form-control" placeholder="Adicione o ID do cliente alvo" name="stock-export-client" />
																</div>
															</div>
														</>
													}
												</div>

												{
													this.state.modal_stock_tab_z && this.state.modal_stock_tab == 2 &&
													<div>
														<div className="modal-body text-center py-4">
															<svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-yellow icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
															<h3>Tem a certeza?</h3>
															<div className="text-muted">
																Tem a certeza que deseja exportar estes cartões?
															</div>
														</div>
														<div className="modal-footer">
															<div className="w-100">
																<div className="row">
																	<div className="col">
																		<a className="btn btn-white w-100" onClick={() => this.setState({ modal_stock_tab_z: false, modal_stock_tab_o: false })}>
																			Não
																		</a>
																	</div>
																	<div className="col">
																		<a className="btn btn-yellow w-100" data-bs-dismiss="modal" onClick={() => this.guardar(2)}>
																			Sim
																		</a>
																	</div>
																</div>
															</div>
														</div>
													</div>
												}
											</div>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn me-auto" data-bs-dismiss="modal" onClick={() => this.setState({ modal_stock_tab_z: false, modal_stock_tab_o: false })}>Cancelar</button>
								{
									{
										0: <a className="btn btn-primary ms-auto" onClick={() => this.setState({ modal_stock_tab_z: true, modal_stock_tab_o: false })}>
											<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
												<path stroke="none" d="M0 0h24v24H0z" fill="none" />
												<line x1={12} y1={5} x2={12} y2={19} />
												<line x1={5} y1={12} x2={19} y2={12} />
											</svg>Adicionar
										</a>,
										1: <a className="btn btn-danger" onClick={() => this.setState({ modal_stock_tab_o: true, modal_stock_tab_z: false })}>
											<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
												<path stroke="none" d="M0 0h24v24H0z" fill="none" />
												<line x1={4} y1={7} x2={20} y2={7} />
												<line x1={10} y1={11} x2={10} y2={17} />
												<line x1={14} y1={11} x2={14} y2={17} />
												<path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
												<path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
											</svg>Eliminar
										</a>,
										2: <a className="btn btn-primary ms-auto" onClick={() => this.setState({ modal_stock_tab_z: true, modal_stock_tab_o: false })}>
											<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
												<path stroke="none" d="M0 0h24v24H0z" fill="none" />
												<line x1={12} y1={5} x2={12} y2={19} />
												<line x1={5} y1={12} x2={19} y2={12} />
											</svg>Exportar
										</a>
									}[this.state.modal_stock_tab]
								}
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-confirmation" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-sm modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-status bg-danger"></div>
							<div className="modal-body text-center py-4">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
								<svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-danger icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
								<div className="modal-title">Tem a certeza?</div>
								<div>Esta ação é irreversível! Pretende continuar?</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn bg-white me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => this.deleteCard()}>Eliminar</button>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-contact" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-sm modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-status bg-blue"></div>
							<div className="modal-body text-center py-4">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
								<svg className="icon mb-2" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 202.592 202.592" style={{ enableBackground: 'new 0 0 202.592 202.592', width: '2.5rem', height: '2.5rem' }} xmlSpace="preserve">
									<g>
										<g>
											<path style={{ fill: '#206bc4' }} d="M198.048,160.105l-31.286-31.29c-6.231-6.206-16.552-6.016-23.001,0.433l-15.761,15.761
			c-0.995-0.551-2.026-1.124-3.11-1.732c-9.953-5.515-23.577-13.074-37.914-27.421C72.599,101.48,65.03,87.834,59.5,77.874
			c-0.587-1.056-1.145-2.072-1.696-3.038l10.579-10.565l5.2-5.207c6.46-6.46,6.639-16.778,0.419-23.001L42.715,4.769
			c-6.216-6.216-16.541-6.027-23.001,0.433l-8.818,8.868l0.243,0.24c-2.956,3.772-5.429,8.124-7.265,12.816
			c-1.696,4.466-2.752,8.729-3.235,12.998c-4.13,34.25,11.52,65.55,53.994,108.028c58.711,58.707,106.027,54.273,108.067,54.055
			c4.449-0.53,8.707-1.593,13.038-3.275c4.652-1.818,9.001-4.284,12.769-7.233l0.193,0.168l8.933-8.747
			C204.079,176.661,204.265,166.343,198.048,160.105z M190.683,176.164l-3.937,3.93l-1.568,1.507
			c-2.469,2.387-6.743,5.74-12.984,8.181c-3.543,1.364-7.036,2.24-10.59,2.663c-0.447,0.043-44.95,3.84-100.029-51.235
			C14.743,94.38,7.238,67.395,10.384,41.259c0.394-3.464,1.263-6.95,2.652-10.593c2.462-6.277,5.812-10.547,8.181-13.02l5.443-5.497
			c2.623-2.63,6.714-2.831,9.112-0.433l31.286,31.286c2.394,2.401,2.205,6.492-0.422,9.13L45.507,73.24l1.95,3.282
			c1.084,1.829,2.23,3.879,3.454,6.106c5.812,10.482,13.764,24.83,29.121,40.173c15.317,15.325,29.644,23.27,40.094,29.067
			c2.258,1.249,4.32,2.398,6.17,3.5l3.289,1.95l21.115-21.122c2.634-2.623,6.739-2.817,9.137-0.426l31.272,31.279
			C193.5,169.446,193.31,173.537,190.683,176.164z" />
										</g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
									<g>
									</g>
								</svg>

								<div className="modal-title">{this.state.modal_contact.type}</div>
								<strong><a href={`tel:${this.state.modal_contact.contact}`}>{this.state.modal_contact.contact}</a></strong>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-warning" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-sm modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-status bg-yellow"></div>
							<div className="modal-body text-center py-4">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
								<svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-yellow icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
								<div className="modal-title">Atenção!</div>
								<div>Tem a certeza que pretende arquivar este processo?</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-yellow" data-bs-dismiss="modal" onClick={() => this.arquivar()}>OK</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Page;