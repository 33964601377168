import React, { useEffect } from 'react';
import { BrowserRouter, Redirect, Switch, Route } from 'react-router-dom';
import api from "./utils/api";
import {notify} from "./utils/notify";
import { Global } from "./utils/Global";

import Index from './layouts/Index';
import Workplace from './layouts/Workplace';

class App extends React.Component {
  constructor(props) {
    super(props)


    this.state = {
      session: null,
      ready: false
    }
  }

  componentDidMount() {
		notify('loading', 'A carregar...', Infinity, false)
    
    const _this = this;
    api.post("user", {
      headers: {
        "X-Requested-With": "XMLHttpRequest",
      }
    }).then((response) => {
      if(response.data.user == "100001") {
        _this.setState({ session: true, ready: true })
      } else {
        notify('warning', 'Faça o logout na área de cliente antes de fazer o login no backoffice', Infinity, false)
      }
    }, (error) => {
      _this.setState({ session: null, ready: true })
    });
  }
  render() {
    return this.state.ready &&
      <Global.Provider value={this.state}>
        <BrowserRouter>
          <Switch>
            <Route path="/" exact>
              {
                this.state.session ?
                  <Redirect from='/' to='/workplace' />
                :
                  <Index />
              }
            </Route>
            <Route path="/workplace">
              {
                this.state.session ?
                  <Workplace />
                :
                  <Redirect from='/workplace' to='/' />
              }
            </Route>
            <Redirect from="*" to="/" />
          </Switch>
        </BrowserRouter> 
      </Global.Provider>
  }
}

export default App;
