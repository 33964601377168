import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation } from "react-router-dom";
import axios from 'axios';
import api from "../../utils/api";

import {notify, closeNotify} from "../../utils/notify";

import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Page extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			languages: [{ flag: "PT", code: "pt", lang: "Português" }],
			active_language: "pt",
			title: "",
			edit_title: false,
			description: "",
			edit_description: false,
			products: [],
			features: [],
			pack_type: 1,
			modal: 2,
			product_image: null,
			product_image_deleted: false
		}
	}

	onload() {
		const _this = this;

		this.setState({ edit_title: false, edit_description: false })

		document.title = this.props.target + " • BackOffice";

		if (document.getElementsByClassName('page-pretitle')) {
			document.getElementsByClassName('page-pretitle')[0].innerHTML = "Painel > Páginas";
		}

		if (document.getElementsByClassName('page-title-text')[0]) {
			document.getElementsByClassName('page-title-text')[0].innerHTML = "Produtos";
		}


		api.post("products", {
			hl: this.state.active_language
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			_this.setState({ title: response.data.title, description: response.data.description, products: response.data.products }, () => {
			});
		}, (error) => {
			alert('Erro');
		});
	}

	componentDidMount() {
		const _this = this;

		this.onload();

		api.post("translations", { t: "1" }, {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.setState({
				languages: response.data
			})
		}, (error) => {
			alert('Erro');
		});
	}

	switchLang(params) {
		this.setState({
			active_language: params
		}, () => {
			this.onload();
		})
	}

	addFeature() {
		if (document.querySelector('.modal [name="add-feature-key"]').value.trim().length > 0 && document.querySelector('.modal [name="add-feature-value"]').value.trim().length > 0) {
			var res = this.state.features;

			res.push({
				col1: document.querySelector('.modal [name="add-feature-key"]').value.trim(),
				col2: document.querySelector('.modal [name="add-feature-value"]').value.trim()
			})

			this.setState({ features: res }, () => {
				document.querySelector('.modal [name="add-feature-key"]').value = "";
				document.querySelector('.modal [name="add-feature-value"]').value = "";
			})
		}
	}

	reset() {
		for (var i = 0; i < document.querySelectorAll('.modal input[type="text"]').length; i++) {
			document.querySelectorAll('.modal input[type="text"]')[i].value = "";
		}

		if (document.querySelector('.modal input[name="mais-popular"]')) {
			document.querySelector('.modal input[name="mais-popular"]').checked = false;
		}

		this.setState({ features: [] })
	}

	newModal() {
		if (this.state.modal == 2) {
			this.reset(); this.setState({ modal: 1 })
		}
	}

	deleteFeature(params) {
		var res = this.state.features;
		res.splice(params, 1);

		this.setState({ features: res });
	}

	deleteProduct() {
		const _this = this;

		var obj = JSON.parse(document.getElementById('modal-confirmation').dataset.product);


		notify('loading', 'Aguarde um momento...', Infinity, false)
		api.post("deleteproduct", {
			id: obj[0]
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			var res = _this.state.products;
			res.splice(obj[1], 1);

			_this.setState({ products: res });
			notify('success', 'Eliminado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}


	save() {
		const _this = this;

		function isNumeric(n) { return !isNaN(parseFloat(n)) && isFinite(n); }
		var preco = this.state.pack_type == 1 ? document.querySelector('.modal [name="preco"]').value.trim() : null;
		var valid = true;

		if (this.state.pack_type == 1) {
			preco = preco.replaceAll(',', '.');
			preco = Number(preco).toFixed(2).toString();

			if (isNumeric(preco)) {
				valid = true;
			} else {
				valid = false;
			}
		}

		if (document.querySelector('.modal [name="add-barcode"]').value &&
			document.querySelector('.modal [name="add-title"]').value &&
			document.querySelector('.modal [name="add-description"]').value &&
			document.querySelector('.modal [name="number-cards-k"]').value &&
			document.querySelector('.modal [name="number-cards-s"]').value &&
			document.querySelector('.modal [name="pack-type-radio"]:checked')) {
			if (valid) {
				notify('loading', 'Aguarde um momento...', Infinity, false)
				const guardar = (uploaded) => {
					var data = {
						hl: this.state.active_language,
						image: uploaded ? uploaded : (this.state.product_image_deleted ? "": null),
						barcode: document.querySelector('.modal [name="add-barcode"]').value.trim(),
						titulo: document.querySelector('.modal [name="add-title"]').value.trim(),
						description: document.querySelector('.modal [name="add-description"]').value.trim(),
						caracteristicas: JSON.stringify(this.state.features),
						pack_type: document.querySelector('.modal [name="pack-type-radio"]:checked').value,
						preco: this.state.pack_type == 1 ? preco : null,
						cards_minimo_k: document.querySelector('.modal [name="number-cards-k"]').value,
						cards_minimo_s: document.querySelector('.modal [name="number-cards-s"]').value,
						promotion: document.querySelector('.modal [name="promocao"]') ? document.querySelector('.modal [name="promocao"]').value : null,
						highlight: document.querySelector('.modal [name="mais-popular"]') ? (document.querySelector('.modal [name="mais-popular"]').checked ? "1" : "0") : "0"
					}
	
					if (this.state.modal > 1) {
						data.id = document.querySelector('.modal [type="hidden"]').value.trim();
					}
	
					api.post(this.state.modal == 1 ? "addtoproducts" : "editproduct", data, {
						headers: {
							"X-Requested-With": "XMLHttpRequest"
						}
					}).then((response) => {
						var res = _this.state.products;
	
						if (this.state.modal == 1) {
							data.id = response.data;
							data.caracteristicas = JSON.parse(data.caracteristicas);
							res.push(data);
						} else {
							res.map(item => {
								if (item.id == data.id) {
									item.image = data.image;
									item.barcode = data.barcode;
									item.titulo = data.titulo;
									item.description = data.description;
									item.caracteristicas = JSON.parse(data.caracteristicas);
									item.preco = data.preco;
									item.cards_minimo_k = data.cards_minimo_k;
									item.cards_minimo_s = data.cards_minimo_s;
									item.highlight = data.highlight;
									item.promotion = data.promotion == "0" ? null : data.promotion;
								}
							});
						}
						
						if(document.querySelector('[name="product-image-upload"]')) {
							document.querySelector('[name="product-image-upload"]').value = "";
						}

						_this.setState({ products: res }, () => {
							_this.reset();
							notify('success', 'Atualizado com sucesso!', 6000, false)
						});
					}, (error) => {
						notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
					});
				}

				const uploadImage = (imagefile) => {
					var formData = new FormData();
					formData.append("file", imagefile.files[0]);
			
					axios.post('https://images.takiportugal.com/upload/', formData, {
						headers: {
							"X-Requested-With": "XMLHttpRequest",
							"Content-Type": "multipart/form-data"
						}
					}).then((response) => {
						guardar(response.data)
					}, (error) => {
						if (error.response.status == 400) {
							notify('error', error.response.data[0], 6000, false)
						} else {
							notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
						}
					});
				}

				if(document.querySelector('[name="product-image-upload"]').files.length == 0) {
					guardar();
				} else {
					uploadImage(document.querySelector('[name="product-image-upload"]'));
				}
			} else {
				notify('error', 'Preço inválido. Por favor, corrija-o.', 6000, false)
			}
		} else {
			notify('error', 'Preencha todos os campos.', 6000, false)
		}
	}


	saveMd(params) {
		const _this = this;
		var data = {
			hl: this.state.active_language
		}

		if (params == "1") {
			data.title = this.state.title;
		}

		if (params == "2") {
			data.description = this.state.description;
		}

		notify('loading', 'Aguarde um momento...', Infinity, false)
		if (params == "1" || params == "2") {
			api.post("editproducts", data, {
				headers: {
					"X-Requested-With": "XMLHttpRequest"
				}
			}).then((response) => {
				if (params == "1") {
					_this.setState({ edit_title: false })
				}
				if (params == "2") {
					_this.setState({ edit_description: false })
				}
				notify('success', 'Atualizado com sucesso!', 6000, false)
			}, (error) => {
				notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
			});
		}
	}


	getPrice(price, discount) {
		var numVal1 = Number(price);
		var numVal2 = Number(discount) / 100;
		var totalValue = numVal1 - (numVal1 * numVal2)

		return totalValue.toFixed(2);
	}

	modalPack(params) {
		this.setState({ modal: 2 }, () => {
			var features = JSON.stringify(params.caracteristicas);
			document.querySelector('.modal [type="hidden"]').value = params.id;
			document.querySelector('.modal [name="product-image-upload"]').value = "";
			document.querySelector('.modal [name="add-barcode"]').value = params.barcode;
			document.querySelector('.modal [name="add-title"]').value = params.titulo;
			document.querySelector('.modal [name="add-description"]').value = params.description;
			if (params.preco) {
				this.setState({ pack_type: 1, product_image: params.image, product_image_deleted: false }, () => {
					if (document.querySelector('.modal [name="preco"]')) {
						document.querySelector('.modal [name="preco"]').value = params.preco;
					}
					document.querySelector('.modal [name="promocao"]').value = params.promotion ? params.promotion : '0';
					document.querySelector('.modal [name="number-cards-k"]').value = params.cards_minimo_k;
					document.querySelector('.modal [name="number-cards-s"]').value = params.cards_minimo_s;
				});
				document.querySelectorAll('.modal [name="pack-type-radio"]')[0].click();
			} else {
				this.setState({ pack_type: 2, product_image: params.image, product_image_deleted: false }, () => {
					document.querySelector('.modal [name="promocao"]').value = params.promotion ? params.promotion : '0';
					document.querySelector('.modal [name="number-cards-k"]').value = params.cards_minimo_k;
					document.querySelector('.modal [name="number-cards-s"]').value = params.cards_minimo_s;
				});
				document.querySelectorAll('.modal [name="pack-type-radio"]')[1].click();
			}

			document.querySelector('.modal [name="mais-popular"]').checked = params.highlight == "1" ? true : false;
			this.setState({ features: JSON.parse(features) });
		})
	}

	render() {
		const promotion = <div className="col">
			<label className="form-label">Promoção</label>
			<select class="form-select" name="promocao">
				<option value="0" selected>Sem promoção</option>
				<option value="5">-5%</option>
				<option value="10">-10%</option>
				<option value="15">-15%</option>
				<option value="20">-20%</option>
				<option value="25">-25%</option>
				<option value="30">-30%</option>
				<option value="35">-35%</option>
				<option value="40">-40%</option>
				<option value="45">-45%</option>
				<option value="50">-50%</option>
				<option value="55">-55%</option>
				<option value="60">-60%</option>
				<option value="65">-65%</option>
				<option value="70">-70%</option>
				<option value="75">-75%</option>
				<option value="80">-80%</option>
			</select>
		</div>

		return (
			<>
				<div className="container-xl">
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<div className="page-pretitle">{"Painel > " + this.props.target}</div>
								<h2 className="page-title">
									<Icon svg="Produtos" me="1" />
									<span className="page-title-text"></span>
								</h2>
							</div>
							<div className="col-auto ms-auto">
								<div className="btn-list">
									<a className="btn btn-primary d-none d-sm-inline-block" data-bs-toggle="modal" data-bs-target="#modal-packs" onMouseDown={() => this.newModal()}>
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none" />
											<line x1={12} y1={5} x2={12} y2={19} />
											<line x1={5} y1={12} x2={19} y2={12} />
										</svg>
										Novo pack
									</a>
									<a className="btn btn-primary d-sm-none btn-icon" data-bs-toggle="modal" data-bs-target="#modal-packs" onMouseDown={() => this.newModal()}>
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none" />
											<line x1={12} y1={5} x2={12} y2={19} />
											<line x1={5} y1={12} x2={19} y2={12} />
										</svg>
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="page-body">
					<div className="container-xl">


						<div className="row">
							<div className="col-md-3 d-sm-none">
								<label className="form-label">Idiomas disponíveis</label>
								{
									this.state.languages.length > 0 &&

									<div className="btn-group w-100">
										<button type="button" className={`btn ${this.state.languages[0].code == this.state.active_language && 'btn-primary'}`} onClick={() => this.switchLang(this.state.languages[0].code)}>
											<span className={`flag flag-xs flag-country-${this.state.languages[0].flag.toLowerCase()}`} />
											{this.state.languages[0].lang}
										</button>
										{
											this.state.languages.length > 1 &&

											<button type="button" className={`btn ${this.state.languages[1].code == this.state.active_language && 'btn-primary'}`} onClick={() => this.switchLang(this.state.languages[1].code)}>
												<span className={`flag flag-xs flag-country-${this.state.languages[1].flag.toLowerCase()}`} />
												{this.state.languages[1].lang}
											</button>
										}
										{
											this.state.languages.length > 2 &&

											<div className="btn-group " role="group">
												<button id="btnGroupDrop1" type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Outros</button>
												<div className="dropdown-menu dropdown-menu-end" style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(-87px, 38px)' }} data-popper-placement="bottom-end">
													{
														this.state.languages.map((item, i) => {
															if (i > 1) {
																return <a className={`dropdown-item ${item.code == this.state.active_language && 'btn-primary'}`} onClick={() => this.switchLang(item.code)}>
																	<span className={`flag flag-xs flag-country-${item.flag.toLowerCase()}`} />
																	{item.lang}
																</a>
															}
														})
													}
												</div>
											</div>
										}
									</div>
								}
							</div>

							<div className="col-auto d-none d-sm-inline-block">
								<label className="form-label">Idiomas disponíveis</label>
								<div className="btn-group w-50">
									{
										this.state.languages.map(item => {
											return <button type="button" className={`btn ${item.code == this.state.active_language && 'btn-primary'}`} onClick={() => this.switchLang(item.code)}>
												<span className={`flag flag-xs flag-country-${item.flag.toLowerCase()}`} />
												{item.lang}
											</button>
										})
									}
								</div>
							</div>
						</div>


						<div className="hr-text">
							<span>Cabeçalho</span>
						</div>
						<div className="row mt-4 text-center">
							<div className="row mb-4">
								<div className="col">
									<label className="form-label">Título</label>
									<h2 className="w-auto mx-auto">
										{
											this.state.edit_title ?
												<input type="text" className="form-control bg-white" placeholder="Ex: Escolha o plano certo para si" defaultValue={this.state.title} onInput={e => this.setState({ title: e.target.value.trim() })} />
												:
												this.state.title
										}
									</h2>
									{
										this.state.edit_title ?
											<a className="btn btn-success" data-bs-dismiss="modal" onClick={() => this.saveMd('1')}>
												<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-device-floppy" width={24} height={24} viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><circle cx={12} cy={14} r={2} /><polyline points="14 4 14 8 8 8 8 4" /></svg>
												Guardar
											</a>
											:
											<a className="btn" onClick={() => this.setState({ edit_title: true })}>
												<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-edit" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
													<path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
													<line x1={16} y1={5} x2={19} y2={8} />
												</svg>
												Editar
											</a>
									}
								</div>
							</div>
							<div className="row">
								<div className="col">
									<label className="form-label">Descrição</label>
									<p className="mx-auto">
										{
											this.state.edit_description ?
												<textarea className="form-control bg-white" rows={2} defaultValue={this.state.description} onInput={e => this.setState({ description: e.target.value.trim() })} />
												:
												this.state.description
										}
									</p>
									{
										this.state.edit_description ?
											<a className="btn btn-success" data-bs-dismiss="modal" onClick={() => this.saveMd('2')}>
												<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-device-floppy" width={24} height={24} viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><circle cx={12} cy={14} r={2} /><polyline points="14 4 14 8 8 8 8 4" /></svg>
												Guardar
											</a>
											:
											<a className="btn" onClick={() => this.setState({ edit_description: true })}>
												<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-edit" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
													<path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
													<line x1={16} y1={5} x2={19} y2={8} />
												</svg>
												Editar
											</a>
									}
								</div>
							</div>
						</div>
						<div className="hr-text">
							<span>Produtos</span>
						</div>
						<div className="row gx-lg-4 mt-4">
							<div className="col-12">
								<div className="row">
									{
										this.state.products.map((item, i) => {
											return <div className="col-sm-6 col-lg-3 mb-4">
												<div className="card card-md">
													{
														item.highlight == "1" &&
														<div className="ribbon ribbon-top ribbon-bookmark bg-green">
															<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-filled" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" /></svg>
														</div>
													}
													<div className="card-body text-center">
														<kbd className="float-start">
															{
																item.preco ?
																	(
																		item.promotion ?
																			"Promoção especial"
																			:
																			"Preço c/ IVA"
																	)
																	:
																	"Simulador"
															}
														</kbd>
														<br />
														<h2 className="text-uppercase font-weight-medium mt-3">{item.titulo}</h2>
														<span className="text-muted">{item.description}</span>
														<div className="my-4 text-start">
															{
																item.caracteristicas.map(item => {
																	return <div className="text-muted">{item.col1}<span className="float-end text-black font-weight-medium">{item.col2}</span></div>
																})
															}
														</div>


														{
															item.preco ?
																<>
																	{
																		item.promotion &&
																		<span className="text-muted"><s>&nbsp;{'€' + item.preco}&nbsp;</s></span>
																	}

																	<div className={`display-5 my-${item.promotion ? '1' : '3'}`}>
																		€{item.promotion ? this.getPrice(item.preco, item.promotion) : item.preco}
																		<span className="text-muted subheader">/ano</span>
																	</div>
																</>
																:
																<div className="display-5 my-3">{item.promotion ? '-' + item.promotion + '%' : '?'}</div>
														}


														<div className="text-center mt-4">
															{
																item.preco ?
																	<a className="btn btn-green w-100">Adicionar ao carrinho</a>
																	:
																	<a className="btn btn-dark w-100">Simular preço</a>
															}
														</div>
													</div>
													<div className="card-footer text-center">
														<a className="btn me-3" data-bs-toggle="modal" data-bs-target="#modal-packs" onMouseDown={() => this.modalPack(item)}>
															<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-edit" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																<path stroke="none" d="M0 0h24v24H0z" fill="none" />
																<path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
																<path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
																<line x1={16} y1={5} x2={19} y2={8} />
															</svg>
															Editar
														</a>
														<a className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#modal-confirmation" onMouseDown={() => document.getElementById('modal-confirmation').dataset.product = JSON.stringify([item.id, i])}>
															<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																<path stroke="none" d="M0 0h24v24H0z" fill="none" />
																<line x1={4} y1={7} x2={20} y2={7} />
																<line x1={10} y1={11} x2={10} y2={17} />
																<line x1={14} y1={11} x2={14} y2={17} />
																<path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
																<path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
															</svg>
															Eliminar
														</a>
													</div>
												</div>
											</div>
										})
									}
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-packs" tabIndex={-1} role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{this.state.modal == 1 ? "Novo pack" : "Editar pack"}</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								<div className="row mb-2 align-items-end d-flex">
									<div className="col-9">
										<div className="form-label">Imagem</div>
										<input type="file" name="product-image-upload" className="form-control" accept="image/*" />
									</div>
									{
										this.state.product_image &&
										<>
											<div className="col-3" id="product-image-preview">
												<img src={`https://images.takiportugal.com/public/${this.state.product_image}`} className="s-w50" />
											</div>
											<a className="btn btn-danger w-auto ms-2 mt-2 mb-2" onClick={() => this.setState({ product_image: null, product_image_deleted: true })}>
												<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1={4} y1={7} x2={20} y2={7} /><line x1={10} y1={11} x2={10} y2={17} /><line x1={14} y1={11} x2={14} y2={17} /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
												Eliminar
											</a>
										</>
									}
								</div>
									
								<div className="row mb-2 align-items-end">
									<div className="col-lg-6 col-md-6 col-sm-12">
										<label className="form-label">Código de barras</label>
										<input type="text" className="form-control" name="add-barcode" />
									</div>
									<div className="col-lg-6 col-md-6 col-sm-12">
										<label className="form-label">Título</label>
										<input type="text" className="form-control" name="add-title" />
									</div>
								</div>
								<div className="row mb-2 align-items-end">
									<div className="col">
										<label className="form-label">Descrição</label>
										<input type="text" className="form-control" name="add-description" />
									</div>
								</div>
								<div className="row mb-2 align-items-end">
									<div className="col">
										<label className="form-label">Características</label>
										<div className="row g-2">
											<div className="col">
												<input type="text" className="form-control" name="add-feature-key" placeholder="Ex: Número de cartões" />
											</div>
											<div className="col">
												<input type="text" className="form-control" name="add-feature-value" placeholder="Ex: 10" />
											</div>
											<div className="col-auto">
												<a className="btn btn-white btn-icon" onClick={() => this.addFeature()}>
													<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
														<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
														<line x1="12" y1="5" x2="12" y2="19"></line>
														<line x1="5" y1="12" x2="19" y2="12"></line>
													</svg>
												</a>
											</div>
										</div>
										<ul className="mt-2 mb-0 list-unstyled" style={{ maxHeight: "130px", overflow: "auto" }}>
											{
												this.state.features.map((item, i) => {
													return <li className="text-muted" onClick={() => this.deleteFeature(i)} >
														<svg xmlns="http://www.w3.org/2000/svg" className="icon me-1 text-danger cursor-pointer" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1={18} y1={6} x2={6} y2={18} /><line x1={6} y1={6} x2={18} y2={18} /></svg>
														{item.col1}&nbsp;&nbsp;-&nbsp;&nbsp;{item.col2}
													</li>
												})
											}
										</ul>
									</div>
								</div>
								<div className="row mb-2 align-items-end">
									<div className="col">
										<label className="form-label">Tipo de pack</label>
										<div className="mb-1">
											<label className="form-check form-check-inline">
												<input className="form-check-input" name="pack-type-radio" defaultValue="1" type="radio" defaultChecked onChange={() => this.setState({ pack_type: 1 })} />
												<span className="form-check-label">Preço final</span>
											</label>
											<label className="form-check form-check-inline">
												<input className="form-check-input" name="pack-type-radio" defaultValue="2" type="radio" onChange={() => this.setState({ pack_type: 2 })} />
												<span className="form-check-label">Simulador</span>
											</label>
										</div>
									</div>

									{
										this.state.pack_type == 1 ?
											<div className="col">
												<label className="form-label">Preço</label>
												<div className="input-group mb-2">
													<span className="input-group-text">€</span>
													<input type="text" name="preco" className="form-control" placeholder="Preço" autoComplete="off" />
													<span className="input-group-text">/ano</span>
												</div>
											</div>
											:
											(
												this.state.modal == 2 &&
												promotion
											)
									}

									{
										this.state.modal == 1 && this.state.pack_type == 1 &&
										<div className="row mt-2">
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label className="form-label">Nº de C. standard</label>
												<input type="number" min={0} className="form-control" name="number-cards-s" placeholder="Ex: 10" />
											</div>
											<div className="col-lg-6 col-md-6 col-sm-12">
												<label className="form-label">Nº de porta-chaves</label>
												<input type="number" min={0} className="form-control" name="number-cards-k" placeholder="Ex: 10" />
											</div>
										</div>
									}
								</div>
								<div className="row mb-2 align-items-end">
									{
										this.state.modal == 2 ?
											<div className="row mb-1 align-items-end">
												<div className="col-lg-6 col-md-6 col-sm-12">
													<label className="form-label">{this.state.pack_type == 1 ? "Nº de C. standard" : "Nº mínimo de C. standard"}</label>
													<input type="number" min={0} className="form-control" name="number-cards-s" placeholder="Ex: 10" />
												</div>
												<div className="col-lg-6 col-md-6 col-sm-12">
													<label className="form-label">{this.state.pack_type == 1 ? "Nº de porta-chaves" : "Nº mínimo de porta-chaves"}</label>
													<input type="number" min={0} className="form-control" name="number-cards-k" placeholder="Ex: 10" />
												</div>
												{
													this.state.modal == 2 && this.state.pack_type == 1 ?
														<div className="col-lg-6 col-md-6 col-sm-12 mt-2">
															{promotion}
														</div>
														:
														(null)
												}
												<div className="col-lg-6 col-md-6 col-sm-12 mt-2">
													<label className="form-check form-switch mt-3">
														<input className="form-check-input" type="checkbox" name="mais-popular" />
														<span className="form-check-label">Mais popular</span>
													</label>
												</div>
											</div>
											:
											<div className="row mb-1 align-items-end">
												{
													this.state.pack_type == 2 &&
													<>
														<div className="col-lg-6 col-md-6 col-sm-12">
															<label className="form-label">Nº mínimo de C. standard</label>
															<input type="number" min={0} className="form-control" name="number-cards-s" placeholder="Ex: 10" />
														</div>
														<div className="col-lg-6 col-md-6 col-sm-12">
															<label className="form-label">Nº mínimo de porta-chaves</label>
															<input type="number" min={0} className="form-control" name="number-cards-k" placeholder="Ex: 10" />
														</div>
													</>
												}
											</div>
									}
								</div>
							</div>
							<div className="modal-footer">
								<input type="hidden" />
								{
									this.state.modal == 1 ?
										<>
											<button type="button" className="btn me-auto" onClick={() => this.reset()}>Limpar</button>
											<button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.save()}>
												<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<line x1={12} y1={5} x2={12} y2={19}></line>
													<line x1={5} y1={12} x2={19} y2={12}></line>
												</svg>
												Adicionar
											</button>
										</>
										:
										<a className="btn btn-success" data-bs-dismiss="modal" onClick={() => this.save()}>
											<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-device-floppy" width={24} height={24} viewBox="0 0 24 24" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" strokeWidth={2}><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2" /><circle cx={12} cy={14} r={2} /><polyline points="14 4 14 8 8 8 8 4" /></svg>
											Guardar
										</a>
								}
							</div>
						</div>
					</div>
				</div>
				<div className="modal modal-blur fade" id="modal-confirmation" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-sm modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-status bg-danger" />
							<div className="modal-body text-center py-4">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
								<svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-danger icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
								<div className="modal-title">Tem a certeza?</div>
								<div>Esta ação é irreversível! Pretende continuar?</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn bg-white me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => this.deleteProduct()}>Eliminar</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Page;