import React from 'react';
import { Link, useLocation } from "react-router-dom";
import Logotipo from "../images/logo.png";

function Logo() {
  return (
    <div className="brand-container mb-4">
      <Link to={useLocation().pathname == "/" ? "/" : "/workplace"}>
        <img src={Logotipo} alt="" />
        <div>
          <span className="brand-container-title">BackOffice</span>
          <br />
          <span className="brand-container-subtitle">powered by Infoeco</span>
        </div>
      </Link>
    </div>
  )
}

export default Logo;