const notify = (type, message, duration, action) => {
    var icon = "";

    switch (type) {
      case 'success':
        icon = `<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" style="margin-top: 2px" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M5 12l5 5l10 -10"></path>
        </svg>`;
        break;
      case 'error':
        icon = `<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" style="margin-top: 2px" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <circle cx="12" cy="12" r="9"></circle>
          <path d="M10 10l4 4m0 -4l-4 4"></path>
        </svg>`;
        break;
      case 'info':        
      icon = `<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" style="margin-top: 2px" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
        <circle cx="12" cy="12" r="9"></circle>
        <line x1="12" y1="8" x2="12.01" y2="8"></line>
        <polyline points="11 12 12 12 12 16 13 16"></polyline>
        </svg>`;
        break;
      case 'warning':
        icon = `<svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" style="margin-top: 2px" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 9v2m0 4v.01"></path>
          <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
        </svg>`;
        break;
      default:
        icon = '<span class="loading-spinner"></span>';
    }

    var options = { type: type, icon: icon, message: message, duration: duration, action: action ? { callback: () => { } } : false }
    new exports['Notify'].prototype.constructor(options);
  }

const closeNotify = () => {
  window.Notify.prototype.clearExistingNotifications()
}

export { notify, closeNotify };