import React from 'react';
import api from '../utils/api';
import {notify} from "../utils/notify";

class Password extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            "current": null,
            "new": null,
            "confirm": null
        }
    }

    componentDidMount() {
        
    }

    submit() {
        notify('loading', 'Aguarde um momento...', Infinity, false)
        api.post("changepassword", this.state, {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then((response) => {
            if(document.querySelector("#modal-password form")) {
                document.querySelector("#modal-password form").reset(); 
                notify('success', 'A password foi alterada com sucesso!', 6000, false)
            }
        }, (error) => {
            notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
        });
    }

    render() {
        return (
            <div className="modal modal-blur fade" id="modal-password" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Alterar password</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ boxShadow: "none" }} />
                        </div>
                        <form className="modal-body" onSubmit={e => { e.preventDefault(); this.submit() }}>
                            <div className="mb-3">
                                <label className="form-label">Password atual</label>
                                <input type="password" className="form-control" placeholder="Password atual" onInput={e => this.setState({ current: e.target.value.trim() })} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Nova password</label>
                                <input type="password" className="form-control" placeholder="Nova password" onInput={e => this.setState({ new: e.target.value.trim() })} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Confirmar nova password</label>
                                <input type="password" className="form-control" placeholder="Confirmar nova password" onInput={e => this.setState({ confirm: e.target.value.trim() })} />
                            </div>
                        </form>


                        <div className="modal-footer">
                            <a className="btn" data-bs-dismiss="modal">
                                Cancelar
                            </a>
                            <a className="btn btn-dark ms-auto" data-bs-dismiss="modal" onClick={() => this.submit()}>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-world-upload" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M21 12a9 9 0 1 0 -9 9"></path>
                                    <path d="M3.6 9h16.8"></path>
                                    <path d="M3.6 15h8.4"></path>
                                    <path d="M11.578 3a17 17 0 0 0 0 18"></path>
                                    <path d="M12.5 3c1.719 2.755 2.5 5.876 2.5 9"></path>
                                    <path d="M18 21v-7m3 3l-3 -3l-3 3"></path>
                                </svg>
                                Guardar
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Password;