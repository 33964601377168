import React from 'react';
import { withRouter, Switch, Route, Redirect } from "react-router-dom";
import {notify} from "../utils/notify";

import Header from "../components/Header";
import Footer from "../components/Footer";


import Painel from "../views/workplace/Panel";
import GetParams from "../utils/Getparams";
import Idiomas from "../views/workplace/Idiomas";
import Products from "../views/workplace/Products";
import Orders from "../views/workplace/Orders";
import Sitemap from "../views/workplace/Sitemap";
import Reports from "../views/workplace/Reports";
import Notifications from "../views/workplace/Notifications";
import Clients from "../views/workplace/Clients";
import Settings from '../components/Settings';
import Password from '../components/Password';


class Workplace extends React.Component {
	constructor(props) {
		super(props)

		this.state = {}
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			notify('loading', 'A carregar...', Infinity, false)
		}
	}

	render() {
		return (
			<>
				<div className="antialiased">
					<div className="wrapper">
						<Header />
						<div className="page-wrapper">
							<Switch>
								<Route path="/workplace" exact>
									<Painel />
								</Route>
								<Route path="/workplace/page/:id" exact>
									<GetParams type="Page" />
								</Route>
								<Route path="/workplace/sitemap" exact>
									<Sitemap />
								</Route>
								<Route path="/workplace/users" exact>
									<Clients target="Utilizadores" />
								</Route>
								<Route path="/workplace/orders" exact>
									<Orders target="Encomendas" />
								</Route>
								<Route path="/workplace/products" exact>
									<Products target="Produtos" />
								</Route>
								<Route path="/workplace/reports" exact>
									<Reports target="Reportes" />
								</Route>
								<Route path="/workplace/search/:id" exact>
									<GetParams type="Search" />
								</Route>

								<Route path="/workplace/languages" exact>
									<Idiomas target="Idiomas" />
								</Route>
								<Route path="/workplace/notifications" exact>
									<Notifications target="Notificações" />
								</Route>



								<Redirect from="/workplace/" to="/workplace" />
							</Switch>

							<Settings />
							<Password />
							<Footer />
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(props => <Workplace {...props} />);