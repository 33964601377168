import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation, Link } from "react-router-dom";


import Chartsales from '../../components/panel/Chartsales';
import Chartrevenue from '../../components/panel/Chartrevenue';
import Chartclients from '../../components/panel/Chartclients';
import Chartreports from '../../components/panel/Chartreports';
import Chartraffic from '../../components/panel/Chartraffic';
import Rankingpages from '../../components/panel/Rankingpages';
import Rankinginfluencers from '../../components/panel/Rankinginfluencers';
import Reports from '../../components/panel/Reports';
import Newslettersubs from '../../components/panel/Newslettersubs';
import Orders from '../../components/panel/Orders';
import Total from '../../components/panel/Total';
import Notifications from '../../components/panel/Notifications';

class Painel extends React.Component {
	constructor(props) {
		super(props)

		this.state = {}
	}

	onload() {
		document.title = "Painel • BackOffice";
	}

	componentDidMount() {
		this.onload();
	}
	componentDidUpdate() {
		this.onload();
	}

	render() {
		return (
			<>
				<div className="container-xl">
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<div className="page-pretitle">Back Office</div>
								<h2 className="page-title">
									<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-dashboard" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<circle cx="12" cy="13" r="2"></circle>
										<line x1="13.45" y1="11.55" x2="15.5" y2="9.5"></line>
										<path d="M6.4 20a9 9 0 1 1 11.2 0z"></path>
									</svg>
									<span className="page-title-text">Painel</span>
								</h2>
							</div>
							<div className="col-auto ms-auto">
								<a className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modal-settings">
									<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10.325 4.317c.426 -1.756 2.924 -1.756 3.35 0a1.724 1.724 0 0 0 2.573 1.066c1.543 -.94 3.31 .826 2.37 2.37a1.724 1.724 0 0 0 1.065 2.572c1.756 .426 1.756 2.924 0 3.35a1.724 1.724 0 0 0 -1.066 2.573c.94 1.543 -.826 3.31 -2.37 2.37a1.724 1.724 0 0 0 -2.572 1.065c-.426 1.756 -2.924 1.756 -3.35 0a1.724 1.724 0 0 0 -2.573 -1.066c-1.543 .94 -3.31 -.826 -2.37 -2.37a1.724 1.724 0 0 0 -1.065 -2.572c-1.756 -.426 -1.756 -2.924 0 -3.35a1.724 1.724 0 0 0 1.066 -2.573c-.94 -1.543 .826 -3.31 2.37 -2.37c1 .608 2.296 .07 2.572 -1.065z" /><circle cx={12} cy={12} r={3} /></svg>
									Configurações gerais
								</a>
							</div>
						</div>
					</div>
				</div>
				<div className="page-body">
					<div className="container-xl">
						<div className="row row-deck row-cards">
							<Chartsales />
							<Chartrevenue />
							<Chartclients />
							<Chartreports />
							<div className="col-lg-6">
								<div className="row row-cards">
									<Total />

									<Chartraffic />
								</div>
							</div>
							<div className="col-lg-6">
								<Notifications />
							</div>
							<div className="col-md-8">{/*Notifications */}
								<Orders />
							</div>
							<div className="col-md-4">
								<Newslettersubs />
							</div>
							<div className="col-12">
								<Reports />
							</div>
							<div className="col-md-6 col-lg-4">
								<Rankinginfluencers />
							</div>
							<div className="col-md-12 col-lg-8">
								<Rankingpages />
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Painel;