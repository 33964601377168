import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import api from '../../utils/api';


import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Painel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            reports: []
        }
    }

    onload() {
        document.title = "Painel • BackOffice";
    }

    componentDidMount() {
        const _this = this;
        _this.onload();

        api.post("latestreports", {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then((response) => {
            _this.setState({
                reports: response.data
            })
        }, (error) => {
            alert('Erro');
        });
    }
    componentDidUpdate() {
        this.onload();
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="d-flex align-items-center w-full">
                        <div className="col">
                            <h3 className="card-title">Reportes</h3>
                        </div>
                        <div className="col-auto">
                            <Link className="btn btn-outline-white" to="/workplace/sitemap">
                                <svg xmlns="http://www.w3.org/2000/svg" className="me-2 icon icon-infco icon-infco-flag" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <line x1={5} y1={5} x2={5} y2={21}></line>
                                    <line x1={19} y1={5} x2={19} y2={14}></line>
                                    <path d="M5 5a5 5 0 0 1 7 0a5 5 0 0 0 7 0"></path>
                                    <path d="M5 14a5 5 0 0 1 7 0a5 5 0 0 0 7 0"></path>
                                </svg>
                                Ver todos
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table card-table table-vcenter">
                        <thead>
                            <tr>
                                <th>Processo</th>
                                <th>Cartão ID</th>
                                <th>Encontrado por</th>
                                <th>Contacto</th>
                                <th>Local</th>
                                <th>Dono</th>
                                <th>Status</th>
                                <th>Data de início</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.reports.map(item => {
                                    return <tr>
                                        <td>{item.id}</td>
                                        <td><strong>{item.cartao}</strong></td>
                                        <td>{item.nome}</td>
                                        <td><strong><a href={`tel:${item.contacto}`}>{item.contacto}</a></strong></td>
                                        <td>{item.local}</td>
                                        <td>{item.dono.dono_nome}</td>
                                        <td>{item.status == 0 ? <span className="badge bg-red-lt">Perdido</span> : <span className="badge bg-green-lt">Encontrado</span>}</td>
                                        <td>{item.inicio}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
                <div className="card-footer d-flex align-items-center">
                    <p className="m-0 text-muted" id="total_sitemap"><b>Total:</b> &nbsp;{this.state.reports.length} processo{this.state.reports.length!=1 ?'s ativos':' ativo'}</p>
                </div>
            </div>
        );
    }
}

export default Painel;