import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import api from '../../utils/api';

import {notify} from "../../utils/notify";

import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Painel extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			range: "7",
			days: [],
			f: [],
			reports: 0,
			limit: ["2022-11-10", new Date().toISOString().split('T')[0]]
		}
	}

	onload() {
		document.title = "Painel • BackOffice";
	}


	setGraph() {
		window.setBoChart('chart-reports',
			{
				chart: {
					type: "bar",
					fontFamily: 'inherit',
					height: 40.0,
					sparkline: {
						enabled: true
					},
					animations: {
						enabled: false
					},
				},
				plotOptions: {
					bar: {
						columnWidth: '50%',
					}
				},
				dataLabels: {
					enabled: false,
				},
				fill: {
					opacity: 1,
				},
				series: [{
					name: "Novos reportes",
					data: this.state.f
				}],
				grid: {
					strokeDashArray: 4,
				},
				xaxis: {
					labels: {
						padding: 0,
					},
					tooltip: {
						enabled: false
					},
					type: 'category',
				},
				yaxis: {
					labels: {
						padding: 4
					},
				},
				labels: this.state.days,
				colors: ["#000000"],
				legend: {
					show: false,
				},
			}
		);
	}

	componentDidMount() {
		this.onload();

		this.setGraph();
		this.initGraph(this.state.range);
	}
	componentDidUpdate() {
		this.onload();
	}


	initGraph(range) {
		const _this = this;

		this.setState({ range: range }, () => {
			notify('loading', 'A carregar...', Infinity, false)
			api.post("reportsgraph", { range: _this.state.range }, {
				headers: {
					"X-Requested-With": "XMLHttpRequest"
				}
			}).then((response) => {
				_this.graph(response.data);
			}, (error) => {
				alert('Erro');
			});
		});
	}

	graph(data) {
		var datakeys = Object.keys(data);

		var obj = {
			days: [],
			f: [],
			reports: 0
		}


		datakeys.forEach(key => {
			const month = key.split('-')[1] + '/' + key.split('-')[0]

			Object.keys(data[key]).forEach(k => {
				const zDay = parseInt(k) < 10 ? '0' + k : k;
				obj.days.push(zDay + '/' + month);
				obj.f.push(data[key][k].f);

				obj.reports += data[key][k].f;
			});
		});


		this.setState({
			days: obj.days,
			f: obj.f,
			reports: obj.reports
		}, () => {
			window['chart_reports'].destroy();
			this.setGraph();
		})
	}

	validatecustom(a, value) {
		if (value < this.state.limit[0] || value > this.state.limit[1]) {
			if (a == "start") {
				document.getElementById('reports-datepicker-start').value = "";
			} else {
				document.getElementById('reports-datepicker-end').value = "";
			}
		}
	}


	applyInterval() {
		const start = document.getElementById('reports-datepicker-start').value;
		const end = document.getElementById('reports-datepicker-end').value;

		var diffInMs = new Date(end) - new Date(start)
		var diffInDays = diffInMs / (1000 * 60 * 60 * 24);


		if (start > end) {
			alert('A data de começo não pode ser superior à data do fim.')
		} else if (diffInDays > 90) {
			alert('O intervalo de tempo não pode ser maior que 90 dias.')
		} else {
			notify('loading', 'A carregar...', Infinity, false)
			const _this = this;

			api.post("reportsgraph", {
				range: _this.state.range,
				start: start,
				end: end
			}, {
				headers: {
					"X-Requested-With": "XMLHttpRequest"
				}
			}).then((response) => {
				_this.graph(response.data);
			}, (error) => {
				alert('Erro');
			});
		}
	}

	dirIndicator() {
		var u = Number(this.state.f[this.state.f.length-1]);
		var p = Number(this.state.f[this.state.f.length-2]);
		var pct = null;
		var color = null;
		var dir = null;
		if(p < u) {
			pct = Math.ceil(((u-p) / p)*100)+'%';
			color = "text-green";
			dir = <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="3 17 9 11 13 15 21 7" /><polyline points="14 7 21 7 21 14" /></svg>;
		} else if(p > u) {
			pct = Math.ceil(((u-p) / p)*100) + "%";
			color = "text-red";
			dir = <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="3 7 9 13 13 9 21 17" /><polyline points="21 10 21 17 14 17" /></svg>;
		} else {
			pct = "0%";
			color = "text-yellow";
			dir = <svg xmlns="http://www.w3.org/2000/svg" className="icon ms-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1={5} y1={12} x2={19} y2={12} /></svg>;
		}
		return <span className={`${color} d-inline-flex align-items-center lh-1`}>{pct}{dir}</span>
	}


	render() {
		return (
			<>
				<div className="col-sm-6 col-lg-3">
					<div className="card">
						<div className="card-body">
							<div className="d-flex align-items-center">
								<div className="subheader">Reportes</div>
								<div className="ms-auto lh-1">
									<div className="dropdown">
										<a className="dropdown-toggle text-muted" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
											{
												{
													"7": "Últimos 7 dias",
													"30": "Últimos 30 dias",
													"month": "Desde o início do mês",
													"custom": "Intervalo personalizado"
												}[this.state.range]
											}
										</a>
										<div className="dropdown-menu dropdown-menu-end">
											<a className={`dropdown-item ${!this.state.range || this.state.range == "7" ? 'active' : ''}`} onClick={() => this.initGraph("7")}>Últimos 7 dias</a>
											<a className={`dropdown-item ${this.state.range == "30" ? 'active' : ''}`} onClick={() => this.initGraph("30")}>Últimos 30 dias</a>
											<a className={`dropdown-item ${this.state.range == "month" ? 'active' : ''}`} onClick={() => this.initGraph("month")}>Desde o início do mês</a>
											<a className={`dropdown-item ${this.state.range == "custom" ? 'active' : ''}`} data-bs-toggle="modal" data-bs-target="#modal-reports-interval">Intervalo personalizado</a>
										</div>
									</div>
								</div>
							</div>
							<div className="d-flex align-items-baseline">
								<div className="h1 mb-0 me-2">{this.state.reports}</div>
								{
									this.state.range != "custom" &&

									<div className="me-auto">{ this.dirIndicator() }</div>
								}
							</div>
						</div>
						<div id="chart-reports" className="chart-sm" />
					</div>
				</div>


				<div className="modal modal-blur fade" id="modal-reports-interval" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Intervalo personalizado</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								<div className="row mb-3 align-items-end">
									<div className="col">
										<label className="form-label">De</label>
										<div className="input-icon mb-2">
											<span className="input-icon-addon">
												<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<rect x={4} y={5} width={16} height={16} rx={2} />
													<line x1={16} y1={3} x2={16} y2={7} />
													<line x1={8} y1={3} x2={8} y2={7} />
													<line x1={4} y1={11} x2={20} y2={11} />
													<line x1={11} y1={15} x2={12} y2={15} />
													<line x1={12} y1={15} x2={12} y2={18} />
												</svg>
											</span>
											<input className="form-control" id="reports-datepicker-start" type="date" min={this.state.limit[0]} max={this.state.limit[1]} onChange={e => this.validatecustom('start', e.target.value)} />
										</div>
									</div>
									<div className="col">
										<label className="form-label">Até</label>
										<div className="input-icon mb-2">
											<span className="input-icon-addon">
												<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none" />
													<rect x={4} y={5} width={16} height={16} rx={2} />
													<line x1={16} y1={3} x2={16} y2={7} />
													<line x1={8} y1={3} x2={8} y2={7} />
													<line x1={4} y1={11} x2={20} y2={11} />
													<line x1={11} y1={15} x2={12} y2={15} />
													<line x1={12} y1={15} x2={12} y2={18} />
												</svg>
											</span>
											<input className="form-control" id="reports-datepicker-end" type="date" min={this.state.limit[0]} max={this.state.limit[1]} onChange={e => this.validatecustom('end', e.target.value)} />
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn me-auto" data-bs-dismiss="modal">
									Cancelar
								</button>
								<a className="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.setState({ range: "custom" }, () => this.applyInterval())}>
									<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<path d="M5 12l5 5l10 -10"></path>
									</svg>
									Aplicar
								</a>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Painel;