import React from 'react';
import api from '../../utils/api';
import { notify } from '../../utils/notify';
import axios from 'axios';

class Painel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            subs: [],
            page: 1,
			total: 0
        }
    }

    fetchRows(params) {
        var rows = this.state.subs;
        for (var i = 0; i < params.subs.length; i++) {
            const element = params.subs[i];
            rows.push(element)
        }
        this.setState({ subs: rows });
    }

	loadMore() {
		const _this = this;
		const page = this.state.page + 1;

		this.setState({ page: page }, () => {
			api.post("newslettersubs", {
				page: page
			},{
				headers: {
					"X-Requested-With": "XMLHttpRequest",
				}
			}).then((response) => {
                _this.fetchRows(response.data)
			}, (error) => {
			});
		})
	}

    componentDidMount() {
		const _this = this;
        api.post("newslettersubs", {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            }
        }).then((response) => {
            _this.setState({ total: response.data.count }, () => {
                _this.fetchRows(response.data);
            });
        }, (error) => {
        });
    }
    
    exportCsv() {
		const ept = () => {
            axios({
                url: 'https://takiportugal.com/rest/public/api/export_list',
                method: 'POST',
                data: {list: "1"},
                responseType: 'blob',
            }).then((response) => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', 'subscritores_newsletter.csv');
                document.body.appendChild(link);
                link.click();
            });
        }

        notify('loading', 'A exportar excel...', 6000, false);
		api.post("user", {
            headers: {
            "X-Requested-With": "XMLHttpRequest",
            }
        }).then((response) => {
            if(response.data.user == "100001") {
                ept();
            }
        }, (error) => {
        });
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="col">
                        <h3 className="card-title">Subscritores de newsletter</h3>
                    </div>
                    <div className="col-auto">
                        <a className="btn btn-teal" onClick={() => this.exportCsv()}>
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <ellipse cx={12} cy={6} rx={8} ry={3}></ellipse>
                                <path d="M4 6v6c0 1.657 3.582 3 8 3a19.84 19.84 0 0 0 3.302 -.267m4.698 -2.733v-6"></path>
                                <path d="M4 12v6c0 1.599 3.335 2.905 7.538 2.995m8.462 -6.995v-2m-6 7h7m-3 -3l3 3l-3 3"></path>
                            </svg>
                            Exportar excel
                        </a>
                    </div>
                </div>
                <div className="list-group list-group-flush overflow-auto" style={{ maxHeight: '20rem' }}>
                    {
                        this.state.subs.map((item, i) => {
                            const fl = item.email.charAt(0);
                            return <>
                                {
                                    i == 0 ?
                                        <div className="list-group-header sticky-top">{fl}</div>
                                        :
                                        (
                                            this.state.subs[(i - 1)].email.charAt(0) != fl &&
                                            <div className="list-group-header sticky-top">{fl}</div>
                                        )
                                }
                                <div className="list-group-item">
                                    <div className="row">
                                        <a href={`mailto:${item.email}`}>{item.email}</a>
                                    </div>
                                </div>
                            </>
                        })
                    }
                    {
                        this.state.total != this.state.subs.length &&
                        <div className="list-group-header sticky-top text-center text-black cursor-pointer p-3" onClick={() => this.loadMore()}>Carregar mais...</div>
                    }
                </div>
            </div>
        );
    }
}

export default Painel;