import axios from "axios";

import {notify, closeNotify} from "./notify";

var api = axios.create({
  withCredentials: true,
  baseURL: "https://takiportugal.com/rest/public/api/",
});

var requestsPending = 0;

api.interceptors.request.use(function (config) {
    requestsPending++;
    
    return config;
}, function (error) {
    return Promise.reject(error);
});
api.interceptors.response.use(function (response) {
    requestsPending--;
    if (requestsPending == 0 && document.querySelector('user-notification p') && document.querySelector('user-notification p').innerText == "A carregar...") {
        closeNotify()
    }
    return response;
}, function (error) {
    requestsPending--;
    if (requestsPending == 0 && document.querySelector('user-notification p') && document.querySelector('user-notification p').innerText == "A carregar...") {
      closeNotify()
    }
    return Promise.reject(error);
});


export default api;