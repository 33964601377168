import React from 'react';
import api from "../utils/api";

import BannersModal from '../components/modals/Banners';

function Banners(props) {
  function openModal() {
    document.getElementById('modal_banners_progress-' + props.load.id).classList.remove('d-none');
    setTimeout(() => {
      document.getElementById('modal_banners_progress-' + props.load.id).classList.add('d-none');
    }, 3000)
  }

  function deleteBlock() {
    var obj = {
      id: props.load.id,
      block: props.load.block,
      page: window.location.pathname.split('/')[3]
    }

    document.getElementById('modal-confirmation').dataset.block = JSON.stringify(obj);
  }

  return (
    <>
      <div className="card mt-4">
        <div className="card-header">
          <h3 className="card-title">
            <svg xmlns="http://www.w3.org/2000/svg" className="me-1 icon icon-infco icon-infco-slideshow" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <line x1={15} y1={6} x2="15.01" y2={6} />
              <rect x={3} y={3} width={18} height={14} rx={3} />
              <path d="M3 13l4 -4a3 5 0 0 1 3 0l4 4" />
              <path d="M13 12l2 -2a3 5 0 0 1 3 0l3 3" />
              <line x1={8} y1={21} x2="8.01" y2={21} />
              <line x1={12} y1={21} x2="12.01" y2={21} />
              <line x1={16} y1={21} x2="16.01" y2={21} />
            </svg>
            Banners
            <span class="badge bg-green ms-2">ID {props.load.id}</span>
          </h3>
          <div className="card-actions">
            <a onClick={() => openModal()} className="btn btn-outline-white" data-bs-toggle="modal" data-bs-target={`#modal-banners-${props.load.id}`}>
              <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
                <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
                <line x1={16} y1={5} x2={19} y2={8} />
              </svg>
              Editar
            </a>
            {
              window.location.pathname.split('/')[3] == "848438" || window.location.pathname.split('/')[3] == "192985" ?
                (null)
                :
                <a onClick={() => deleteBlock()} className="btn btn-red ms-2" data-bs-toggle="modal" data-bs-target="#modal-confirmation">
                  <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <line x1={4} y1={7} x2={20} y2={7} />
                    <line x1={10} y1={11} x2={10} y2={17} />
                    <line x1={14} y1={11} x2={14} y2={17} />
                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                  </svg>
                  Eliminar
                </a>
            }
          </div>
        </div>
        <div className="card-body">
          <div id={`carousel-indicators-${props.load.id}`} className="carousel slide" data-bs-ride="carousel">
            <div className="carousel-inner">
              {
                props.load.images.map((item, i) => {
                  return (<>
                    <div className={`carousel-item ${(i == 0 ? 'active' : '')}`}>
                      <img className="d-block w-100" alt="" src={/*require("../images/slide2.jpg").default*/item.src} />
                      <div className="carousel-item-background d-none d-md-block" />
                      <div className="carousel-caption d-none d-md-block">
                        <div className="card">
                          <div className="card-body">
                            <h3 className="card-title">{item.title}</h3>
                            <div className="card-text mb-3">
                              <p>{item.description}</p>
                              {
                                item.buttons.map(button => {
                                  if (button.text != "") {
                                    return <a href={button.link} target="_blank" className="btn btn-primary ms-2">{button.text}</a>
                                  }
                                })
                              }
                            </div>
                            <ol className="carousel-indicators mt-3">
                              {
                                props.load.images.map((indicator, g) => {
                                  return props.load.images.length > 1 ? <li data-bs-target={`#carousel-indicators-${props.load.id}`} data-bs-slide-to={g} className={(i == g ? 'active' : '')} /> : (null)
                                })
                              }
                            </ol>
                          </div>
                        </div>
                      </div>

                    </div>
                  </>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>

      <BannersModal load={props.load} />
    </>
  )
}

export default Banners;