import React from 'react';

function Icon(props) {
  switch (props.svg) {
    case "Banners":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-slideshow`} width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <line x1={15} y1={6} x2="15.01" y2={6} />
          <rect x={3} y={3} width={18} height={14} rx={3} />
          <path d="M3 13l4 -4a3 5 0 0 1 3 0l4 4" />
          <path d="M13 12l2 -2a3 5 0 0 1 3 0l3 3" />
          <line x1={8} y1={21} x2="8.01" y2={21} />
          <line x1={12} y1={21} x2="12.01" y2={21} />
          <line x1={16} y1={21} x2="16.01" y2={21} />
        </svg>
      )
      break;
    case "Info":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-float-right`} width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect width={6} height={6} x={14} y={5} rx={1} />
          <line x1={4} y1={7} x2={10} y2={7} />
          <line x1={4} y1={11} x2={10} y2={11} />
          <line x1={4} y1={15} x2={20} y2={15} />
          <line x1={4} y1={19} x2={20} y2={19} />
        </svg>
      )
      break;
    case "Grid":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-layout-grid`} width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <rect x={4} y={4} width={6} height={6} rx={1} />
          <rect x={14} y={4} width={6} height={6} rx={1} />
          <rect x={4} y={14} width={6} height={6} rx={1} />
          <rect x={14} y={14} width={6} height={6} rx={1} />
        </svg>
      )
      break;
    case "Swiper":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-viewport-wide`} width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M10 12h-7l3 -3m0 6l-3 -3" />
          <path d="M14 12h7l-3 -3m0 6l3 -3" />
          <path d="M3 6v-3h18v3" />
          <path d="M3 18v3h18v-3" />
        </svg>
      )
      break;
    case "Products":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-building-store`} width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1={3} y1={21} x2={21} y2={21}></line>
          <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4"></path>
          <line x1={5} y1={21} x2={5} y2="10.85"></line>
          <line x1={19} y1={21} x2={19} y2="10.85"></line>
          <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4"></path>
        </svg>
      )
      break;
    case "Accordion":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-fold`} width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M12 3v6l3 -3m-6 0l3 3" />
          <path d="M12 21v-6l3 3m-6 0l3 -3" />
          <line x1={4} y1={12} x2={5} y2={12} />
          <line x1={9} y1={12} x2={10} y2={12} />
          <line x1={14} y1={12} x2={15} y2={12} />
          <line x1={19} y1={12} x2={20} y2={12} />
        </svg>
      )
      break;
    case "Utilizadores":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" class={`me-${props.me} icon icon-infco icon-infco-users`} width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <circle cx="9" cy="7" r="4"></circle>
          <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
          <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
          <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
        </svg>
      )
      break;
    case "Encomendas":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" class={`me-${props.me} icon icon-infco icon-infco-mail-forward`} width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5"></path>
          <path d="M3 6l9 6l9 -6"></path>
          <path d="M15 18h6"></path>
          <path d="M18 15l3 3l-3 3"></path>
        </svg>
      )
      break;
    case "Reportes":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-message-report`} width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4" />
          <line x1={12} y1={8} x2={12} y2={11} />
          <line x1={12} y1={14} x2={12} y2="14.01" />
        </svg>
      )
      break;
    case "Gestorsitemap":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-adjustments-alt`} width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <rect x="4" y="8" width="4" height="4"></rect>
          <line x1="6" y1="4" x2="6" y2="8"></line>
          <line x1="6" y1="12" x2="6" y2="20"></line>
          <rect x="10" y="14" width="4" height="4"></rect>
          <line x1="12" y1="4" x2="12" y2="14"></line>
          <line x1="12" y1="18" x2="12" y2="20"></line>
          <rect x="16" y="5" width="4" height="4"></rect>
          <line x1="18" y1="4" x2="18" y2="5"></line>
          <line x1="18" y1="9" x2="18" y2="20"></line>
        </svg>
      )
      break;
    case "Copy":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-copy`} width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <rect x={8} y={8} width={12} height={12} rx={2}></rect>
          <path d="M16 8v-2a2 2 0 0 0 -2 -2h-8a2 2 0 0 0 -2 2v8a2 2 0 0 0 2 2h2"></path>
        </svg>
      )
      break;
    case "Notifications":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon`} width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6" /><path d="M9 17v1a3 3 0 0 0 6 0v-1" /></svg>
      )
      break;
    case "Search":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon`} width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <circle cx={10} cy={10} r={7}></circle>
          <line x1={21} y1={21} x2={15} y2={15}></line>
        </svg>
      )
      break;
    case "Idiomas":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-language`} width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M4 5h7" />
          <path d="M9 3v2c0 4.418 -2.239 8 -5 8" />
          <path d="M5 9c-.003 2.144 2.952 3.908 6.7 4" />
          <path d="M12 20l4 -9l4 9" />
          <path d="M19.1 18h-6.2" />
        </svg>
      )
      break;
    case "Produtos":
      return (
        <svg xmlns="http://www.w3.org/2000/svg" className={`me-${props.me} icon icon-infco icon-infco-building-store`} width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
          <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
          <line x1={3} y1={21} x2={21} y2={21}></line>
          <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4"></path>
          <line x1={5} y1={21} x2={5} y2="10.85"></line>
          <line x1={19} y1={21} x2={19} y2="10.85"></line>
          <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4"></path>
        </svg>
      )
      break;
    default:
      return (null);
  }
}

export default Icon;