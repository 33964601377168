import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import api from '../../utils/api';

import { notify, closeNotify } from "../../utils/notify";

import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Painel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            notifications: [],
            details: []
        }
    }

    onload() {
        document.title = "Painel • BackOffice";
    }

    componentDidMount() {
        const _this = this;
        _this.onload();
        api.post("bo_notifications", {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
            }
        }).then((response) => {
            _this.setState({ notifications: response.data })
        }, (error) => {
        });
    }
    componentDidUpdate() {
        this.onload();
    }

    details(params) {
        notify('loading', 'A carregar...', Infinity, false);
        const _this = this;
        _this.setState({ details: [] }, () => {
            api.post("payment_details", {
                t: params.indexOf('Encomenda') !== -1 ? 'o' : 'r',
                i: params.split('ID: ')[1]
            }, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                }
            }).then((response) => {
                _this.setState({ details: response.data }, () => {
                    closeNotify();
                })
            }, (error) => {
            });
        })
    }

    render() {
        return <>
            <div className="card" style={{ height: '640px' }}>
                <div className="card-header">
                    <div className="d-flex align-items-center w-full">
                        <div className="col">
                            <h3 className="card-title">Notificações mais recentes</h3>
                        </div>
                        <div className="col-auto">
                            <Link className="btn btn-outline-white" to="/workplace/notifications">
                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-bell" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M10 5a2 2 0 0 1 4 0a7 7 0 0 1 4 6v3a4 4 0 0 0 2 3h-16a4 4 0 0 0 2 -3v-3a7 7 0 0 1 4 -6"></path>
                                    <path d="M9 17v1a3 3 0 0 0 6 0v-1"></path>
                                </svg>
                                Ver todas
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-body card-body-scrollable card-body-scrollable-shadow">
                    <div className="divide-y">
                        {
                            this.state.notifications.map(item => {
                                return <div>
                                    <div className="row pt-1 ps-3 pe-2">
                                        <div className="col">
                                            <div className="text-truncate">
                                                {
                                                    {
                                                        1: <strong>🛒 {item.title}</strong>,
                                                        2: <strong>🔄 {item.title}</strong>,
                                                        3: <strong>👤 {item.title}</strong>,
                                                        4: <strong>⏰ {item.title}</strong>,
                                                        5: <strong>⚠️ {item.title}</strong>,
                                                        6: <strong>📩 {item.title}</strong>,
                                                        7: <strong>💵 {item.title}</strong>
                                                    }[item.type]
                                                }
                                            </div>
                                            <div className="text-muted">{item.content}</div>
                                            <small className="subheader">{item.date}</small>
                                        </div>
                                        {
                                            item.type == 7 &&
                                            <div className="col-auto">
                                                <a className="btn btn-white btn-icon mt-2" onClick={() => this.details(item.content)} data-bs-toggle="modal" data-bs-target="#modal-payment-success">
                                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-receipt" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                        <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"></path>
                                                    </svg>
                                                </a>
                                            </div>
                                        }
                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="modal modal-blur fade" id="modal-payment-success" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-sm modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
                        <div className="modal-status bg-success" />
                        <div className="modal-body text-center py-4">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-green icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><circle cx={12} cy={12} r={9} /><path d="M9 12l2 2l4 -4" /></svg>
                            <h3>Pagamento efetuado</h3>
                            <div className="text-muted">
                                {
                                    this.state.details.length > 0 &&
                                    (
                                        this.state.details[0].order_id ?
                                        <div className="col-12">
                                            <div className="card card-report">
                                                <div className="card-body" style={{ textAlign: 'left' }}>
                                                    <div className="card-title">
                                                        Encomenda: {this.state.details[0].order_id}
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <rect x={4} y={5} width={16} height={16} rx={2} />
                                                            <line x1={16} y1={3} x2={16} y2={7} />
                                                            <line x1={8} y1={3} x2={8} y2={7} />
                                                            <line x1={4} y1={11} x2={20} y2={11} />
                                                            <line x1={11} y1={15} x2={12} y2={15} />
                                                            <line x1={12} y1={15} x2={12} y2={18} />
                                                        </svg>
                                                        Data: <strong>{this.state.details[0].created_at}</strong>
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <rect x={3} y={4} width={18} height={16} rx={3}></rect>
                                                            <circle cx={9} cy={10} r={2}></circle>
                                                            <line x1={15} y1={8} x2={17} y2={8}></line>
                                                            <line x1={15} y1={12} x2={17} y2={12}></line>
                                                            <line x1={7} y1={16} x2={17} y2={16}></line>
                                                        </svg>
                                                        Cliente ID: <strong><a>{this.state.details[0].client}</a></strong>
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx={12} cy={7} r={4}></circle>
                                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                                                        </svg>
                                                        Nome: <strong><a>{this.state.details[0].name}</a></strong>
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx={12} cy={12} r={4}></circle>
                                                            <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28"></path>
                                                        </svg>
                                                        Email: <strong><a href={`mailto:${this.state.details[0].email}`}>{this.state.details[0].email}</a></strong>
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx={7} cy={17} r={2}></circle>
                                                            <circle cx={17} cy={17} r={2}></circle>
                                                            <path d="M5 17h-2v-4m-1 -8h11v12m-4 0h6m4 0h2v-6h-8m0 -5h5l3 5"></path>
                                                            <line x1={3} y1={9} x2={7} y2={9}></line>
                                                        </svg>
                                                        Opção de envio: <strong>{this.state.details[0].delivery_option == "0" ? 'Correio Normal' : 'CTT Expresso'}</strong>
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                                                            <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                                                            <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                                                        </svg>
                                                        Morada de entrega: <strong>
                                                            {
                                                                this.state.details[0].delivery_location.address + ', ' + this.state.details[0].delivery_location.zip + ', ' + this.state.details[0].delivery_location.locality
                                                            }
                                                        </strong>
                                                    </div>
                                                    {
                                                        this.state.details[0].delivery_location.address_2 &&
                                                        <div className="mb-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <polyline points="5 12 3 12 12 3 21 12 19 12"></polyline>
                                                                <path d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"></path>
                                                                <path d="M9 21v-6a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v6"></path>
                                                            </svg>
                                                            Morada 2: <strong>
                                                                {
                                                                    this.state.details[0].delivery_location.address_2
                                                                }
                                                            </strong>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.details[0].extra &&
                                                        <div className="mb-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M12 9v2m0 4v.01"></path>
                                                                <path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75"></path>
                                                            </svg>
                                                            Nota extra: <strong>{this.state.details[0].extra}</strong>
                                                        </div>
                                                    }
                                                    {
                                                        this.state.details[0].nif ?
                                                            <div className="mb-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"></path>
                                                                    <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5"></path>
                                                                </svg>
                                                                NIF: <strong>{this.state.details[0].nif}</strong>
                                                            </div>
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        this.state.details[0].billing_address &&
                                                        <div className="mb-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"></path>
                                                            </svg>
                                                            Morada de faturação:<br /><strong>{this.state.details[0].billing_address.name}<br />{this.state.details[0].billing_address.contact}<br />{this.state.details[0].billing_address.email}<br />{this.state.details[0].billing_address.address + ', ' + this.state.details[0].billing_address.zip + ', ' + this.state.details[0].billing_address.locality}</strong>
                                                        </div>
                                                    }
                                                    <hr className="mt-3 mb-3" />
                                                    <div className="d-flex mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx={6} cy={19} r={2}></circle>
                                                            <circle cx={17} cy={19} r={2}></circle>
                                                            <path d="M17 17h-11v-14h-2"></path>
                                                            <path d="M6 5l14 1l-1 7h-13"></path>
                                                        </svg>
                                                        Produtos:
    
                                                    </div>
                                                    <div className="mb-2 ps-0">
                                                        {
                                                            this.state.details[0].invoice.artigos.map(elem => {
                                                                var item = elem;
                                                                return <div className="alert p-1 ps-2 mb-1">
                                                                    <strong>{item.artigo_descricao}</strong>
                                                                    <br />
                                                                    <li className="list-unstyled"><span className="subheader text-muted">{item.artigo_codigo}</span></li>
                                                                    <div className="subheader">
                                                                        <ul className="mb-1 list-unstyled text-dark">
                                                                            {
                                                                                Object.keys(item).map(element => {
                                                                                    if (element != "artigo_codigo" && element != "artigo_descricao") {
                                                                                        return <li>{element}: <span className="text-muted">{item[element]}</span></li>
                                                                                    }
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    <span className="badge bg-green me-1">Preço: {item.preco_unit_c_desconto_c_iva}€</span>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <rect x={4} y={3} width={16} height={18} rx={2}></rect>
                                                            <rect x={8} y={7} width={8} height={3} rx={1}></rect>
                                                            <line x1={8} y1={14} x2={8} y2="14.01"></line>
                                                            <line x1={12} y1={14} x2={12} y2="14.01"></line>
                                                            <line x1={16} y1={14} x2={16} y2="14.01"></line>
                                                            <line x1={8} y1={17} x2={8} y2="17.01"></line>
                                                            <line x1={12} y1={17} x2={12} y2="17.01"></line>
                                                            <line x1={16} y1={17} x2={16} y2="17.01"></line>
                                                        </svg>
                                                        Subtotal: <strong>{this.state.details[0].invoice.total}€</strong>
                                                    </div>
                                                    {
                                                        this.state.details[0].referral &&
                                                        <div className="mb-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <line x1={9} y1={15} x2={15} y2={9}></line>
                                                                <circle cx="9.5" cy="9.5" r=".5" fill="currentColor"></circle>
                                                                <circle cx="14.5" cy="14.5" r=".5" fill="currentColor"></circle>
                                                                <path d="M5 7.2a2.2 2.2 0 0 1 2.2 -2.2h1a2.2 2.2 0 0 0 1.55 -.64l.7 -.7a2.2 2.2 0 0 1 3.12 0l.7 .7a2.2 2.2 0 0 0 1.55 .64h1a2.2 2.2 0 0 1 2.2 2.2v1a2.2 2.2 0 0 0 .64 1.55l.7 .7a2.2 2.2 0 0 1 0 3.12l-.7 .7a2.2 2.2 0 0 0 -.64 1.55v1a2.2 2.2 0 0 1 -2.2 2.2h-1a2.2 2.2 0 0 0 -1.55 .64l-.7 .7a2.2 2.2 0 0 1 -3.12 0l-.7 -.7a2.2 2.2 0 0 0 -1.55 -.64h-1a2.2 2.2 0 0 1 -2.2 -2.2v-1a2.2 2.2 0 0 0 -.64 -1.55l-.7 -.7a2.2 2.2 0 0 1 0 -3.12l.7 -.7a2.2 2.2 0 0 0 .64 -1.55v-1"></path>
                                                            </svg>
                                                            Acerto: <strong>{this.state.details[0].invoice.acerto}€</strong>
                                                            <span className="badge bg-yellow-lt ms-2">CP: {this.state.details[0].referral}</span>
                                                        </div>
                                                    }
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M17.2 7a6 7 0 1 0 0 10"></path>
                                                            <path d="M13 10h-8m0 4h8"></path>
                                                        </svg>
                                                        Total: <strong>{this.state.details[0].invoice.custo_total}€</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <div className="col-12">
                                            <div className="card card-report">
                                                <div className="card-body" style={{ textAlign: 'left' }}>
                                                    <div className="card-title">
                                                        Renovação: {this.state.details[0].renewal_id}
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <rect x={4} y={5} width={16} height={16} rx={2} />
                                                            <line x1={16} y1={3} x2={16} y2={7} />
                                                            <line x1={8} y1={3} x2={8} y2={7} />
                                                            <line x1={4} y1={11} x2={20} y2={11} />
                                                            <line x1={11} y1={15} x2={12} y2={15} />
                                                            <line x1={12} y1={15} x2={12} y2={18} />
                                                        </svg>
                                                        Data: <strong>{this.state.details[0].created_at}</strong>
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <rect x={3} y={4} width={18} height={16} rx={3}></rect>
                                                            <circle cx={9} cy={10} r={2}></circle>
                                                            <line x1={15} y1={8} x2={17} y2={8}></line>
                                                            <line x1={15} y1={12} x2={17} y2={12}></line>
                                                            <line x1={7} y1={16} x2={17} y2={16}></line>
                                                        </svg>
                                                        Cliente ID: <strong><a>{this.state.details[0].client}</a></strong>
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx={12} cy={7} r={4}></circle>
                                                            <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                                                        </svg>
                                                        Nome: <strong><a>{this.state.details[0].name}</a></strong>
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx={12} cy={12} r={4}></circle>
                                                            <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28"></path>
                                                        </svg>
                                                        Email: <strong><a href={`mailto:${this.state.details[0].email}`}>{this.state.details[0].email}</a></strong>
                                                    </div>
                                                    {
                                                        this.state.details[0].nif ?
                                                            <div className="mb-2">
                                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                    <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2"></path>
                                                                    <path d="M14 8h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5m2 0v1.5m0 -9v1.5"></path>
                                                                </svg>
                                                                NIF: <strong>{this.state.details[0].nif}</strong>
                                                            </div>
                                                            :
                                                            (null)
                                                    }
                                                    {
                                                        this.state.details[0].billing_address &&
                                                        <div className="mb-2">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <path d="M5 21v-16a2 2 0 0 1 2 -2h10a2 2 0 0 1 2 2v16l-3 -2l-2 2l-2 -2l-2 2l-2 -2l-3 2m4 -14h6m-6 4h6m-2 4h2"></path>
                                                            </svg>
                                                            Morada de faturação:<br /><strong>{this.state.details[0].billing_address.name}<br />{this.state.details[0].billing_address.contact}<br />{this.state.details[0].billing_address.email}<br />{this.state.details[0].billing_address.address + ', ' + this.state.details[0].billing_address.zip + ', ' + this.state.details[0].billing_address.locality}</strong>
                                                        </div>
                                                    }
                                                    <hr className="mt-3 mb-3" />
                                                    <div className="d-flex mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <circle cx={6} cy={19} r={2}></circle>
                                                            <circle cx={17} cy={19} r={2}></circle>
                                                            <path d="M17 17h-11v-14h-2"></path>
                                                            <path d="M6 5l14 1l-1 7h-13"></path>
                                                        </svg>
                                                        Produtos:
    
                                                    </div>
                                                    <div className="mb-2 ps-0">
                                                        {
                                                            this.state.details[0].invoice.artigos.map(elem => {
                                                                var item = elem;
                                                                return <div className="alert p-1 ps-2 mb-1">
                                                                    <strong>{item.artigo_descricao}</strong>
                                                                    <br />
                                                                    <li className="list-unstyled"><span className="subheader text-muted">{item.artigo_codigo}</span></li>
                                                                    <div className="subheader">
                                                                        <ul className="mb-1 list-unstyled text-dark">
                                                                            {
                                                                                Object.keys(item).map(element => {
                                                                                    if (element != "artigo_codigo" && element != "artigo_descricao") {
                                                                                        return <li>{element}: <span className="text-muted">{item[element]}</span></li>
                                                                                    }
                                                                                })
                                                                            }
                                                                        </ul>
                                                                    </div>
                                                                    <span className="badge bg-green me-1">Preço: {item.preco_unit_c_desconto_c_iva}€</span>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <rect x={4} y={3} width={16} height={18} rx={2}></rect>
                                                            <rect x={8} y={7} width={8} height={3} rx={1}></rect>
                                                            <line x1={8} y1={14} x2={8} y2="14.01"></line>
                                                            <line x1={12} y1={14} x2={12} y2="14.01"></line>
                                                            <line x1={16} y1={14} x2={16} y2="14.01"></line>
                                                            <line x1={8} y1={17} x2={8} y2="17.01"></line>
                                                            <line x1={12} y1={17} x2={12} y2="17.01"></line>
                                                            <line x1={16} y1={17} x2={16} y2="17.01"></line>
                                                        </svg>
                                                        Subtotal: <strong>{this.state.details[0].invoice.total}€</strong>
                                                    </div>
                                                    <div className="mb-2">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                            <path d="M17.2 7a6 7 0 1 0 0 10"></path>
                                                            <path d="M13 10h-8m0 4h8"></path>
                                                        </svg>
                                                        Total: <strong>{this.state.details[0].invoice.custo_total}€</strong>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>;
    }
}

export default Painel;