import React from 'react';
import { Link, useLocation } from "react-router-dom";
import DOMPurify from 'dompurify';

import SwiperModal from '../components/modals/Swiper';

function Swiper(props) {
  function deleteBlock() {
    var obj = {
      id: props.load.id,
      block: props.load.block,
      page: window.location.pathname.split('/')[3]
    }

    document.getElementById('modal-confirmation').dataset.block = JSON.stringify(obj);
  }

  return (<>
    <div className="card mt-4">
      <div className="card-header">
        <h3 className="card-title">
          <svg xmlns="http://www.w3.org/2000/svg" className="me-1 icon icon-infco icon-infco-layout-grid" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x={4} y={4} width={6} height={6} rx={1} />
            <rect x={14} y={4} width={6} height={6} rx={1} />
            <rect x={4} y={14} width={6} height={6} rx={1} />
            <rect x={14} y={14} width={6} height={6} rx={1} />
          </svg>
          Swiper
          <span class="badge bg-purple ms-2">ID {props.load.id}</span>
        </h3>
        <div className="card-actions">
          <a className="btn btn-outline-white" data-bs-toggle="modal" data-bs-target={`#modal-swiper-${props.load.id}`}>
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
              <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
              <line x1={16} y1={5} x2={19} y2={8} />
            </svg>
            Editar
          </a>
          {
            window.location.pathname.split('/')[3] == "848438" || window.location.pathname.split('/')[3] == "192985" ?
              (null)
              :
              <a onClick={() => deleteBlock()} className="btn btn-red ms-2" data-bs-toggle="modal" data-bs-target="#modal-confirmation">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1={4} y1={7} x2={20} y2={7} />
                  <line x1={10} y1={11} x2={10} y2={17} />
                  <line x1={14} y1={11} x2={14} y2={17} />
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </svg>
                Eliminar
              </a>
          }
        </div>
      </div>
      <div className="card-body">
        <section className="mb-3">
          <div className="list-group card-list-group">
            {
              props.load.title != null &&
              <div className="list-group-item">
                <div className="row g-2 align-items-center">
                  <div className="col-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-h-1" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M19 18v-8l-2 2" />
                      <path d="M4 6v12" />
                      <path d="M12 6v12" />
                      <path d="M11 18h2" />
                      <path d="M3 18h2" />
                      <path d="M4 12h8" />
                      <path d="M3 6h2" />
                      <path d="M11 6h2" />
                    </svg>
                  </div>
                  <div className="col">
                    <div className="text-muted">
                      Título
                    </div>
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.load.title) }} />
                  </div>
                </div>
              </div>
            }
            {
              props.load.description != null &&
              <div className="list-group-item">
                <div className="row g-2 align-items-center">
                  <div className="col-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-h-2" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M17 12a2 2 0 1 1 4 0c0 .591 -.417 1.318 -.816 1.858l-3.184 4.143l4 0" />
                      <path d="M4 6v12" />
                      <path d="M12 6v12" />
                      <path d="M11 18h2" />
                      <path d="M3 18h2" />
                      <path d="M4 12h8" />
                      <path d="M3 6h2" />
                      <path d="M11 6h2" />
                    </svg>
                  </div>
                  <div className="col">
                    <div className="text-muted">
                      Descrição
                    </div>
                    <span dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.load.description) }} />
                  </div>
                </div>
              </div>
            }
            {
              props.load.items.map(item => {
                return <div className="list-group-item">
                  <div className="row g-2 align-items-center">
                    <div className="col-auto">
                      <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-square-plus" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x={4} y={4} width={16} height={16} rx={2} />
                        <line x1={9} y1={12} x2={15} y2={12} />
                        <line x1={12} y1={9} x2={12} y2={15} />
                      </svg>
                    </div>
                    <div className="col">
                      <div className="text-muted">
                        Unidade
                      </div>
                      <h4>{item.title}</h4>
                      <p dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(item.text) }} />
                    </div>
                    <div className="col-auto me-2">
                      <a href={item.link != "" ? item.link : ""} target="_blank">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-external-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                          <line x1={10} y1={14} x2={20} y2={4} />
                          <polyline points="15 4 20 4 20 9" />
                        </svg>
                      </a>
                    </div>
                    {
                      item.image != "" &&
                      <div className="col-auto">
                        <span className="avatar rounded" style={{ backgroundImage: "url(https://images.takiportugal.com/public/" + item.image + ")" }} />
                      </div>
                    }
                  </div>
                </div>
              })
            }
            {
              props.load.buttons.map(button => {
                if (button.text != "") {
                  return <div className="list-group-item">
                    <div className="row g-2 align-items-center">
                      <div className="col-auto">
                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-click" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1={3} y1={12} x2={6} y2={12} />
                          <line x1={12} y1={3} x2={12} y2={6} />
                          <line x1="7.8" y1="7.8" x2="5.6" y2="5.6" />
                          <line x1="16.2" y1="7.8" x2="18.4" y2="5.6" />
                          <line x1="7.8" y1="16.2" x2="5.6" y2="18.4" />
                          <path d="M12 12l9 3l-4 2l-2 4l-3 -9" />
                        </svg>
                      </div>
                      <div className="col">
                        <div className="text-muted">
                          Botão
                        </div>
                        {button.text}
                      </div>
                      <div className="col-auto me-2">
                        <a href={button.link} target="_blank">
                          <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-external-link" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path d="M11 7h-5a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-5" />
                            <line x1={10} y1={14} x2={20} y2={4} />
                            <polyline points="15 4 20 4 20 9" />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                }
              })
            }
            {
              <div className="list-group-item">
                <div className="row g-2 align-items-center">
                  <div className="col-auto">
                    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-palette" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                      <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                      <path d="M12 21a9 9 0 1 1 0 -18a9 8 0 0 1 9 8a4.5 4 0 0 1 -4.5 4h-2.5a2 2 0 0 0 -1 3.75a1.3 1.3 0 0 1 -1 2.25"></path>
                      <circle cx="7.5" cy="10.5" r=".5" fill="currentColor"></circle>
                      <circle cx={12} cy="7.5" r=".5" fill="currentColor"></circle>
                      <circle cx="16.5" cy="10.5" r=".5" fill="currentColor"></circle>
                    </svg>
                  </div>
                  <div className="col">
                    <div className="text-muted">
                      Fundo
                    </div>
                    "{props.load.background}"
                  </div>
                  <div className="col-auto">
                  <span className={`avatar rounded bg-${props.load.background ? props.load.background : 'white'}`} style={props.load.background?(props.load.background.indexOf('#')>-1 || props.load.background.indexOf('rgb')>-1?{background:props.load.background}:{}):{}} />
                  </div>
                </div>
              </div>
            }
          </div>
        </section>
        {
          props.load.attach[0].background != "" ?
            <section>
              <label className="form-label">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-paperclip" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9l6.5 -6.5" />
                </svg>
                Anexos
              </label>
              <div className="row g-2">
                {
                  props.load.attach[0].background &&
                  <div className="col-4 col-sm-3"><img src={"https://images.takiportugal.com/public/" + props.load.attach[0].background} /></div>
                }
              </div>
            </section>
            :
            (null)
        }
      </div>
    </div>
    <SwiperModal load={props.load} />
  </>
  )
}

export default Swiper;