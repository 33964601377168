import React from 'react';

function Badge(props) {
  switch (props.type) {
    case "Banners":
      return (
        <span className={`badge bg-green ms-${props.ms}`}>ID {props.id}</span>
      )
      break;
    case "Info":
      return (
        <span className={`badge bg-yellow ms-${props.ms}`}>ID {props.id}</span>
      )
      break;
    case "Grid":
      return (
        <span className={`badge bg-indigo ms-${props.ms}`}>ID {props.id}</span>
      )
      break;
    case "Swiper":
      return (
        <span className={`badge bg-purple ms-${props.ms}`}>ID {props.id}</span>
      )
      break;
    case "Products":
      return (
        <span className={`badge bg-red ms-${props.ms}`}>ID {props.id}</span>
      )
      break;
    case "Accordion":
      return (
        <span className={`badge bg-orange ms-${props.ms}`}>ID {props.id}</span>
      )
      break;
    default:
      return (null);
  }
}

export default Badge;