import React from 'react';

import api from '../../utils/api';

import {notify, closeNotify} from "../../utils/notify";

import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Painel extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			influencers: this.props.load,
            count: this.props.count,
            page: 1,
            perpage: 12,
            numpages: 1
		}
	}

	onload() {
		document.title = "Painel • BackOffice";
	}

	componentDidMount() {
		window.paginationTimer = null;
		this.onload();

        this.setState({
            numpages: Math.ceil(this.state.count / this.state.perpage)
        })
	}
	componentDidUpdate() {
		this.onload();
	}


	pagination() {
		var pages = [];
		var numpages = this.state.numpages;
		if(numpages > 5) {
			if(this.state.page < 4) {
				for (var i = 0; i < 5; i++) {
					var index = i + 1;
					pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
				}
			} else if(this.state.page > 3 && this.state.page < ((numpages-3)+1)){
				for (var i = 0; i < 5; i++) {
					var index = i + 1 + (this.state.page-3);
					pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
				}
			} else {
				for (var i = 0; i < 5; i++) {
					var index = i + 1 + (numpages-5);
					pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
				}
			}
		} else {
			for (var i = 0; i < numpages; i++) {
				var index = i + 1;
				pages.push(<li className={`page-item cursor-pointer ${this.state.page == index ? 'active' : ''}`}><a className="page-link" onClick={e => this.switchPage(e.target.textContent)}>{index}</a></li>);
			}
		}



		return pages;
	}


	switchPage(params) {
		const _this = this;
		if (window.paginationTimer){
			clearTimeout(window.paginationTimer);
		}

		this.setState({
			page: params
		}, () => {
			window.paginationTimer = setTimeout(function(){
				notify('loading', 'Aguarde um momento...', Infinity, false)
				api.post("tusers", {
					page: params.toString(),
                    t: "i"
				}, {
					headers: {
						"X-Requested-With": "XMLHttpRequest"
					}
				}).then((response) => {
					_this.setState({
						influencers: response.data
					})
					window.paginationTimer = null;

					closeNotify();
				}, (error) => {
					notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
				});
			}, 1000);
		})
	}

	previousAndNext(params, direction) {
		if (!params.classList.contains('disabled')) {
			if (direction == "b") {
				this.switchPage(this.state.page - 1)
			} else {
				this.switchPage(this.state.page + 1)
			}
		}
	}

	render() {
		return (
			<>
				<div className="hr-text">Afiliados</div>
				<div className="row row-cards">
					{
						this.state.influencers.map((item, i) => {
							const avatar = item.avatar ? 'https://images.takiportugal.com/public/' + item.avatar : item.fullname.split(' ')[0][0] + item.fullname.split(' ')[item.fullname.split(' ').length - 1][0];

							return <div className="col-md-6 col-lg-3">
								<div className="card">
									<div className="card-status-top bg-yellow" />
									<div className="card-body p-4 text-center">
										<span className="avatar avatar-xl mb-3 avatar-rounded" style={{ backgroundImage: 'url(' + avatar + ')' }}>{!item.avatar ? avatar : ''}</span>
										<h3 className="m-0 mb-2">{item.fullname}</h3>
										<div className="text-muted mb-2">Desde {item.since}</div>
										<div className="mt-2">
											<span className="badge bg-yellow-lt">C. promocional: {item.influencer}</span>
										</div>
									</div>
									<div className="d-flex">
										<a data-bs-toggle="modal" data-bs-target="#modal-details" className="card-btn cursor-pointer" onClick={() => this.props.open(item.id, item.fullname)}>
											<svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
												<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
												<circle cx={12} cy={12} r={9}></circle>
												<line x1={12} y1={8} x2="12.01" y2={8}></line>
												<polyline points="11 12 12 12 12 16 13 16"></polyline>
											</svg>
											Abrir ficha de afiliado
										</a>
									</div>
                                    <div className="d-flex">
                                        <a href={`mailto:${item.email}`} className="card-btn cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                <circle cx={12} cy={12} r={4}></circle>
                                                <path d="M16 12v1.5a2.5 2.5 0 0 0 5 0v-1.5a9 9 0 1 0 -5.5 8.28"></path>
                                            </svg>
                                            Email
                                        </a>
                                        <a data-bs-toggle="modal" data-bs-target="#modal-contact" className="card-btn cursor-pointer" onClick={() => this.props.openc(item.fullname, item.contact)}>
                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon me-2 text-muted" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
                                            </svg>
                                            Contactar
                                        </a>
                                    </div>
								</div>
							</div>
						})
					}
				</div>

                <div className="d-flex mt-4">
					<p className="m-0 text-muted"><b>Total:</b> &nbsp;{this.state.count} afiliado{this.state.count!=1 ?'s':''}</p>
                    {
                       Number(this.state.count) > this.state.perpage &&
                        <ul className="pagination m-0 ms-auto scroll-x" style={{ maxWidth: '50%' }}>
                            <li onClick={e => this.previousAndNext(e.target, 'b')} className={`page-item ${this.state.page == 1 ? 'disabled' : 'cursor-pointer'}`}>
                                <a className="page-link">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="15 6 9 12 15 18" /></svg>
                                    Anterior
                                </a>
                            </li>
                            {this.pagination()}
                            <li onClick={e => this.previousAndNext(e.target, 'f')} className={`page-item ${this.state.page == this.state.numpages ? 'disabled' : 'cursor-pointer'}`}>
                                <a className="page-link">
                                    Próxima
                                    <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><polyline points="9 6 15 12 9 18" /></svg>
                                </a>
                            </li>
                        </ul>
                    }
                </div>
			</>
		);
	}
}

export default Painel;