import React from 'react';
import axios from 'axios';
import api from '../../utils/api';

import Header from '../modals/reusable/Header';
import Footer from '../modals/reusable/Footer';

class Banners extends React.Component {
    constructor(props) {
        super(props)

        this.state = this.props.load
    }

    bannerImageTab(params) {
        var _this = [];

        for (var i = 0; i < this.state.images.length; i++) {
            if (this.state.images[i].id == params) {
                _this = this.state.images[i];
            }
        }

        document.getElementById('modal-banner-editor-' + this.state.id).classList.remove('d-none');

        document.querySelectorAll('[name="modal-banner-title-' + this.state.id + '"]')[0].value = _this.title;
        document.querySelectorAll('[name="modal-banner-description-' + this.state.id + '"]')[0].value = _this.description;

        for (var i = 0; i < _this.buttons.length; i++) {
            document.querySelectorAll('#modal-banner-buttons-tab-' + this.state.id + ' li a')[i].innerHTML = _this.buttons[i].text;
            document.querySelectorAll('#modal-banner-buttons-tab-' + this.state.id + ' li a')[i].setAttribute('data-link', _this.buttons[i].link);
        }
        document.querySelectorAll('#modal-banner-buttons-tab-' + this.state.id + ' li a')[0].click();
    }

    bannerButtonTab(params) {
        document.querySelectorAll('#modal-banner-buttons-tab-' + this.state.id + ' li a')[0].classList.remove('active');
        document.querySelectorAll('#modal-banner-buttons-tab-' + this.state.id + ' li a')[1].classList.remove('active');
        params.classList.add('active');
        document.querySelectorAll('[name=modal-banner-button-text-' + this.state.id + ']')[0].value = params.innerText;
        document.querySelectorAll('[name=modal-banner-button-link-' + this.state.id + ']')[0].value = params.dataset.link;
    }

    componentDidMount() {
        var state = this.state;

        switch (state.type) {
            case "1":
                if (state.images.length > 0) {
                    this.bannerImageTab(state.images[0].id);
                }
                break;
            case "2":
                if (state.images.length > 0) {
                    document.querySelectorAll('#modal-slideshow-tab-images-' + this.state.id + ' [name="form-imagecheck"]')[0].click();
                }
                break;
            default:
                return false
        }
    }

    refresh() {
        setTimeout(() => {
            document.getElementById('blocks-refresh').click();
        }, 2000);
    }

    setChanges(field, val) {
        var state = this.state;
        var tab = (state.type=="1"?(state.images.length?state.images[0].id:""):(state.images.length?document.querySelectorAll('#modal-slideshow-tab-images-' + this.state.id + ' input:checked')[0].value : ""));
        var _this;
        var val = val.trim();

        if(state.images.length) {
            for (var i = 0; i < state.images.length; i++) {
                if (state.images[i].id == tab) {
                    _this = state.images[i];
                }
            }
        }

        if (field instanceof Array) {
            if (field[0] == "button") {
                for (var i = 0; i < _this.buttons.length; i++) {
                    if (field[1] == "text") {
                        if (_this.buttons[i].text == document.querySelectorAll('#modal-banner-buttons-tab-' + state.id + ' .active')[0].innerText) {
                            _this.buttons[i].text = val;
                            document.querySelectorAll('#modal-banner-buttons-tab-' + state.id + ' .active')[0].innerText = val;
                        }
                    } else {
                        if (_this.buttons[i].link == document.querySelectorAll('#modal-banner-buttons-tab-' + state.id + ' .active')[0].dataset.link) {
                            _this.buttons[i].link = val;
                            document.querySelectorAll('#modal-banner-buttons-tab-' + state.id + ' .active')[0].setAttribute('data-link', val);
                        }
                    }
                }
            } else {
                if (field[1] == "add") {
                    state.images.push({
                        id: val.split('.')[0],
                        src: "https://images.takiportugal.com/public/" + val,
                        title: "",
                        description: "",
                        buttons: [
                            {
                                text: "",
                                link: ""
                            },
                            {
                                text: "",
                                link: ""
                            }
                        ]
                    });

                    setTimeout(() => {
                        if(document.querySelectorAll('#modal-slideshow-tab-images-' + state.id + ' input').length) {
                            document.querySelectorAll('#modal-slideshow-tab-images-' + state.id + ' input[value="'+val.split('.')[0]+'"]')[0].click();
                        }

                        if(state.type == "1") {
                            this.bannerImageTab(val.split('.')[0]);
                        }
                    }, 1000);
                } else {
                    for (var i = 0; i < state.images.length; i++) {
                        if (state.images[i].id == tab) {
                            state.images.splice(i, (i+1));
                            if(state.images.length) {
                                this.bannerImageTab(state.images[0].id);
                                document.querySelectorAll('#modal-slideshow-tab-images-' + this.state.id + ' input')[0].checked = true;
                            } else {
                                document.getElementById('modal-banner-editor-' + this.state.id).classList.add('d-none');
                            }
                        }
                    }
                }
            }
        } else {
            for (var i = 0; i < state.images.length; i++) {
                if (state.images[i].id == tab) {
                    _this[field] = val;
                }
            }
        }

        this.setState(state);
    }

    uploadImage(imagefile) {
        const _this = this;
        var formData = new FormData();
        formData.append("file", imagefile.files[0]);
        axios.post('https://images.takiportugal.com/upload/', formData, {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            "Content-Type": "multipart/form-data"
          }
        }).then((response) => {
          _this.setChanges(['image', 'add'], response.data);
        }, (error) => {
          alert('Erro');
        });
    }

    deleteImage() {
        this.setChanges(['image', 'delete'], '');
    }

    render() {
        return (
            <>
                <div className="modal modal-blur fade" id={`modal-banners-${this.state.id}`} tabIndex={-1} role="dialog" aria-hidden="true">
                    <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
                        <div className="modal-content">
                            <Header type="Banners" id={this.state.id} load={this.state} />
                            <div id={`modal_banners_progress-${this.state.id}`} className="progress"><div className="progress-bar progress-bar-indeterminate bg-blue"></div></div>
                            <div className="modal-body">

                                {
                                    this.state.type == "1" &&
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <div className="form-label">Imagem / Thumbnail de vídeo</div>
                                                <input type="file" className="form-control" onChange={(e) => this.uploadImage(e.target)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div className="row g-2 mb-2">
                                                <div className="col-4 col-sm-3" id={`modal-simple-banner-tab-image-${this.state.id}`}>{this.state.images.length > 0 ? <img src={this.state.images[0].src} /> : (null)}</div>
                                            </div>
                                        </div>
                                    </div>
                                }

                                {
                                    this.state.type == "2" &&
                                    <div className="row mb-2">
                                        <div className="col-lg-12">
                                            <div className="mb-3">
                                                <div className="form-label">Imagens / Thumbnails de vídeo</div>
                                                <input type="file" className="form-control" onChange={(e) => this.uploadImage(e.target)} />
                                            </div>
                                        </div>
                                        <div className="col-lg-12">
                                            <div id={`modal-slideshow-tab-images-${this.state.id}`} className="row g-2 radio-group">
                                                {
                                                    this.state.images.map(item => {
                                                        return (
                                                            <div className="col-4 col-sm-3">
                                                                <label className="form-imagecheck mb-2">
                                                                    <input type="radio" name="form-imagecheck" className="form-imagecheck-input" defaultValue={item.id} onChange={(e) => this.bannerImageTab(e.target.value)} />
                                                                    <span className="form-imagecheck-figure">
                                                                        <img src={item.src} className="form-imagecheck-image" />
                                                                    </span>
                                                                </label>
                                                            </div>
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                }


                                <div id={`modal-banner-editor-${this.state.id}`} className="d-none">
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <a className="btn btn-red" onClick={() => this.deleteImage()}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <line x1={4} y1={7} x2={20} y2={7} />
                                                    <line x1={10} y1={11} x2={10} y2={17} />
                                                    <line x1={14} y1={11} x2={14} y2={17} />
                                                    <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                    <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                </svg>
                                                Eliminar
                                            </a>
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <label className="form-label">Título</label>
                                            <input type="text" name={`modal-banner-title-${this.state.id}`} className="form-control" defaultValue="" onChange={e => this.setChanges('title', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">
                                        <div className="col-lg-12">
                                            <label className="form-label">Descrição</label>
                                            <textarea name={`modal-banner-description-${this.state.id}`} className="form-control" rows={3} defaultValue="" onChange={e => this.setChanges('description', e.target.value)} />
                                        </div>
                                    </div>
                                    <div className="row mb-3">

                                        <div className="col-lg-12">
                                            <label className="form-label">Botões</label>
                                            <div className="card">
                                                <div className="card-header">
                                                    <ul className="nav nav-pills card-header-pills" id={`modal-banner-buttons-tab-${this.state.id}`}>
                                                        <li className="nav-item"><a className="nav-link" data-link="" onClick={e => this.bannerButtonTab(e.target)}></a></li>
                                                        <li className="nav-item"><a className="nav-link" data-link="" onClick={e => this.bannerButtonTab(e.target)}></a></li>
                                                    </ul>
                                                </div>
                                                <div className="card-body">
                                                    <div className="col-lg-12">
                                                        <div className="row mb-3 align-items-end">
                                                            <div className="col">
                                                                <label className="form-label">Texto do botão</label>
                                                                <input type="text" className="form-control" name={`modal-banner-button-text-${this.state.id}`} placeholder="Exemplo: Saber mais" onChange={e => this.setChanges(['button', 'text'], e.target.value)} />
                                                            </div>
                                                        </div>
                                                        <div className="mb-3">
                                                            <label className="form-label">Hiperligação / Link de vídeo (Youtube ou Vimeo)</label>
                                                            <input type="text" className="form-control" name={`modal-banner-button-link-${this.state.id}`} placeholder="Exemplo: https://takiportugal.com/" onChange={e => this.setChanges(['button', 'link'], e.target.value)} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>







                            <Footer load={this.state} />
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Banners;