import React from 'react';
import axios from 'axios';
import fontag from '../../utils/fontag';
import { Link, useLocation } from "react-router-dom";

import Header from '../modals/reusable/Header';
import Footer from '../modals/reusable/Footer';
import BackgroundColor from '../Backgrounds';

class Swiper extends React.Component {
    constructor(props) {
        super(props)

        this.state = this.props.load
    }

    infoItemTab(params) {
        for (var i = 0; i < this.state.items.length; i++) {
            if(document.querySelectorAll('#modal-info-items-tab-' + this.state.id + ' li .active').length > 0) {
                document.querySelectorAll('#modal-info-items-tab-' + this.state.id + ' li a')[i].classList.remove('active');
            }
        }
        params.classList.add('active');
        document.querySelectorAll('[name=modal-info-item-title-' + this.state.id + ']')[0].value = params.innerText;
        document.querySelectorAll('[name=modal-info-item-text-' + this.state.id + ']')[0].value = params.dataset.text;
        document.querySelectorAll('[name=modal-info-item-link-' + this.state.id + ']')[0].value = params.dataset.link;
        document.getElementById('modal-info-item-image-' + this.state.id).innerHTML = "";
        if(params.dataset.image != "") {
            document.getElementById('modal-info-item-image-' + this.state.id).innerHTML = '<img src="https://images.takiportugal.com/public/' + params.dataset.image + '" />';
        } else {
            document.getElementById('modal-info-item-image-' + this.state.id).innerHTML = "";
        }
    }
    bannerButtonTab(params) {
        document.querySelectorAll('#modal-banner-buttons-tab-' + this.state.id + ' li a')[0].classList.remove('active');
        document.querySelectorAll('#modal-banner-buttons-tab-' + this.state.id + ' li a')[1].classList.remove('active');
        params.classList.add('active');
        document.querySelectorAll('[name=modal-banner-button-text-' + this.state.id + ']')[0].value = params.innerText;
        document.querySelectorAll('[name=modal-banner-button-link-' + this.state.id + ']')[0].value = params.dataset.link;
    }

    setChanges(field, val) {
        var state = this.state;
        var id = Math.floor(Math.random() * (1000000 - 100000 + 1)) + 100000;
        var tab = state.items.length ? document.querySelectorAll('#modal-info-items-tab-' + state.id + ' li .active')[0].dataset.id : "";
        var _this;
        var val = val.trim();

        if (field == "item") {
            if (val == "add") {
                state.items.push({
                    id: id,
                    title: "",
                    text: "",
                    link: "",
                    image: ""
                });
            } else {
                for (var i = 0; i < state.items.length; i++) {
                    if (state.items[i].id == tab) {
                        state.items.splice(i, (i + 1));
                        if (state.items.length) {
                            setTimeout(() => {
                                this.infoItemTab(document.querySelectorAll('#modal-info-items-tab-' + this.state.id + ' li a[data-id="'+state.items[0].id+'"]')[0]);
                            }, 600);
                        } else {
                            document.querySelectorAll('[name=modal-info-item-title-' + this.state.id + ']')[0].value = "";
                            document.querySelectorAll('[name=modal-info-item-text-' + this.state.id + ']')[0].value = "";
                            document.querySelectorAll('[name=modal-info-item-link-' + this.state.id + ']')[0].value = "";
                            document.getElementById('modal-info-item-image-' + this.state.id).innerHTML = "";
                        }
                    }
                }
            }
        }

        if (field instanceof Array) {
            if (field[0] == "button") {
                for (var i = 0; i < state.buttons.length; i++) {
                    if (field[1] == "text") {
                        if (state.buttons[i].text == document.querySelectorAll('#modal-banner-buttons-tab-' + state.id + ' .active')[0].innerText) {
                            state.buttons[i].text = val;
                            document.querySelectorAll('#modal-banner-buttons-tab-' + state.id + ' .active')[0].innerText = val;
                        }
                    } else {
                        if (state.buttons[i].link == document.querySelectorAll('#modal-banner-buttons-tab-' + state.id + ' .active')[0].dataset.link) {
                            state.buttons[i].link = val;
                            document.querySelectorAll('#modal-banner-buttons-tab-' + state.id + ' .active')[0].setAttribute('data-link', val);
                        }
                    }
                }
            }

            if (field[0] == "item") {
                for (var i = 0; i < state.items.length; i++) {
                    if (field[1] == "title") {
                        if (state.items[i].id == document.querySelectorAll('#modal-info-items-tab-' + state.id + ' .active')[0].dataset.id) {
                            state.items[i].title = val;
                            document.querySelectorAll('#modal-info-items-tab-' + state.id + ' .active')[0].innerText = val;
                        }
                    } else if(field[1] == "text") {
                        if (state.items[i].id == document.querySelectorAll('#modal-info-items-tab-' + state.id + ' .active')[0].dataset.id) {
                            state.items[i].text = val;
                            document.querySelectorAll('#modal-info-items-tab-' + state.id + ' .active')[0].setAttribute('data-text', val);
                        }
                    } else if(field[1] == "link") {
                        if (state.items[i].id == document.querySelectorAll('#modal-info-items-tab-' + state.id + ' .active')[0].dataset.id) {
                            state.items[i].link = val;
                            document.querySelectorAll('#modal-info-items-tab-' + state.id + ' .active')[0].setAttribute('data-link', val);
                        }
                    } else {
                        if (state.items[i].id == document.querySelectorAll('#modal-info-items-tab-' + state.id + ' .active')[0].dataset.id) {
                            state.items[i].image = val;
                            document.querySelectorAll('#modal-info-items-tab-' + state.id + ' .active')[0].setAttribute('data-image', val);
                            document.querySelectorAll('#modal-info-items-tab-' + state.id + ' .active')[0].click()
                        }
                    }
                }
            }

            if (field[0] == "attach" && field[1] == "background") {
                state.attach[0].background = val;
            }
        }

        this.setState(state);
    }

    uploadImage(type, imagefile) {
        const _this = this;
        var formData = new FormData();
        formData.append("file", imagefile.files[0]);

        axios.post('https://images.takiportugal.com/upload/', formData, {
            headers: {
                "X-Requested-With": "XMLHttpRequest",
                "Content-Type": "multipart/form-data"
            }
        }).then((response) => {
            switch (type) {
                case "background":
                    _this.setChanges(['attach', 'background'], response.data);
                    break;
                case "image":
                    _this.setChanges(['item', 'image'], response.data);
            }
        }, (error) => {
            alert('Erro');
        });
    }

    deleteItemImage() {
        this.setChanges(['item', 'image'], '');
    }

    deleteBackground() {
        this.setChanges(['attach', 'background'], '');
    }

    componentDidMount() {
        if (this.state.items.length) {
            document.querySelectorAll('#modal-info-items-tab-' + this.state.id + ' li a')[0].click();
        }
        document.querySelectorAll('#modal-banner-buttons-tab-' + this.state.id + ' li a')[0].click();


        window.boTextEditor(this.state.id);
    }

    render() {
        return (
            <div className="modal modal-blur fade" id={`modal-swiper-${this.state.id}`} tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <Header type="Swiper" id={this.state.id} load={this.state} />
                        <div className="modal-body">
                            <div className="row mb-3">
                                <div className="col-lg-11">
                                    <label className="form-label">Título</label>
                                    <input type="text" className="form-control" defaultValue={fontag(this.state.title, 't')} onChange={e => this.setState({ title: e.target.value })} />
                                </div>
                                <div className="col-lg-1">
                                    <label className="form-label">Cor</label>
                                    <input type="color" name={`modal-${this.state.id}-color-title`} className="form-control form-control-color" defaultValue={fontag(this.state.title, 'c')} />
                                </div>
                            </div>
                            <div className="row mb-3">
                                <div className="col-lg-11">
                                    <label className="form-label">Descrição</label>
                                    <textarea className="form-control" rows={3} defaultValue={fontag(this.state.description, 't')} onChange={e => this.setState({ description: e.target.value })} />
                                </div>
                                <div className="col-lg-1">
                                    <label className="form-label">Cor</label>
                                    <input type="color" name={`modal-${this.state.id}-color-description`} className="form-control form-control-color" defaultValue={fontag(this.state.description, 'c')} />
                                </div>
                            </div>


                            <div className="row mb-3">
                                <div className="col-lg-12">
                                    <label className="form-label">Unidades</label>
                                    <div className="card">
                                        <div className="card-header">
                                            <ul className="nav nav-pills card-header-pills" id={`modal-info-items-tab-${this.state.id}`}>
                                                {
                                                    this.state.items.map((item, g) => {
                                                        return <li className="nav-item"><a className="nav-link" data-id={item.id} data-text={item.text} data-link={item.link} data-image={item.image} onClick={e => this.infoItemTab(e.target)}>{item.title}</a></li>
                                                    })
                                                }
                                                <li className="nav-item ms-auto">
                                                    <a onClick={() => this.setChanges('item', 'add')} className="btn btn-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <line x1={12} y1={5} x2={12} y2={19} />
                                                            <line x1={5} y1={12} x2={19} y2={12} />
                                                        </svg>
                                                    </a>
                                                </li>
                                                <li className="nav-item ms-2">
                                                    <a onClick={() => this.setChanges('item', 'delete')} className="btn btn-red btn-icon">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <line x1={4} y1={7} x2={20} y2={7} />
                                                            <line x1={10} y1={11} x2={10} y2={17} />
                                                            <line x1={14} y1={11} x2={14} y2={17} />
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                        </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <div className="col-lg-12">
                                                <div className="mb-3">
                                                    <label className="form-label">Imagem</label>
                                                    <input type="file" className="form-control" onChange={(e) => this.uploadImage('image', e.target)} />
                                                </div>
                                                <div className="mb-3">
                                                    <div className="col-lg-12">
                                                        <div className="row g-2 radio-group">
                                                            <div className="col-4 col-sm-3" id={`modal-info-item-image-${this.state.id}`}></div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row mb-3">
                                                    <div className="col-lg-12">
                                                        <a onClick={() => this.deleteItemImage()} className="btn btn-red">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                                <line x1={4} y1={7} x2={20} y2={7} />
                                                                <line x1={10} y1={11} x2={10} y2={17} />
                                                                <line x1={14} y1={11} x2={14} y2={17} />
                                                                <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                                <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                            </svg>
                                                            Eliminar
                                                        </a>
                                                    </div>
                                                </div>
                                                <div className="mb-3 align-items-end">
                                                    <div className="col">
                                                        <label className="form-label">Título</label>
                                                        <input type="text" className="form-control" name={`modal-info-item-title-${this.state.id}`} defaultValue="" placeholder="Exemplo: Saber mais" onChange={e => this.setChanges(['item', 'title'], e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="mb-3 align-items-end">
                                                    <div className="col">
                                                        <label className="form-label">Texto</label>
                                                        <div>
                                                            <div id={`editor-${this.state.id}`} className="wysiwyg">
                                                                <textarea name={`modal-info-item-text-${this.state.id}`} defaultValue="" />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="mb-3 align-items-end">
                                                    <div className="col">
                                                        <label className="form-label">Hiperligação</label>
                                                        <div className="row g-2">
                                                            <div className="col">
                                                                <input type="text" className="form-control" name={`modal-info-item-link-${this.state.id}`} defaultValue="" placeholder="Exemplo: Saber mais" onChange={e => this.setChanges(['item', 'link'], e.target.value)} />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row mb-3">

                                <div className="col-lg-12">
                                    <label className="form-label">Botões</label>
                                    <div className="card">
                                        <div className="card-header">
                                            <ul className="nav nav-pills card-header-pills" id={`modal-banner-buttons-tab-${this.state.id}`}>
                                                <li className="nav-item"><a className="nav-link" data-link={this.state.buttons[0].link} onClick={e => this.bannerButtonTab(e.target)}>{this.state.buttons[0].text}</a></li>
                                                <li className="nav-item"><a className="nav-link" data-link={this.state.buttons[1].link} onClick={e => this.bannerButtonTab(e.target)}>{this.state.buttons[1].text}</a></li>
                                            </ul>
                                        </div>
                                        <div className="card-body">
                                            <div className="col-lg-12">
                                                <div className="row mb-3 align-items-end">
                                                    <div className="col">
                                                        <label className="form-label">Texto do botão</label>
                                                        <input type="text" className="form-control" name={`modal-banner-button-text-${this.state.id}`} placeholder="Exemplo: Saber mais" onChange={e => this.setChanges(['button', 'text'], e.target.value)} />
                                                    </div>
                                                </div>
                                                <div className="mb-3">
                                                    <label className="form-label">Hiperligação</label>
                                                    <input type="text" className="form-control" name={`modal-banner-button-link-${this.state.id}`} placeholder="Exemplo: https://takiportugal.com/" onChange={e => this.setChanges(['button', 'link'], e.target.value)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <div className="form-label">Imagens</div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group mb-3 row">
                                        <div className="col">
                                            <small className="form-hint">Imagem de fundo </small>
                                            <input type="file" className="form-control" onChange={(e) => this.uploadImage('background', e.target)} />
                                        </div>
                                    </div>
                                    {
                                        this.state.attach[0].background != "" &&
                                        <>
                                            <div className="row mb-3">
                                                <div className="col-lg-12">
                                                    <div className="row g-2 radio-group">
                                                        <div className="col-4 col-sm-3">
                                                            <img src={'https://images.takiportugal.com/public/' + this.state.attach[0].background} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row mb-3">
                                                <div className="col-lg-12">
                                                    <a onClick={() => this.deleteBackground()} className="btn btn-red">
                                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                            <line x1={4} y1={7} x2={20} y2={7} />
                                                            <line x1={10} y1={11} x2={10} y2={17} />
                                                            <line x1={14} y1={11} x2={14} y2={17} />
                                                            <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                                                            <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                                                        </svg>
                                                        Eliminar
                                                    </a>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                            </div>

                            <BackgroundColor id={this.state.id} color={this.state.background} />

                        </div>
                        <Footer load={this.state} />
                    </div>
                </div>
            </div>
        )
    }
}

export default Swiper;