import React from 'react';
import axios from 'axios';
import api from '../../utils/api';
import {notify} from "../../utils/notify";
import Icon from '../../components/Icons';

class Sitemap extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			languages: [],
			files: [],
			list: [],
			menu: [],
			fullmenu: [],
			footer: [],
			website: null,
			modaliststate: "add",
			modaledit: "header",
			menuHf: "h"
		}
	}

	deleteItem(params, from) {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		api.post((from == "header" ? (this.state.menuHf == "h" ? "deleteheader" : "deletefooter") : "deletefullmenu"), {
			id: params
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			this.onload()
			notify('success', 'Eliminado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	deletePage() {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		api.post("deletepage", {
			id: document.getElementById('modal-confirmation').dataset.id
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			this.onload()
			notify('success', 'Eliminado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	showedit(params, from) {
		const _this = this;

		_this.setState({
			modaledit: from,
			modaleditmenu: params.url == null || params.url == "" ? true : false
		}, () => {
			document.getElementById('modal-edit').dataset.id = params.id;

			if (document.getElementById('modal-edit-fullmenu-highlight')) {
				document.getElementById('modal-edit-fullmenu-highlight').checked = params.highlight == "1" ? true : false;
			}

			if (document.getElementById('modal-edit-menu-list')) {
				document.getElementById('modal-edit-menu-list').innerHTML = "";
			}

			_this.state.list.map(item => {
				if (item.url != null && item.url != "") {
					if (document.getElementById('modal-edit-menu-list')) {
						document.getElementById('modal-edit-menu-list').innerHTML += `<div class="list-group-item">
						<div class="row align-items-center">
						<div class="col-auto"><input type="checkbox" class="form-check-input" value="` + item.id + `" ` + (JSON.stringify(params.pages).indexOf(item.id) !== -1 ? 'checked' : '') + `/></div>
						<div class="col text-truncate">
							<a class="text-body d-block">` + item.title + `</a>
							<small class="d-block text-muted text-truncate mt-n1">` + (item.url.indexOf('.') !== -1 ? 'URL externo' : 'Página') + `</small>
						</div>
						</div>
						</div>`;
					}
				}
			})
		})
	}

	tipo(params) {
		switch (params) {
			case "1":
				document.getElementsByClassName('tipo_pagina')[0].classList.remove('d-none');
				document.getElementsByClassName('tipo_externo')[0].classList.add('d-none');
				break;
			case "2":
				document.getElementsByClassName('tipo_externo')[0].classList.remove('d-none');
				document.getElementsByClassName('tipo_pagina')[0].classList.add('d-none');
				break;
			case "3":
				document.getElementsByClassName('tipo_pagina')[0].classList.add('d-none');
				document.getElementsByClassName('tipo_externo')[0].classList.add('d-none');
		}
	}

	draggableTemplate(item, menu) {
		return <label className="form-selectgroup-item flex-fill" onMouseDown={(e) => this.setState({ menuHf: menu })}>
			<input type="checkbox" name="form-project-manager[]" defaultValue={item.id} className="form-selectgroup-input" defaultChecked={true} disabled={true} />
			<div className="form-selectgroup-label d-flex align-items-center p-3">
				<div className="col form-selectgroup-label-content d-flex align-items-center">
					<div className="font-weight-medium">
						{
							item.highlight == 1 ?
								<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-star me-2" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="orange" fill="orange" strokeLinecap="round" strokeLinejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
									<path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z"></path>
								</svg>
								:
								(null)
						}
						{
							item.url == null || item.url == "" ?
								<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-dots me-3" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
									<circle cx={5} cy={12} r={1}></circle>
									<circle cx={12} cy={12} r={1}></circle>
									<circle cx={19} cy={12} r={1}></circle>
								</svg>
								:
								<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-link me-3" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
									<path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"></path>
									<path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"></path>
								</svg>
						}
						{item.title}
						<ul className="mt-2 ms-3">
							{
								item.pages.map(page => {
									return <li className="text-muted">{page.title}</li>
								})
							}
						</ul>
					</div>
				</div>

				<div className="col-auto me-3">
					{
						item.hasOwnProperty('highlight') || item.url == null ?
							<a className="btn btn-icon me-2" data-bs-toggle="modal" data-bs-target="#modal-edit" onClick={() => this.showedit(item, (item.hasOwnProperty('highlight') ? "menu" : "header"))}>
								<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-edit" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
									<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
									<path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
									<path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
									<line x1={16} y1={5} x2={19} y2={8}></line>
								</svg>
							</a>
							:
							(null)
					}
					<a className="btn btn-danger btn-icon" onClick={() => this.deleteItem(item.id, (item.hasOwnProperty('highlight') ? "menu" : "header"))}>
						<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1={4} y1={7} x2={20} y2={7} /><line x1={10} y1={11} x2={10} y2={17} /><line x1={14} y1={11} x2={14} y2={17} /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
					</a>
				</div>
				<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon icon-infco icon-infco-grid-dots">
					<path stroke="none" d="M0 0h24v24H0z" fill="none" />
					<circle cx={5} cy={5} r={1} />
					<circle cx={12} cy={5} r={1} />
					<circle cx={5} cy={12} r={1} />
					<circle cx={12} cy={12} r={1} />
					<circle cx={5} cy={19} r={1} />
					<circle cx={12} cy={19} r={1} />
				</svg>
			</div>
		</label>
	}

	total(params) {
		var total = {
			p: 0,
			h: 0,
			d: 0
		}

		params.map(item => {
			if (item.url == null) {
				total.d += 1;
			} else {
				if (item.url.indexOf('.') === -1) {
					total.p += 1;
				} else {
					total.h += 1;
				}
			}
		})

		document.getElementById('total_sitemap').innerHTML = "<b>Total:</b> &nbsp;" + total.p + " páginas . " + total.h + " hiperligações . " + total.d + " dropdowns";
	}

	addPage() {
		const id = Math.floor(Math.random() * (1000000 - 100000 + 1)) + 100000;
		var apiurl = "";
		var res = {};

		var titleHl = {};
		for (var i = 0; i < this.state.languages.length; i++) {
			const element = this.state.languages[i];
			if(element.code != "pt") {
				titleHl[element.code] = document.getElementById('modal-add-list-title-'+element.code).value
			}
		}

		if (document.getElementById('modal-add-list-select') && this.state.modaliststate == "add") {
			apiurl = "add_page";

			if (document.getElementById('modal-add-list-select').value != "3") {
				res = {
					id: id,
					hl: "pt",
					title: document.getElementById('modal-add-list-title-pt').value,
					title_hl: JSON.stringify(titleHl),
					url: (document.getElementById('modal-add-list-select').value == "1" ? document.getElementById('modal-add-list-page').value : document.getElementById('modal-add-list-external').value)
				}
			} else {
				res = {
					id: id,
					hl: "pt",
					title: document.getElementById('modal-add-list-title-pt').value,
					title_hl: JSON.stringify(titleHl)
				}
			}
		} else {
			apiurl = "edit_page";

			if (document.getElementById('modal-add-list').dataset.edit) {
				res = {
					id: document.getElementById('modal-add-list').dataset.edit,
					hl: "pt",
					title: document.getElementById('modal-add-list-title-pt').value,
					title_hl: JSON.stringify(titleHl),
					url: document.getElementById('modal-add-list-url-edit').value
				}
			}
		}

		notify('loading', 'Aguarde um momento...', Infinity, false)
		api.post(apiurl, res, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			this.onload()
			notify('success', 'Atualizado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	addToHeader() {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		const id = document.getElementById('header_select').value;
		const apiurl = this.state.menuHf == "h" ? "addtoheader" : "addtofooter";

		api.post(apiurl, {
			id: id
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			this.onload()
			notify('success', 'Atualizado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	addToMenu() {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		const id = document.getElementById('menu_select').value;

		api.post("addtofullmenu", {
			id: id,
			highlight: "0"
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			this.onload()
			notify('success', 'Atualizado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	editar() {
		var apiurl = "";
		var res = {};

		if (document.querySelectorAll('#modal-edit .modal-title')[0].innerText == "Header") {
			apiurl = "editheader";

			var array = [];
			for (var i = 0; i < document.querySelectorAll('.form-check-input:checked').length; i++) {
				array.push(document.querySelectorAll('.form-check-input:checked')[i].value);
			}

			res = {
				id: document.getElementById('modal-edit').dataset.id,
				pages: JSON.stringify(array)
			}
		} else if (document.querySelectorAll('#modal-edit .modal-title')[0].innerText == "Footer") {
			apiurl = "editfooter";

			var array = [];
			for (var i = 0; i < document.querySelectorAll('.form-check-input:checked').length; i++) {
				array.push(document.querySelectorAll('.form-check-input:checked')[i].value);
			}

			res = {
				id: document.getElementById('modal-edit').dataset.id,
				pages: JSON.stringify(array)
			}
		} else {
			apiurl = "editfullmenu";

			if (document.getElementById('modal-edit-menu-list')) {
				var array = [];
				for (var i = 0; i < document.querySelectorAll('.form-check-input:checked').length; i++) {
					array.push(document.querySelectorAll('.form-check-input:checked')[i].value);
				}

				res = {
					id: document.getElementById('modal-edit').dataset.id,
					pages: JSON.stringify(array),
					highlight: "0"
				}
			} else {
				res = {
					id: document.getElementById('modal-edit').dataset.id,
					pages: "",
					highlight: document.getElementById('modal-edit-fullmenu-highlight').checked ? "1" : "0"
				}
			}
		}

		notify('loading', 'Aguarde um momento...', Infinity, false)
		api.post(apiurl, res, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			this.onload()
			notify('success', 'Atualizado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	prepareModal(modal, action, item) {
		let _this = this;

		if (action == "add") {
			for (var i = 0; i < document.querySelectorAll('#modal-add-list input').length; i++) {
				document.querySelectorAll('#modal-add-list input')[i].value = ""
			}
		}

		this.setState({
			modaliststate: action
		}, () => {
			if (item) {
				document.getElementById('modal-add-list').dataset.edit = item.id;

				if (document.getElementById('modal-add-list-title-pt')) {
					document.getElementById('modal-add-list-title-pt').value = item.title;
					for (var i = 0; i < _this.state.languages.length; i++) {
						const element = _this.state.languages[i];
						if(element.code != "pt") {
							document.getElementById('modal-add-list-title-'+element.code).value = item.title_hl && Object.keys(JSON.parse(item.title_hl)).length ? JSON.parse(item.title_hl)[element.code] : "";
						}
					}
				}
				if (document.getElementById('modal-add-list-url-edit')) {
					document.getElementById('modal-add-list-url-edit').value = item.url;
				}
			}
		})
	}

	dragdrop(params) {
		var array = [];

		if (this.state.menuHf == "h") {
			if (params == "header" && document.querySelectorAll('#sortable-1 input')[0]) {
				const item = document.querySelectorAll('#sortable-1 input');

				for (var i = 0; i < item.length; i++) {
					array.push(item[i].value)
				}
			}
		} else {
			if (params == "header" && document.querySelectorAll('#sortable-3 input')[0]) {
				const item = document.querySelectorAll('#sortable-3 input');

				for (var i = 0; i < item.length; i++) {
					array.push(item[i].value)
				}
			}
		}


		if (params == "fullmenu" && document.querySelectorAll('#sortable-2 input')[0]) {
			const item = document.querySelectorAll('#sortable-2 input');

			for (var i = 0; i < item.length; i++) {
				array.push(item[i].value)
			}
		}

		notify('loading', 'Aguarde um momento...', Infinity, false)
		api.post("editmenus", {
			menu: params == "header" ? (this.state.menuHf == "h" ? "header" : "footer") : "fullmenu",
			order: JSON.stringify(array)
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			notify('success', 'Atualizado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	onload() {
		let _this = this;

		document.title = "Gestor de sitemap • BackOffice";

		if (document.getElementsByClassName('page-pretitle')) {
			document.getElementsByClassName('page-pretitle')[0].innerHTML = "Painel > Páginas >";
		}

		if (document.getElementsByClassName('page-title-text')[0]) {
			document.getElementsByClassName('page-title-text')[0].innerHTML = "Gestor de sitemap";
		}


		this.setState({
			website: document.getElementById('website_redirect').href
		})

		api.post("sitemap", {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			_this.setState({
				list: response.data
			}, () => {
				this.total(response.data);
			});
		}, (error) => {
			alert('Erro');
		});

		this.loadFiles();

		api.post("links", {
			hl: "pt"
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			_this.setState({
				menu: response.data.menu,
				fullmenu: response.data.sitemap,
				footer: response.data.footer
			}, () => {
				window.layoutSortable('#sortable-1');
				window.layoutSortable('#sortable-2');
				window.layoutSortable('#sortable-3');
			});
		}, (error) => {
			alert('Erro');
		});
	}

	componentDidMount() {
		this.onload();

		const _this = this;
		api.post("translations", { t: "1" }, {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.setState({
				languages: response.data
			})
		}, (error) => {
			alert('Erro');
		});
	}

	copylink(params) {
		var copyText = document.getElementById('file-' + params + '-link');

		//copyText.setSelectionRange(0, 99999); For mobile device*/

		navigator.clipboard.writeText(copyText.href);
	}

	loadFiles() {
		const _this = this;
		axios.post('https://files.takiportugal.com/upload/files.php?f=1', {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
				"Content-Type": "multipart/form-data"
			}
		}).then((response) => {
			_this.setState({ files: response.data })
		}, (error) => {

		});
	}

	uploadFile(file) {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		const _this = this;
		var formData = new FormData();
		formData.append("file", file.files[0]);

		axios.post('https://files.takiportugal.com/upload/?t=all', formData, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
				"Content-Type": "multipart/form-data"
			}
		}).then((response) => {
			_this.loadFiles()
			notify('success', 'Atualizado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	render() {
		return (
			<>
				<div className="container-xl">
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<div className="page-pretitle"></div>
								<h2 className="page-title">
									<Icon svg="Gestorsitemap" me="1" />
									<span className="page-title-text"></span>
								</h2>
							</div>
						</div>
					</div>


					<div className="row mt-3">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">Lista de páginas &amp; outras ligações</h3>
									<a className="btn btn-primary ms-auto" data-bs-toggle="modal" data-bs-target="#modal-add-list" onClick={() => this.prepareModal("modal-add-list", "add", null)}>
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<line x1={12} y1={5} x2={12} y2={19}></line>
											<line x1={5} y1={12} x2={19} y2={12}></line>
										</svg>
										Adicionar
									</a>
								</div>
								<div className="table-responsive">
									<table className="table card-table table-vcenter text-nowrap datatable">
										<thead>
											<tr>
												<th>ID</th>
												<th>Título</th>
												<th>URL / Caminho</th>
												<th>Operações</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.list.map(item => {
													return <tr>
														<td className="w-1"><span className="text-muted">{item.id}</span></td>
														<td>
															<span className="badge bg-dark-lt me-1"><span className="flag flag-xs flag-country-pt me-1" />{item.title}</span>
															{
																item.title_hl && Object.keys(JSON.parse(item.title_hl)).length &&
																	this.state.languages.map(element => {
																		if(element.code != "pt") {
																			const obj = JSON.parse(item.title_hl);
																			
																			if (obj.hasOwnProperty(element.code)) {
																				return <span className="badge bg-dark-lt me-1">
																					<span className={`flag flag-xs flag-country-${element.flag.toLowerCase()} me-1`} />
																					{obj[element.code]}
																				</span>
																			}
																		}
																	})
															}
														</td>
														<td>
															{
																item.url == null || item.url == "" ?
																	<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-dots" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																		<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																		<circle cx={5} cy={12} r={1}></circle>
																		<circle cx={12} cy={12} r={1}></circle>
																		<circle cx={19} cy={12} r={1}></circle>
																	</svg>
																	:
																	<a href={item.url.indexOf('.') !== -1 ? item.url : document.getElementById('website_redirect').href + (item.url != '/' ? item.url : '')} className="text-reset" target="_blank">
																		<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-link me-1" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																			<path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5"></path>
																			<path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5"></path>
																		</svg>
																		{item.url}
																	</a>
															}
														</td>
														<td>
															{
																item.id == "848438" || item.id == "192985" || item.id == "668200" || item.id == "806556" || item.id == "120644" || item.id == "543029" ?
																	<a className="btn me-3" data-bs-toggle="modal" data-bs-target="#modal-add-list" onClick={() => this.prepareModal("modal-add-list", "edit", item)}>
																		<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-edit" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																			<path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
																			<path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
																			<line x1={16} y1={5} x2={19} y2={8}></line>
																		</svg>
																		Editar
																	</a>
																	:
																	<>
																		<a className="btn me-3" data-bs-toggle="modal" data-bs-target="#modal-add-list" onClick={() => this.prepareModal("modal-add-list", "edit", item)}>
																			<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-edit" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																				<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																				<path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
																				<path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
																				<line x1={16} y1={5} x2={19} y2={8}></line>
																			</svg>
																			Editar
																		</a>
																		<a className="btn btn-danger" data-bs-toggle="modal" data-bs-target="#modal-confirmation" onClick={() => document.getElementById('modal-confirmation').dataset.id = item.id}>
																			<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><line x1={4} y1={7} x2={20} y2={7} /><line x1={10} y1={11} x2={10} y2={17} /><line x1={14} y1={11} x2={14} y2={17} /><path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" /><path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" /></svg>
																			Eliminar
																		</a>
																	</>
															}
														</td>
													</tr>
												})
											}
										</tbody>
									</table>
								</div>
								<div className="card-footer d-flex align-items-center">
									<p className="m-0 text-muted" id="total_sitemap" />
								</div>
							</div>
						</div>
					</div>

					<div className="row mt-3">
						<div className="col-12">
							<div className="card">
								<div className="card-header">
									<h3 className="card-title">Lista de ficheiros</h3>
									<a className="btn btn-white ms-auto" onClick={() => document.getElementById('upload-file-btn').click()} >
										<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<path d="M7 18a4.6 4.4 0 0 1 0 -9a5 4.5 0 0 1 11 2h1a3.5 3.5 0 0 1 0 7h-1"></path>
											<polyline points="9 15 12 12 15 15"></polyline>
											<line x1={12} y1={12} x2={12} y2={21}></line>
										</svg>
										Carregar ficheiro
									</a>
									<input type="file" id="upload-file-btn" class="form-control d-none" onChange={e => this.uploadFile(e.target)} />
								</div>
								<div className="table-responsive">
									<table className="table card-table table-vcenter text-nowrap datatable">
										<thead>
											<tr>
												<th className="w-1">URL</th>
												<th className="w-1"></th>
												<th>Tipo</th>
												<th>Tamanho</th>
												<th>Data do carregamento</th>
											</tr>
										</thead>
										<tbody>
											{
												this.state.files.map((item, i) => {
													return <tr>
														<td><a id={`file-${i}-link`} href={`https://files.takiportugal.com/public/${item.url}`} target="_blank">{item.url}</a></td>
														<td>
															<a className="btn btn-white me-3" onClick={() => this.copylink(i)}>
																<svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
																	<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
																	<path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2"></path>
																	<rect x={9} y={3} width={6} height={4} rx={2}></rect>
																</svg>
																Copiar
															</a>
														</td>
														<td>{item.type}</td>
														<td>{item.size}</td>
														<td class="text-muted">{item.created_at}</td>
													</tr>
												})
											}
										</tbody>
									</table>
								</div>
								<div className="card-footer d-flex align-items-center">
									<p className="m-0 text-muted" id="total_sitemap" />
								</div>
							</div>
						</div>
					</div>

					<div className="row">
						<div className="col-md-6">
							<div className="card mt-4">
								<div className="card-header">
									<h3 className="card-title">
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-layout-navbar me-1" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<rect x={4} y={4} width={16} height={16} rx={2}></rect>
											<line x1={4} y1={9} x2={20} y2={9}></line>
										</svg>
										Header
									</h3>
									<a onClick={() => this.setState({ menuHf: 'h' })} data-bs-toggle="modal" data-bs-target="#modal-add-menu" className="btn btn-primary ms-auto">
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<line x1={12} y1={5} x2={12} y2={19}></line>
											<line x1={5} y1={12} x2={19} y2={12}></line>
										</svg>
										Adicionar
									</a>
								</div>
								<div className="card-body">
									<div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
										<ul id="sortable-1" class="sortable-container">
											<a className="request_save d-none" onClick={() => this.dragdrop('header')} />
											{
												this.state.menu.map(item => {
													return this.draggableTemplate(item, 'h');
												})
											}
										</ul>
									</div>
								</div>
							</div>
							<div className="card mt-4">
								<div className="card-header">
									<h3 className="card-title">
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-layout-navbar me-1" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<rect x={4} y={4} width={16} height={16} rx={2}></rect>
											<line x1={4} y1={9} x2={20} y2={9}></line>
										</svg>
										Footer
									</h3>
									<a onClick={() => this.setState({ menuHf: "f" })} data-bs-toggle="modal" data-bs-target="#modal-add-menu" className="btn btn-primary ms-auto">
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<line x1={12} y1={5} x2={12} y2={19}></line>
											<line x1={5} y1={12} x2={19} y2={12}></line>
										</svg>
										Adicionar
									</a>
								</div>
								<div className="card-body">
									<div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
										<ul id="sortable-3" class="sortable-container">
											<a className="request_save d-none" onClick={() => this.dragdrop('header')} />
											{
												this.state.footer.map(item => {
													return this.draggableTemplate(item, "f");
												})
											}
										</ul>
									</div>
								</div>
							</div>
						</div>
						<div className="d-lg-block col-md-6">
							<div className="card mt-4">
								<div className="card-header">
									<h3 className="card-title">
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-menu-2 me-1" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<line x1={4} y1={6} x2={20} y2={6}></line>
											<line x1={4} y1={12} x2={20} y2={12}></line>
											<line x1={4} y1={18} x2={20} y2={18}></line>
										</svg>
										Menu expandido
									</h3>
									<a data-bs-toggle="modal" data-bs-target="#modal-add-fullmenu" className="btn btn-primary ms-auto">
										<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<line x1={12} y1={5} x2={12} y2={19}></line>
											<line x1={5} y1={12} x2={19} y2={12}></line>
										</svg>
										Adicionar
									</a>
								</div>
								<div className="card-body">
									<div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
										<ul id="sortable-2" class="sortable-container">
											<a className="request_save d-none" onClick={() => this.dragdrop('fullmenu')} />
											{
												this.state.fullmenu.map(item => {
													return this.draggableTemplate(item, "m");
												})
											}
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-add-list" tabIndex={-1} role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Lista de páginas &amp; outras ligações</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								{
									this.state.modaliststate == "add" &&
									<div className="row mb-3 align-items-end">
										<div className="col">
											<label className="form-label">Tipo</label>
											<div class="form-floating">
												<select class="form-select" id="modal-add-list-select" onChange={e => this.tipo(e.target.value)}>
													<option value="1">Página</option>
													<option value="2">URL externo</option>
													<option value="3">Menu</option>
												</select>
												<label for="modal-add-list-select">Selecione o tipo de ligação</label>
											</div>
										</div>
									</div>
								}
								<div className="row mb-2 align-items-end">
									<div className="col">
										<label className="form-label mb-0">Título</label>
										<small className="text-muted">Adicione o título nos seguintes idiomas:</small>
									</div>
								</div>

								{
									this.state.languages.map(item => {
										return <div className="mb-3">
											<div className="input-group input-group-flat">
												<span className="input-group-text pe-2"><span className={`flag flag-xs flag-country-${item.flag.toLowerCase()}`} /></span>
												<input type="text" autoComplete="off" className="form-control ps-2 modal-add-list-title" id={`modal-add-list-title-${item.code}`} />
											</div>
										</div>
									})
								}



								{
									this.state.modaliststate == "add" &&
									<>
										<div className="row mb-3 align-items-end tipo_pagina">
											<div className="col">
												<label className="form-label">URL da página</label>
												<div className="input-group input-group-flat">
													<span className="input-group-text">{this.state.website}</span>
													<input type="text" className="form-control ps-0" id="modal-add-list-page" defaultValue="" autoComplete="off" placeholder="exemplo-de-url" />
												</div>
											</div>
										</div>
										<div className="row mb-3 align-items-end tipo_externo d-none">
											<div className="col">
												<label className="form-label">URL externo</label>
												<input type="text" className="form-control" id="modal-add-list-external" />
											</div>
										</div>
									</>
								}
								{
									this.state.modaliststate != "add" &&
									<div className="row mb-3 align-items-end">
										<div className="col">
											<label className="form-label">URL</label>
											<input type="text" className="form-control" id="modal-add-list-url-edit" />
										</div>
									</div>
								}

							</div>
							<div className="modal-footer">
								<button type="button" className="btn me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.addPage()}>
									{
										this.state.modaliststate == "add" ?
											<>
												<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<line x1={12} y1={5} x2={12} y2={19}></line>
													<line x1={5} y1={12} x2={19} y2={12}></line>
												</svg>
												Adicionar
											</>
											:
											<>
												<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-edit" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
													<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
													<path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
													<path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
													<line x1={16} y1={5} x2={19} y2={8}></line>
												</svg>
												Editar
											</>
									}
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-add-menu" tabIndex={-1} role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{this.state.menuHf == "h" ? "Header" : "Footer"}</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								<div className="row mb-3 align-items-end">
									<div className="col">
										<label className="form-label">Ligação</label>
										<div class="form-floating">
											<select class="form-select" id="header_select">
												{
													this.state.list.map(item => {
														return <option data-type={item.url == null || item.url == "" ? "3" : item.url.indexOf('.') !== -1 ? "2" : "1"} value={item.id}>
															{item.title}
															{item.url == null || item.url == "" ? " (Menu)" : item.url.indexOf('.') !== -1 ? " (URL externo)" : " (Página)"}
														</option>
													})
												}
											</select>
											<label for="header_select">Selecione a ligação que pretende adicionar</label>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.addToHeader()}>
									<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<line x1={12} y1={5} x2={12} y2={19}></line>
										<line x1={5} y1={12} x2={19} y2={12}></line>
									</svg>
									Adicionar
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-add-fullmenu" tabIndex={-1} role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Menu expandido</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								<div className="row mb-3 align-items-end">
									<div className="col">
										<label className="form-label">Ligação</label>
										<div class="form-floating">
											<select class="form-select" id="menu_select">
												{
													this.state.list.map(item => {
														return <option data-type={item.url == null || item.url == "" ? "3" : item.url.indexOf('.') !== -1 ? "2" : "1"} value={item.id}>
															{item.title}
															{item.url == null || item.url == "" ? " (Menu)" : item.url.indexOf('.') !== -1 ? " (URL externo)" : " (Página)"}
														</option>
													})
												}
											</select>
											<label for="menu_select">Selecione a ligação que pretende adicionar</label>
										</div>
									</div>
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.addToMenu()}>
									<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-plus" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<line x1={12} y1={5} x2={12} y2={19}></line>
										<line x1={5} y1={12} x2={19} y2={12}></line>
									</svg>
									Adicionar
								</button>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-confirmation" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-sm modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-status bg-danger"></div>
							<div className="modal-body text-center py-4">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
								<svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-danger icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
								<div className="modal-title">Tem a certeza?</div>
								<div>Esta ação é irreversível! Pretende continuar?</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn bg-white me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => this.deletePage()}>Eliminar</button>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-edit" tabIndex={-1} role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">{this.state.modaledit == "header" ? (this.state.menuHf == "h" ? "Header" : "Footer") : "Menu expansivo"}</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								{
									this.state.modaleditmenu &&
									<div className="row mb-3 align-items-end">
										<label className="form-label">Menu</label>

										<div id="modal-edit-menu-list" className="list-group list-group-flush" style={{ overflow: "auto", height: "200px" }}></div>
									</div>
								}

								{
									this.state.modaledit == "menu" && !this.state.modaleditmenu ?
										<div className="row mb-3 align-items-end">
											<div className="col">
												<label className="form-check form-switch">
													<input className="form-check-input" type="checkbox" id="modal-edit-fullmenu-highlight" />
													<span className="form-check-label">Em destaque</span>
												</label>
											</div>
										</div>
										:
										(null)
								}

							</div>
							<div className="modal-footer">
								<button type="button" className="btn me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={() => this.editar()}>
									<svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-edit" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
										<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
										<path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3"></path>
										<path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3"></path>
										<line x1={16} y1={5} x2={19} y2={8}></line>
									</svg>
									Editar
								</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default Sitemap;