import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation } from "react-router-dom";

import api from "../../utils/api";

import Icon from '../../components/Icons';

class Notifications extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			results: [],
			count: {
				cards: 0,
				orders: 0,
				users: 0,
				reports: 0,
				pages: 0,
				stocks: 0,
				all: 0
			},
			category: 'all',
			timer: 0
		}
	}

	componentDidMount() {
		this.load()
	}

	load() {
		var timer = performance.now();
		var res = [];

		var _this = this;
		api.post("bo_search", {
			q: this.props.id
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			res = response.data.search;

			window.initSearch(response.data.pages);

			var filtered = window.updateBookCountAndTable(_this.props.id);
			for (var i = 0; i < filtered.length; i++) {
				res.push(filtered[i])
			}

			_this.setState({ results: res, count: { cards: response.data.search_count.cards, users: response.data.search_count.users, orders: response.data.search_count.orders, reports: response.data.search_count.reports, stocks: response.data.search_count.stocks, pages: filtered.length, all: res.length }, timer: String(((performance.now() - timer) / 1000).toFixed(2)) + ' segundos' })
		}, (error) => {
			alert('Erro');
		});
	}

	results() {
		var results = [];

		if (this.state.category != "all") {
			this.state.results.map(item => {
				if (this.state.category != "pages") {
					if (item.hasOwnProperty('search_category')) {
						if (item.search_category == this.state.category) {
							results.push(item)
						}
					}
				} else {
					if (!item.hasOwnProperty('search_category')) {
						results.push(item)
					}
				}
			})
		} else {
			results = this.state.results;
		}

		return results.map(item => {
			var color = null;
			var icon = null;
			var body = null;

			switch (item.search_category) {
				case "cards":
					color = 'bg-yellow';
					icon = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-bookmarks" width={24} height={24} viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
						<path d="M13 7a2 2 0 0 1 2 2v12l-5 -3l-5 3v-12a2 2 0 0 1 2 -2h6z"></path>
						<path d="M9.265 4a2 2 0 0 1 1.735 -1h6a2 2 0 0 1 2 2v12l-1 -.6"></path>
					</svg>;
					body = <div className="col">
						{
							item.type == 0 ?
								<div className="font-weight-medium">💳 Cartão ID: {item.card_id}</div>
								:
								<div className="font-weight-medium">🏷️ Cartão ID: {item.card_id}</div>
						}
						<span className={`badge bg-dark-lt mt-1 me-1`}>{item.belonging}</span>
						<span className={`badge ${item.status == '1' ? 'bg-green-lt' : 'bg-red-lt'} mt-1`}>{item.status == '1' ? 'Na posse do dono' : 'Perdido'}</span>
						<div className="mt-1">
							<img src={`https://images.takiportugal.com/public/${item.image}`} style={{ height: "70px" }} />
						</div>
					</div>;
					break;
				case "users":
					color = 'bg-dark';
					icon = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-users" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
						<circle cx={9} cy={7} r={4}></circle>
						<path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
						<path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
						<path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
					</svg>;
					body = <div className="col">
						<div className="font-weight-medium">Utilizador ID: {item.user}</div>
						<span className={`badge ${item.influencer ? 'bg-yellow-lt' : 'bg-green-lt'} mt-1 me-1`}>{item.influencer ? 'Influencer' : 'Cliente'}</span>
						<div className="mt-1">
							<dl className="row">
								<dt className="col-5 mb-2">Nome e apelido:</dt>
								<dd className="col-7">{item.fullname}</dd>
								<dt className="col-5 mb-2">Contacto:</dt>
								<dd className="col-7"><strong><a href={`tel:${item.contact}`}>{item.contact}</a></strong></dd>
								<dt className="col-5 mb-2">Sexo:</dt>
								<dd className="col-7">{item.gender == 'm' ? 'Masculino' : 'Feminino'}</dd>
								<dt className="col-5 mb-2">NIF:</dt>
								<dd className="col-7">{item.nif}</dd>
								<dt className="col-5 mb-2">Morada:</dt>
								<dd className="col-7">{item.address}</dd>
								<dt className="col-5 mb-2">Morada 2:</dt>
								<dd className="col-7"> {item.address_2} </dd>
								<dt className="col-5 mb-2">Localidade:</dt>
								<dd className="col-7">{item.locality}</dd>
								<dt className="col-5 mb-2">País:</dt>
								<dd className="col-7"><span className={`flag flag-xs flag-country-${item.country}`} /></dd>
								<dt className="col-5 mb-2">Código-postal:</dt>
								<dd className="col-7">{item.zip}</dd>
							</dl>
						</div>
					</div>;
					break;
				case "reports":
					color = 'bg-red';
					icon = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-message-report" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
						<path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4"></path>
						<line x1={12} y1={8} x2={12} y2={11}></line>
						<line x1={12} y1={14} x2={12} y2="14.01"></line>
					</svg>;
					body = <div className="col">
						<div className="font-weight-medium">Processo ID: {item.process_id}</div>
						<span className="badge bg-yellow mt-1">{`Cartão ID: ${item.card_id}`}</span>
						<div className="mt-1">
							<dl className="row">
								<dt className="col-5 mb-2">Iniciado por:</dt>
								<dd className="col-7">{item.found_by_name}</dd>
								<dt className="col-5 mb-2">Contacto:</dt>
								<dd className="col-7"><strong><a href={`tel:${item.found_by_contact}`}>{item.found_by_contact}</a></strong></dd>
								<dt className="col-5 mb-2">Local:</dt>
								<dd className="col-7">{item.found_at}</dd>
								<dt className="col-5 mb-2">Data início:</dt>
								<dd className="col-7">{item.created_at}</dd>
								<dt className="col-5 mb-2">Data conclusão:</dt>
								<dd className="col-7">{item.finished_at}</dd>
							</dl>
						</div>
					</div>;
					break;
				case "orders":
					color = 'bg-blue';
					icon = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-mail-forward" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
						<path d="M12 18h-7a2 2 0 0 1 -2 -2v-10a2 2 0 0 1 2 -2h14a2 2 0 0 1 2 2v7.5"></path>
						<path d="M3 6l9 6l9 -6"></path>
						<path d="M15 18h6"></path>
						<path d="M18 15l3 3l-3 3"></path>
					</svg>;
					body = <div className="col">
						<div className="font-weight-medium">Encomenda ID: {item.order_id}</div>
						<span className="badge bg-dark mt-1">{`Cliente ID: ${item.client}`}</span>
						<div className="mt-1">
							<dl className="row">
								<dt className="col-5 mb-2">Produtos</dt>
								<dd className="col-7">{item.cart_md}</dd>
								<dt className="col-5 mb-2">Em nome de:</dt>
								<dd className="col-7">{item.name}</dd>
								<dt className="col-5 mb-2">Contacto:</dt>
								<dd className="col-7"><strong><a href={`tel:${item.contact}`}>{item.contact}</a></strong></dd>
								<dt className="col-5 mb-2">Email:</dt>
								<dd className="col-7">{item.email}</dd>
								<dt className="col-5 mb-2">NIF:</dt>
								<dd className="col-7">{item.nif}</dd>
							</dl>
						</div>
					</div>;
					break;
					case "stocks":
						color = 'bg-pink';
						icon = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-package" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
							<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
							<polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
							<line x1={12} y1={12} x2={20} y2="7.5"></line>
							<line x1={12} y1={12} x2={12} y2={21}></line>
							<line x1={12} y1={12} x2={4} y2="7.5"></line>
							<line x1={16} y1="5.25" x2={8} y2="9.75"></line>
						</svg>;
						body = <div className="col">
							<div className="font-weight-medium">Cartão ID: {item.card_id}</div>
							<span className="badge bg-dark mt-1">{item.card_type == "1" ? 'Porta-chaves' : 'Standard'}</span>
						</div>;
						break;
				default:
					color = 'bg-green';
					icon = <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-news" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
						<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
						<path d="M16 6h3a1 1 0 0 1 1 1v11a2 2 0 0 1 -4 0v-13a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1v12a3 3 0 0 0 3 3h11"></path>
						<line x1={8} y1={8} x2={12} y2={8}></line>
						<line x1={8} y1={12} x2={12} y2={12}></line>
						<line x1={8} y1={16} x2={12} y2={16}></line>
					</svg>;
					body = <div className="col">
						<div className="font-weight-medium">Página ID: {item.hasOwnProperty('url') ? item.id : item.search_page_id}</div>
						<span className="badge bg-green mt-1">{item.hasOwnProperty('url') ? item.title : item.search_page_title}</span>
						{
							item.hasOwnProperty('url') ?
								<div className="mt-1">
									<span className="badge bg-blue-lt text-lowercase">
										<svg xmlns="http://www.w3.org/2000/svg" className="me-2 icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" /><path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" /></svg>
										{item.url == "/" ? "/" : "/" + item.url}
									</span>
								</div>
								:
								<div className="mt-1">
									<span className="badge bg-lime-lt">
										{
											{
												info: "Informação",
												swiper: "Swiper",
												grid: "Grelha",
												accordion: "Acordeão",
												banners: "Banner"
											}[item.block_type]
										} ID: {item.id}
									</span>
									<div className="card-body p-0 mt-2">
										Contém o termo "<b>{this.props.id}</b>"
									</div>
								</div>
						}
					</div>;
			}

			return <div className="col-sm-6 col-lg-4 mb-3">
				<div className="card card-sm">
					<div className="card-body">
						<div className="row align-items-center">
							<div className="col-auto">
								<span className={`${color} text-white avatar`}>{icon}</span>
							</div>
							{body}
						</div>
					</div>
				</div>
			</div>
		})
	}

	render() {
		return (
			<>
				<div className="container-xl">
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<div className="page-pretitle">{"Painel > Pesquisa"}</div>
								<h2 className="page-title">
									<Icon svg={"Search"} me="1" />
									<span className="page-title-text">Resultados de "{this.props.id}"</span>
								</h2>
							</div>
							<div className="col-auto">
								<div className="text-muted mt-1">{this.state.results.length + (this.state.results.length == 1 ? ' resultado' : ' resultados')} {this.state.results.length > 0 && `(${this.state.timer})`}</div>
							</div>
						</div>
					</div>
				</div>
				<div className="page-body">
					<div className="container-xl mt-2">
						<div className="row gx-lg-4">
							<div className="d-lg-block col-md-3">
								<form action method="get">
									<div className="subheader mb-2">Categorias</div>
									<div className="list-group list-group-transparent mb-4">
										<a onClick={() => this.setState({ category: 'all' })} className={`list-group-item list-group-item-action d-flex align-items-center cursor-pointer ${this.state.category == 'all' ? 'active' : ''}`}>
											Todas
											<small className="text-muted ms-auto">{this.state.count.all}</small>
										</a>
										<a onClick={() => this.setState({ category: 'cards' })} className={`list-group-item list-group-item-action d-flex align-items-center cursor-pointer ${this.state.category == 'cards' ? 'active' : ''}`}>
											<div className="badge bg-yellow me-2" />
											Cartões
											<small className="text-muted ms-auto">{this.state.count.cards}</small>
										</a>
										<a onClick={() => this.setState({ category: 'users' })} className={`list-group-item list-group-item-action d-flex align-items-center cursor-pointer ${this.state.category == 'users' ? 'active' : ''}`}>
											<div className="badge bg-dark me-2" />
											Utilizadores
											<small className="text-muted ms-auto">{this.state.count.users}</small>
										</a>
										<a onClick={() => this.setState({ category: 'reports' })} className={`list-group-item list-group-item-action d-flex align-items-center cursor-pointer ${this.state.category == 'reports' ? 'active' : ''}`}>
											<div className="badge bg-red me-2" />
											Reportes
											<small className="text-muted ms-auto">{this.state.count.reports}</small>
										</a>
										<a onClick={() => this.setState({ category: 'orders' })} className={`list-group-item list-group-item-action d-flex align-items-center cursor-pointer ${this.state.category == 'orders' ? 'active' : ''}`}>
											<div className="badge bg-blue me-2" />
											Encomendas
											<small className="text-muted ms-auto">{this.state.count.orders}</small>
										</a>
										<a onClick={() => this.setState({ category: 'stocks' })} className={`list-group-item list-group-item-action d-flex align-items-center cursor-pointer ${this.state.category == 'stocks' ? 'active' : ''}`}>
											<div className="badge bg-pink me-2" />
											Stocks
											<small className="text-muted ms-auto">{this.state.count.stocks}</small>
										</a>
										<a onClick={() => this.setState({ category: 'pages' })} className={`list-group-item list-group-item-action d-flex align-items-center cursor-pointer ${this.state.category == 'pages' ? 'active' : ''}`}>
											<div className="badge bg-success me-2" />
											Páginas
											<small className="text-muted ms-auto">{this.state.count.pages}</small>
										</a>
									</div>
								</form>
							</div>
							<div className="col-md-9">
								<div className="row">{this.results()}</div>
							</div>
						</div>
					</div>
				</div>

			</>
		);
	}
}

export default Notifications;