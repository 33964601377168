import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import api from '../../utils/api';


import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Painel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            total: null,
            tem: 1
        }
    }

    onload() {
        document.title = "Painel • BackOffice";
    }

    componentDidMount() {
        const _this = this;
        _this.onload();

        api.post("total", {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then((response) => {
            _this.setState({
                total: response.data
            })
        }, (error) => {

        });
    }
    componentDidUpdate() {
        this.onload();
    }

    render() {
        return (
            this.state.total &&
            <>
                <div className="col-sm-4">
                    <div className="card card-sm">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <span className="bg-black text-white avatar">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <circle cx={9} cy={7} r={4}></circle>
                                            <path d="M3 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"></path>
                                            <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                                            <path d="M21 21v-2a4 4 0 0 0 -3 -3.85"></path>
                                        </svg>
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="subheader">Total de clientes</div>
                                    <div className="font-weight-medium">
                                        {this.state.total.clients.total}
                                        {
                                            this.state.total.clients.new ?
                                                <span className="float-right font-weight-medium text-green ms-1">{'+' + this.state.total.clients.new + ' hoje'}</span>
                                                :
                                                (null)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card card-sm">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <span className="bg-yellow text-white avatar">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M7.859 6h-2.834a2.025 2.025 0 0 0 -2.025 2.025v2.834c0 .537 .213 1.052 .593 1.432l6.116 6.116a2.025 2.025 0 0 0 2.864 0l2.834 -2.834a2.025 2.025 0 0 0 0 -2.864l-6.117 -6.116a2.025 2.025 0 0 0 -1.431 -.593z"></path>
                                            <path d="M17.573 18.407l2.834 -2.834a2.025 2.025 0 0 0 0 -2.864l-7.117 -7.116"></path>
                                            <path d="M6 9h-.01"></path>
                                        </svg>
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="subheader">Total de cartões</div>
                                    <div className="font-weight-medium">
                                        {this.state.total.cards.total}
                                        {
                                            this.state.total.cards.new ?
                                                <span className="float-right font-weight-medium text-yellow ms-1">{'+' + this.state.total.cards.new + ' hoje'}</span>
                                                :
                                                (null)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card card-sm">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <span className="bg-red text-white avatar">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M4 21v-13a3 3 0 0 1 3 -3h10a3 3 0 0 1 3 3v6a3 3 0 0 1 -3 3h-9l-4 4"></path>
                                            <line x1={12} y1={8} x2={12} y2={11}></line>
                                            <line x1={12} y1={14} x2={12} y2="14.01"></line>
                                        </svg>
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="subheader">Total de reportes</div>
                                    <div className="font-weight-medium">
                                        {this.state.total.reports.total}
                                        {
                                            this.state.total.reports.new ?
                                                <span className="float-right font-weight-medium text-blue ms-1">{'+' + this.state.total.reports.new + ' hoje'}</span>
                                                :
                                                (null)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card card-sm">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <span className="bg-green text-white avatar">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M7 11v8a1 1 0 0 1 -1 1h-2a1 1 0 0 1 -1 -1v-7a1 1 0 0 1 1 -1h3a4 4 0 0 0 4 -4v-1a2 2 0 0 1 4 0v5h3a2 2 0 0 1 2 2l-1 5a2 3 0 0 1 -2 2h-7a3 3 0 0 1 -3 -3"></path>
                                        </svg>
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="subheader">Feedback</div>
                                    <div className="font-weight-medium">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" fill={this.state.total.feedback.total >= 1 ? "orange" : "#dddddd"}>
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" fill={this.state.total.feedback.total >= 2 ? "orange" : "#dddddd"}>
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" fill={this.state.total.feedback.total >= 3 ? "orange" : "#dddddd"}>
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" fill={this.state.total.feedback.total >= 4 ? "orange" : "#dddddd"}>
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                        </svg>
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" fill={this.state.total.feedback.total == 5 ? "orange" : "#dddddd"}>
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                            <path d="M12 17.75l-6.172 3.245l1.179 -6.873l-5 -4.867l6.9 -1l3.086 -6.253l3.086 6.253l6.9 1l-5 4.867l1.179 6.873z" />
                                        </svg>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card card-sm">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <span className="bg-pink text-white avatar">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-device-mobile-message" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <path d="M11 3h10v8h-3l-4 2v-2h-3z"></path>
                                            <path d="M15 16v4a1 1 0 0 1 -1 1h-8a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1h2"></path>
                                            <path d="M10 18v.01"></path>
                                        </svg>
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="subheader">Saldo SMS's</div>
                                    <div className="font-weight-medium">
                                        {this.state.total.sms.balance}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-sm-4">
                    <div className="card card-sm">
                        <div className="card-body">
                            <div className="row align-items-center">
                                <div className="col-auto">
                                    <span className="bg-blue text-white avatar">
                                        <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-package" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                            <polyline points="12 3 20 7.5 20 16.5 12 21 4 16.5 4 7.5 12 3"></polyline>
                                            <line x1={12} y1={12} x2={20} y2="7.5"></line>
                                            <line x1={12} y1={12} x2={12} y2={21}></line>
                                            <line x1={12} y1={12} x2={4} y2="7.5"></line>
                                            <line x1={16} y1="5.25" x2={8} y2="9.75"></line>
                                        </svg>
                                    </span>
                                </div>
                                <div className="col">
                                    <div className="subheader">Stocks</div>
                                    <div className="font-weight-medium">
                                        {this.state.total.stocks.k} 🏷️ | {this.state.total.stocks.s} 💳
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>

        );
    }
}

export default Painel;