import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import api from '../../utils/api';


import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Painel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            ranking: [],
            sales: 0
        }
    }

    onload() {
        document.title = "Painel • BackOffice";
    }

    componentDidMount() {
        const _this = this;
        _this.onload();

        api.post("rankinginfluencers", {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then((response) => {
            _this.setState({
                ranking: response.data.ranking,
                sales: response.data.sales
            })
        }, (error) => {
        });
    }
    componentDidUpdate() {
        this.onload();
    }

	pct(value) {
		var valor = Number(value) / this.state.sales;
		var pct = valor * 100;

		return Math.round(pct);
	}

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="d-flex align-items-center w-full">
                        <div className="col">
                            <h3 className="card-title">Ranking dos afiliados</h3>
                        </div>
                        <div title="Vendas geradas por afiliados" className="col-auto">
                            <span className="badge bg-green ms-2">Vendas este mês: €{this.state.sales}</span>
                        </div>
                    </div>
                </div>
                <table className="table card-table table-vcenter">
                    <thead>
                        <tr>
                            <th colSpan={2}>Afiliado</th>
                            <th>Vendas</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            this.state.ranking.map(item => {
                                return <tr>
                                    <td className="w-1">
                                        <span className="avatar avatar-sm" style={{ backgroundImage: 'url("'+(item.avatar?'https://images.takiportugal.com/public/'+item.avatar : require('../../images/avatar.png').default)+'")' }} />
                                    </td>
                                    <td className="w-70">
                                        <span>{item.fullname}</span>
                                        <br />
                                        <small className="subheader">C. promocional: {item.influencer}</small>
                                    </td>
                                    <td className="w-30">
                                        <span className={`subheader text-${item.influencer_sales=="0"?"red":"green"} text-lowercase`}>+{Number(item.influencer_sales).toFixed(2)}€</span>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>
        );
    }
}

export default Painel;