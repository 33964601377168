import React from 'react';
import { withRouter, Switch, Route, Redirect, useLocation, Link } from "react-router-dom";
import api from '../../utils/api';


import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Painel extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            pages: []
        }
    }

    onload() {
        document.title = "Painel • BackOffice";
    }

    componentDidMount() {
		const _this = this;
		_this.onload();

		api.post("rankingpages", {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.setState({
				pages: response.data
			})
		}, (error) => {
			alert('Erro');
		});
    }
    componentDidUpdate() {
        this.onload();
    }

    render() {
        return (
            <div className="card">
                <div className="card-header">
                    <div className="d-flex align-items-center w-full">
                        <div className="col">
                            <h3 className="card-title">Páginas mais visitadas</h3>
                        </div>
                        <div className="col-auto">
                            <Link to="/workplace/sitemap" className="btn btn-outline-white">
                                <Icon svg="Gestorsitemap" me="2" />
                                Gestor de páginas
                            </Link>
                        </div>
                    </div>
                </div>
                <div className="card-table table-responsive">
                    <table className="table table-vcenter">
                        <thead>
                            <tr>
                                <th>Página</th>
                                <th>URL</th>
                                <th>Visitas</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.state.pages.map((item, i) => {
                                    return <tr>
                                        <td>{item.title}</td>
                                        <td>
                                            <a href={document.getElementById('website_redirect').href + (item.url != '/' ? item.url : '')}>
                                                <svg xmlns="http://www.w3.org/2000/svg" className="me-2 icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                                                    <path d="M10 14a3.5 3.5 0 0 0 5 0l4 -4a3.5 3.5 0 0 0 -5 -5l-.5 .5" />
                                                    <path d="M14 10a3.5 3.5 0 0 0 -5 0l-4 4a3.5 3.5 0 0 0 5 5l.5 -.5" />
                                                </svg>
                                                <span className="text-dark">{item.url == '/' ? 'Página inicial' : '/' + item.url}</span>
                                            </a>
                                        </td>
                                        <td className="text-muted">{item.visitors}</td>
                                    </tr>
                                })
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default Painel;