import React from 'react';
import { Link, useLocation } from "react-router-dom";

function Products(props) {
  function deleteBlock() {
    var obj = {
      id: props.load.id,
      block: props.load.block,
      page: window.location.pathname.split('/')[3]
    }

    document.getElementById('modal-confirmation').dataset.block = JSON.stringify(obj);
  }

  return (<>
    <div className="card mt-4">
      <div className="card-header">
        <h3 className="card-title">
          <svg xmlns="http://www.w3.org/2000/svg" className={`me-1 icon icon-infco icon-infco-building-store`} width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
            <line x1={3} y1={21} x2={21} y2={21}></line>
            <path d="M3 7v1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1m0 1a3 3 0 0 0 6 0v-1h-18l2 -4h14l2 4"></path>
            <line x1={5} y1={21} x2={5} y2="10.85"></line>
            <line x1={19} y1={21} x2={19} y2="10.85"></line>
            <path d="M9 21v-4a2 2 0 0 1 2 -2h2a2 2 0 0 1 2 2v4"></path>
          </svg>
          Produtos
          <span class="badge bg-red ms-2">ID {props.load.id}</span>
        </h3>
        <div className="card-actions">
          <Link to="/workplace/products" className="btn btn-outline-white">
            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
              <path stroke="none" d="M0 0h24v24H0z" fill="none" />
              <path d="M9 7h-3a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-3" />
              <path d="M9 15h3l8.5 -8.5a1.5 1.5 0 0 0 -3 -3l-8.5 8.5v3" />
              <line x1={16} y1={5} x2={19} y2={8} />
            </svg>
            Editar
          </Link>
          {
            window.location.pathname.split('/')[3] == "848438" || window.location.pathname.split('/')[3] == "192985" ?
              (null)
              :
              <a onClick={() => deleteBlock()} className="btn btn-red ms-2" data-bs-toggle="modal" data-bs-target="#modal-confirmation">
                <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-trash" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <line x1={4} y1={7} x2={20} y2={7} />
                  <line x1={10} y1={11} x2={10} y2={17} />
                  <line x1={14} y1={11} x2={14} y2={17} />
                  <path d="M5 7l1 12a2 2 0 0 0 2 2h8a2 2 0 0 0 2 -2l1 -12" />
                  <path d="M9 7v-3a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v3" />
                </svg>
                Eliminar
              </a>
          }
        </div>
      </div>
    </div>
  </>
  )
}

export default Products;