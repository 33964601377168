import React from 'react';
import api from '../utils/api';
import {notify} from "../utils/notify";

class Accordion extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            "morada": null,
            "email": null,
            "contact": null,
            "w_hours": null,
            "external": {
                "youtube": null,
                "facebook": null,
                "instagram": null
            },
            "percentages": {
                "iva": null,
                "referral": null,
                "comm": null
            },
            "shipping": [],
            "renewal": {
                "price": null,
                "barcode": null
            },
            "units": {
                "k": null,
                "s": null
            }
        }
    }

    componentDidMount() {
        const _this = this;
        api.post("bo_settings", {
            headers: {
                "X-Requested-With": "XMLHttpRequest"
            }
        }).then((response) => {
            _this.setState(response.data)
            if(response.data.shipping.length == 0) {
                _this.setState({
                    shipping: [{
                        "title": "",
                        "price": "0",
                        "description": "",
                        "barcode": "",
                        "highlight": "1"
                    },{
                        "title": "",
                        "price": "0",
                        "description": "",
                        "barcode": "",
                        "highlight": "0"
                    }]
                })
            }
        }, (error) => {
        });
    }

    submit() {
        const _this = this;

        var shippingMethods = [];
        for (var i = 0; i < this.state.shipping.length; i++) {
            var title = document.querySelector('[name="shipping-method-title-' + i + '"]').value;
            var price = Number(_this.trimnum(document.querySelector('[name="shipping-method-price-' + i + '"]').value)).toFixed(2);
            var description = document.querySelector('[name="shipping-method-description-' + i + '"]').value;
            var barcode = document.querySelector('[name="shipping-method-barcode-' + i + '"]').value;
            var highlight = document.querySelector('[data-idx="' + i + '"]').checked ? "1" : "0";

            shippingMethods.push({
                "title": title,
                "price": price,
                "description": description,
                "barcode": barcode,
                "highlight": highlight
            })
            
        }
        this.setState({
            shipping: shippingMethods
        }, () => {
		    notify('loading', 'Aguarde um momento...', Infinity, false)
            api.post("bo_save_settings", {
                settings: JSON.stringify(_this.state)
            }, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            }).then((response) => {
                notify('success', 'As configurações gerais foram atualizadas!', 6000, false)
            }, (error) => {
                notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
            });
        })
    }

    trimnum(params) {
        if(params) {
            return params.match(/[-]{0,1}[\d]*[.]{0,1}[\d]+/g)[0];
        }
    }


    render() {
        return (
            <div className="modal modal-blur fade" id="modal-settings" tabIndex={-1} role="dialog" aria-hidden="true">
                <div className="modal-dialog modal-lg modal-dialog-scrollable modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title">Configurações gerais</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" style={{ boxShadow: "none" }} />
                        </div>
                        <div className="modal-body">
                            <div className="hr-text mt-0 mb-3">Matriz</div>

                            <div className="mb-3">
                                <label className="form-label">Número de telefone</label>
                                <input type="text" className="form-control" placeholder="Número de telefone do cabeçalho" defaultValue={this.state.contact} onInput={e => this.setState({ contact: e.target.value.trim() })} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">E-mail do formulário de contacto</label>
                                <input type="text" className="form-control" placeholder="E-mail onde irá receber as mensagens do formulário de contacto" defaultValue={this.state.email} onInput={e => this.setState({ email: e.target.value.trim() })} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Horário</label>
                                <input type="text" className="form-control" placeholder="Horário de funcionamento" defaultValue={this.state.w_hours} onInput={e => this.setState({ w_hours: e.target.value.trim() })} />
                            </div>
                            <div className="mb-3">
                                <label className="form-label">Morada da sede</label>
                                <input type="text" className="form-control" placeholder="Morada da sede" defaultValue={this.state.morada} onInput={e => this.setState({ morada: e.target.value.trim() })} />
                            </div>
                            <div>
                                <label className="form-label">Redes sociais</label>
                                <div className="row">
                                    <div className="col-12 mb-3">
                                        <div className="card card-sm">
                                            <div className="card-body">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <span className="bg-blue text-white avatar">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" width="24px" height="24px"><g id="surface4016594"><path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(255, 255, 255)', fillOpacity: 1 }} d="M 17.523438 9 L 14 9 L 14 7 C 14 5.96875 14.085938 5.316406 15.5625 5.316406 L 17.429688 5.316406 L 17.429688 2.136719 C 16.523438 2.042969 15.609375 1.996094 14.691406 2 C 11.980469 2 10 3.65625 10 6.699219 L 10 9 L 7 9 L 7 13 L 10 13 L 10 22 L 14 22 L 14 12.996094 L 17.066406 12.996094 Z M 17.523438 9 " /></g></svg>
                                                        </span>
                                                    </div>
                                                    <div className="col">
                                                        <div className="font-weight-medium">
                                                            Facebook
                                                        </div>
                                                        <div className="mt-1">
                                                            <input type="text" className="form-control" placeholder="Link do facebook" defaultValue={this.state.external.facebook} onInput={e => { var external = this.state.external; external.facebook = e.target.value.trim(); this.setState({ external: external }) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className="card card-sm">
                                            <div className="card-body">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <span className="bg-yellow text-white avatar">
                                                            <svg xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24" width="24px" height="24px"><g id="surface4011171"><path style={{ stroke: 'none', fillRule: 'nonzero', fill: 'rgb(255, 255, 255)', fillOpacity: 1 }} d="M 8 3 C 5.242188 3 3 5.242188 3 8 L 3 16 C 3 18.757812 5.242188 21 8 21 L 16 21 C 18.757812 21 21 18.757812 21 16 L 21 8 C 21 5.242188 18.757812 3 16 3 Z M 8 5 L 16 5 C 17.652344 5 19 6.347656 19 8 L 19 16 C 19 17.652344 17.652344 19 16 19 L 8 19 C 6.347656 19 5 17.652344 5 16 L 5 8 C 5 6.347656 6.347656 5 8 5 Z M 17 6 C 16.449219 6 16 6.449219 16 7 C 16 7.550781 16.449219 8 17 8 C 17.550781 8 18 7.550781 18 7 C 18 6.449219 17.550781 6 17 6 Z M 12 7 C 9.242188 7 7 9.242188 7 12 C 7 14.757812 9.242188 17 12 17 C 14.757812 17 17 14.757812 17 12 C 17 9.242188 14.757812 7 12 7 Z M 12 9 C 13.652344 9 15 10.347656 15 12 C 15 13.652344 13.652344 15 12 15 C 10.347656 15 9 13.652344 9 12 C 9 10.347656 10.347656 9 12 9 Z M 12 9 " /></g></svg>
                                                        </span>
                                                    </div>
                                                    <div className="col">
                                                        <div className="font-weight-medium">
                                                            Instagram
                                                        </div>
                                                        <div className="mt-1">
                                                            <input type="text" className="form-control" placeholder="Link do instagram" defaultValue={this.state.external.instagram} onInput={e => { var external = this.state.external; external.instagram = e.target.value.trim(); this.setState({ external: external }) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-12 mb-3">
                                        <div className="card card-sm">
                                            <div className="card-body">
                                                <div className="row align-items-center">
                                                    <div className="col-auto">
                                                        <span className="bg-red text-white avatar">
                                                            <svg xmlns="http://www.w3.org/2000/svg" className="icon" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                                                <rect x={3} y={5} width={18} height={14} rx={4}></rect>
                                                                <path d="M10 9l5 3l-5 3z"></path>
                                                            </svg>
                                                        </span>
                                                    </div>
                                                    <div className="col">
                                                        <div className="font-weight-medium">
                                                            Youtube
                                                        </div>
                                                        <div className="mt-1">
                                                            <input type="text" className="form-control" placeholder="Link do youtube" defaultValue={this.state.external.youtube} onInput={e => { var external = this.state.external; external.youtube = e.target.value.trim(); this.setState({ external: external }) }} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="hr-text mt-3 mb-4">Loja E-commerce</div>

                            <div className="row">
                                <div className="col-lg-4">
                                    <div className="mb-3">
                                        <label className="form-label">Taxa de IVA</label>
                                        <div className="input-group">
                                            <input type="text" defaultValue={this.trimnum(this.state.percentages.iva)} onInput={e => { var percentages = this.state.percentages; percentages.iva = this.trimnum(e.target.value.trim()); this.setState({ percentages: percentages }) }} className="form-control" placeholder="" autoComplete="off" />
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="mb-3">
                                        <label className="form-label">Desconto do c. promocional</label>
                                        <div className="input-group">
                                            <input type="text" defaultValue={this.trimnum(this.state.percentages.referral)} onInput={e => { var percentages = this.state.percentages; percentages.referral = this.trimnum(e.target.value.trim()); this.setState({ percentages: percentages }) }} className="form-control" placeholder="" autoComplete="off" />
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-4">
                                    <div className="mb-3">
                                        <label className="form-label">Comissão dos afiliados</label>
                                        <div className="input-group">
                                            <input type="text" defaultValue={this.trimnum(this.state.percentages.comm)} onInput={e => { var percentages = this.state.percentages; percentages.comm = this.trimnum(e.target.value.trim()); this.setState({ percentages: percentages }) }} className="form-control" placeholder="" autoComplete="off" />
                                            <span className="input-group-text">%</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label className="form-label">Preço por unidade (Simulador)</label>
                                        <small className="subheader">Porta-chaves</small>
                                        <div className="input-group mb-2">
                                            <span className="input-group-text">€</span>
                                            {
                                                this.state.units.k &&
                                                <input type="text" className="form-control" defaultValue={Number(this.trimnum(this.state.units.k)) ? Number(this.trimnum(this.state.units.k)).toFixed(2) : '0.00'} onInput={e => { var units = this.state.units; units.k = this.trimnum(e.target.value.trim()); this.setState({ units: units }) }} placeholder="Preço" autoComplete="off" />
                                            }
                                            <span className="input-group-text">c/ IVA</span>
                                        </div>
                                        <small className="subheader">Cartões standard</small>
                                        <div className="input-group mb-2">
                                            <span className="input-group-text">€</span>
                                            {
                                                this.state.units.s &&
                                                <input type="text" className="form-control" defaultValue={Number(this.trimnum(this.state.units.s)) ? Number(this.trimnum(this.state.units.s)).toFixed(2) : '0.00'} onInput={e => { var units = this.state.units; units.s = this.trimnum(e.target.value.trim()); this.setState({ units: units }) }} placeholder="Preço" autoComplete="off" />
                                            }
                                            <span className="input-group-text">c/ IVA</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="mb-3">
                                        <label className="form-label">Renovação de serviço</label>
                                        <div className="input-group mb-2">
                                            <span className="input-group-text">€</span>
                                            {
                                                this.state.renewal.price &&
                                                <input type="text" className="form-control" defaultValue={Number(this.trimnum(this.state.renewal.price)) ? Number(this.trimnum(this.state.renewal.price)).toFixed(2) : '0.00'} onInput={e => { var renewal = this.state.renewal; renewal.price = this.trimnum(e.target.value.trim()); this.setState({ renewal: renewal }) }} placeholder="Preço" autoComplete="off" />
                                            }
                                            <span className="input-group-text">c/ IVA</span>
                                        </div>
                                        <div className="input-group">
                                            <input type="text" className="form-control" defaultValue={this.state.renewal.barcode} onInput={e => { var renewal = this.state.renewal; renewal.barcode = this.trimnum(e.target.value.trim()); this.setState({ renewal: renewal }) }} placeholder="Código de barras" autoComplete="off" />
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="form-label">Opções de envio</label>
                                    <small className="text-muted">Selecione a opção de envio mais popular</small>
                                    <div className="form-selectgroup form-selectgroup-boxes d-flex flex-column mt-2">
                                        {
                                            this.state.shipping.map((item, i) => {
                                                return <label className="form-selectgroup-item flex-fill">
                                                    <input type="radio" defaultChecked={parseInt(item.highlight)} data-idx={i} name="form-shipping-method" defaultValue="normal" className="form-selectgroup-input" />
                                                    <div className="form-selectgroup-label d-flex align-items-center p-3">
                                                        <div className="me-3">
                                                            <span className="form-selectgroup-check" />
                                                        </div>
                                                        <div class="d-flex">
                                                            <div className="col">
                                                                <div className="font-weight-medium">
                                                                    <div className="input-group">
                                                                        <input type="text" name={`shipping-method-title-${i}`} className="form-control" defaultValue={item.title} placeholder="Título" autoComplete="off" />
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2">
                                                                    <div className="input-group">
                                                                        <span className="input-group-text">€</span>
                                                                        <input type="text" name={`shipping-method-price-${i}`} className="form-control" defaultValue={Number(this.trimnum(item.price)).toFixed(2)} placeholder="Preço" autoComplete="off" />
                                                                        <span className="input-group-text">c/ IVA</span>
                                                                    </div>
                                                                </div>
                                                                <div className="mt-2">
                                                                    <input type="text" name={`shipping-method-description-${i}`} className="form-control" defaultValue={item.description} placeholder="Descrição" autoComplete="off" />
                                                                </div>
                                                                <div className="mt-2">
                                                                    <div className="input-group">
                                                                        <input type="text" name={`shipping-method-barcode-${i}`} className="form-control" defaultValue={item.barcode} placeholder="Código de barras" autoComplete="off" />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </label>
                                            })
                                        }
                                    </div>
                                </div>
                            </div>

                        </div>


                        <div className="modal-footer">
                            <a className="btn" data-bs-dismiss="modal">
                                Cancelar
                            </a>
                            <a className="btn btn-dark ms-auto" data-bs-dismiss="modal" onClick={() => this.submit()}>
                                <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-world-upload" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                    <path d="M21 12a9 9 0 1 0 -9 9"></path>
                                    <path d="M3.6 9h16.8"></path>
                                    <path d="M3.6 15h8.4"></path>
                                    <path d="M11.578 3a17 17 0 0 0 0 18"></path>
                                    <path d="M12.5 3c1.719 2.755 2.5 5.876 2.5 9"></path>
                                    <path d="M18 21v-7m3 3l-3 -3l-3 3"></path>
                                </svg>
                                Guardar
                            </a>
                        </div>

                    </div>
                </div>
            </div>
        )
    }
}

export default Accordion;