import React from 'react';
import { withRouter, Switch, Route, Redirect } from "react-router-dom";

import Logo from "../components/Logo";
import Login from "../views/index/Login";

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {}
  }

  componentDidUpdate(prevProps) {
    if (this.props.location.pathname !== prevProps.location.pathname) {
      //update
    }
  }

  render() {
    return (
      <div className="antialiased d-flex flex-column">
        <div className="page page-center">
          <div className="container-tight py-4">
            <Logo />
            <Switch>
              <Route path="/" exact component={Login} />
              <Redirect from="/workplace" to="/workplace/" />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(props => <Index {...props} />);