import React from 'react';

class Backgrounds extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            custom: this.props.color?(this.props.color.indexOf('#')>-1 ||this.props.color.indexOf('rgb')>-1 ?this.props.color:null) : null
        }
    }

    componentDidMount() {
        const props = this.props;
        if (props.color == null) {
            document.querySelectorAll('#block-background-' + props.id + ' [value="white"]')[0].checked = true;
        } else {
            document.querySelectorAll('#block-background-' + props.id + ' [value="' + (props.color != "" ? props.color : "white") + '"]')[0].checked = true;
        }
    }

    setcolor(params) {
        //document.querySelector('[name="color-'+this.props.id+'"]:checked').checked = false;
        this.setState({ custom: params })
    }

    render() {
        const props = this.props;

        return (
            <div className="row mb-3">
                <div className="col-lg-12">
                    <div className="mb-3">
                        <div className="form-label">Fundo</div>
                        <div className="row g-2" id={`block-background-${props.id}`}>
                                <small class="form-hint">Paleta de cores</small>
                            <div className="col-auto">
                                <label className="form-colorinput">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="dark" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-dark" />
                                </label>
                            </div>
                            <div className="col-auto">
                                <label className="form-colorinput form-colorinput-light">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="white" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-white" />
                                </label>
                            </div>
                            <div className="col-auto">
                                <label className="form-colorinput">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="blue" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-blue" />
                                </label>
                            </div>
                            <div className="col-auto">
                                <label className="form-colorinput">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="azure" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-azure" />
                                </label>
                            </div>
                            <div className="col-auto">
                                <label className="form-colorinput">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="indigo" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-indigo" />
                                </label>
                            </div>
                            <div className="col-auto">
                                <label className="form-colorinput">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="purple" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-purple" />
                                </label>
                            </div>
                            <div className="col-auto">
                                <label className="form-colorinput">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="pink" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-pink" />
                                </label>
                            </div>
                            <div className="col-auto">
                                <label className="form-colorinput">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="red" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-red" />
                                </label>
                            </div>
                            <div className="col-auto">
                                <label className="form-colorinput">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="orange" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-orange" />
                                </label>
                            </div>
                            <div className="col-auto">
                                <label className="form-colorinput">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="yellow" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-yellow" />
                                </label>
                            </div>
                            <div className="col-auto">
                                <label className="form-colorinput">
                                    <input name={`color-${props.id}`} type="radio" defaultValue="lime" className="form-colorinput-input" />
                                    <span className="form-colorinput-color bg-lime" />
                                </label>
                            </div>
                            {
                                this.state.custom ?
                                <div className="col-auto">
                                    <label className="form-colorinput">
                                        <input name={`color-${props.id}`} type="radio" defaultValue={this.state.custom} className="form-colorinput-input" defaultChecked />
                                        <span className="form-colorinput-color bg-custom" style={{background: `${this.state.custom}`}} />
                                    </label>
                                </div>
                                :
                                null
                            }
                        </div>
                        <div className="row mt-2 mb-3">
                            <label className="form-colorinput">
                                <small class="form-hint">Cor personalizada</small>
                                <input className="form-control form-control-color" name={`color-custom-${props.id}`} type="color" onChange={e => this.setcolor(e.target.value)} />
                            </label>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default Backgrounds;