import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import api from "../../utils/api";
import { notify } from "../../utils/notify";

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();

  useEffect(() => {
    document.title = "BackOffice • powered by Infoeco";
  }, [])

  function signIn() {
    notify('loading', 'A autenticar... Aguarde!', Infinity, false);
    api.post("login", {
      email: email,
      password: password
    }, {
      headers: {
        "X-Bo-Client": "2a1f2a8928a31927a8c07751eac5be5b"
      }
    }).then((response) => {
      window.location.replace("/workplace");
    }, (error) => {
      notify('error', 'Credenciais incorretas! Tente novamente.', 6000, false);
    });
  }

  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (keyCode !== 13) return;
      signIn()
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
      <form className="card card-md" autoComplete="off">
        <div className="card-body">
          <h2 className="card-title text-center mb-4">Insira as suas credenciais</h2>
          <div className="mb-3">
            <label className="form-label">E-mail</label>
            <input type="email" className="form-control" placeholder="Insira o seu e-mail" onChange={e => setEmail(e.target.value)} required />
          </div>
          <div className="mb-2">
            <label className="form-label">
              Password
            </label>
            <div className="input-group input-group-flat">
              <input type="password" className="form-control" placeholder="Insira a sua password" onChange={e => setPassword(e.target.value)} required />
            </div>
          </div>
          <div className="form-footer">
            <button type="button" className="btn btn-primary w-100" onClick={signIn}>
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M14 8v-2a2 2 0 0 0 -2 -2h-7a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h7a2 2 0 0 0 2 -2v-2"></path>
                <path d="M20 12h-13l3 -3m0 6l-3 -3"></path>
              </svg>
              Entrar
            </button>
          </div>
        </div>
      </form>
  );
}

export default Login;