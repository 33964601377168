import React from 'react';
import { withRouter, Switch, Route, Redirect, Link } from "react-router-dom";
import api from '../../utils/api';

import {notify} from "../../utils/notify";

import Banners from '../../components/Banners';
import Info from '../../components/Info';
import Grid from '../../components/Grid';
import Swiper from '../../components/Swiper';
import Products from '../../components/Products';
import Accordion from '../../components/Accordion';
import Item from '../../components/Layout';


import Icon from '../../components/Icons';
import Badge from '../../components/Badges';

class Page extends React.Component {
	constructor(props) {
		super(props)

		this.state = {
			pages: [],
			languages: [{ flag: "PT", code: "pt", lang: "Português" }],
			blocks: [],
			active_language: "pt",
			load: []
		}
	}

	onload() {
		const pageId = window.location.pathname.split('/')[3];
		const title = (document.querySelectorAll('a[data-id="' + pageId + '"]').length > 0 ? document.querySelectorAll('a[data-id="' + pageId + '"]')[0].innerText : "");

		document.title = title + " • BackOffice";
		document.getElementsByClassName('page-title')[0].innerHTML = `
		<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-notes" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
			<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
			<rect x="5" y="3" width="14" height="18" rx="2"></rect>
			<line x1="9" y1="7" x2="15" y2="7"></line>
			<line x1="9" y1="11" x2="15" y2="11"></line>
			<line x1="9" y1="15" x2="13" y2="15"></line>
		</svg>` + title;

		var _this = this;
		_this.setState({
			load: []
		})
		api.post("layout", {
			page: pageId,
			hl: this.state.active_language
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			_this.setState({
				load: response.data
			}, () => {
				window.layoutSortable('#sortable');
			});
		}, (error) => {
			alert('Erro');
		});
	}

	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			this.setState({ load: [] }, () => {
				this.onload();
			});
		}
	}

	switchLang(params) {
		this.setState({
			active_language: params
		}, () => {
			this.setState({ load: [] }, () => {
				this.onload();
			});
		})
	}

	componentDidMount() {
		const _this = this;
		
		this.onload();

		api.post("sitemap", {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			_this.setState({
				pages: response.data
			});
		}, (error) => {
			alert('Erro');
		});

		
		api.post("translations", {t:"1"}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			_this.setState({
				languages: response.data
			})
		}, (error) => {
			alert('Erro');
		});

	}

	refresh() {
		this.onload();
	}

	newBlock(item) {
		const pageId = window.location.pathname.split('/')[3];
		const id = Math.floor(Math.random() * (1000000 - 100000 + 1)) + 100000;
		var obj;

		switch (item) {
			case "Banners":
				obj = {
					page: pageId,
					block: item,
					id: id,
					hl: this.state.active_language,
					type: (document.querySelectorAll('#modal-banners-type input:checked').length ? document.querySelectorAll('#modal-banners-type input:checked')[0].value : "1")
				}
				break;
			case "Info":
				obj = {
					page: pageId,
					block: item,
					id: id,
					hl: this.state.active_language,
					type: (document.querySelectorAll('#modal-info-type input:checked').length ? document.querySelectorAll('#modal-info-type input:checked')[0].value : "1")
				}
				break;
			case "Grid":
				obj = {
					page: pageId,
					block: item,
					id: id,
					hl: this.state.active_language,
				}
				break;
			case "Swiper":
				obj = {
					page: pageId,
					block: item,
					id: id,
					hl: this.state.active_language,
				}
				break;
			case "Accordion":
				obj = {
					page: pageId,
					block: item,
					id: id,
					hl: this.state.active_language,
					type: (document.querySelectorAll('#modal-accordion-type input:checked').length ? document.querySelectorAll('#modal-accordion-type input:checked')[0].value : "1")
				}
				break;
			case "Products":
				obj = {
					page: pageId,
					block: item,
					id: id,
					hl: this.state.active_language,
				}
		}

		api.post("newblock", obj, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			document.getElementById('blocks-refresh').click();
		}, (error) => {
			alert('Erro');
		});
	}

	deleteBlock() {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		var obj = JSON.parse(document.getElementById('modal-confirmation').dataset.block);

		api.post("deleteblock", obj, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			notify('success', 'Eliminado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	layoutSave() {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		var data = [];
		for (var i = 0; i < document.querySelectorAll('#sortable input').length; i++) {
			data.push(document.querySelectorAll('#sortable input')[i].value);
		}
		api.post("editlayout", {
			action: 'order',
			order: JSON.stringify(data)
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
			notify('success', 'Layout atualizado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});

	}

	setChanges(field, val) {
		var state = this.state;
		var tab = (state.type == "1" ? (state.images.length ? state.images[0].id : "") : (state.images.length ? document.querySelectorAll('#modal-slideshow-tab-images-' + this.state.id + ' input:checked')[0].value : ""));
		var _this;
		var val = val.trim();

		for (var i = 0; i < state.images.length; i++) {
			if (state.images[i].id == tab) {
				_this[field] = val;
			}
		}

		this.setState(state);
	}


	cloneSelect() {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		const _this = this;

		if(document.querySelector('[name="modal-clone-p"]').value.trim().length && document.querySelector('[name="modal-clone-l"]').value.trim().length) {
			api.post("blocks", {
				page: document.querySelector('[name="modal-clone-p"]').value.trim(),
				hl: document.querySelector('[name="modal-clone-l"]').value.trim()
			}, {
				headers: {
					"X-Requested-With": "XMLHttpRequest",
				}
			}).then((response) => {
				_this.setState({
					blocks: response.data
				}, () => {
					document.querySelector('[name="modal-clone-b"]').value = "";
				});

				notify('success', 'Atualizado com sucesso!', 6000, false)
			}, (error) => {
				notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
			});
		}
	}

	newClone() {
		notify('loading', 'Aguarde um momento...', Infinity, false)
		const id = JSON.parse(document.querySelector('[name="modal-clone-b"]').value)[0];
		const block = JSON.parse(document.querySelector('[name="modal-clone-b"]').value)[1];
		const hl = document.querySelector('[name="modal-clone-l"]').value.trim();

		const page = window.location.pathname.split('/')[3];
		const target_id = Math.floor(Math.random() * (1000000 - 100000 + 1)) + 100000;
		const target_hl = this.state.active_language;

		api.post("addclone", {
			id: id,
			block: block,
			hl: hl,
			page: page,
			target_id: target_id,
			target_hl: target_hl
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest"
			}
		}).then((response) => {
			notify('success', 'Atualizado com sucesso!', 6000, false)
		}, (error) => {
			notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
		});
	}

	render() {
		return (
			<>
				<div className="container-xl">
					<div className="page-header">
						<div className="row align-items-center">
							<div className="col">
								<div className="page-pretitle">Painel {">"} Páginas {">"}</div>
								<h2 className="page-title"></h2>
							</div>
							<div className="col-auto ms-auto">
								<div className="btn-list">
									<a id="blocks-refresh" className="btn btn-white btn-icon d-none" onClick={() => this.refresh()}>
										<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-refresh" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
											<path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
										</svg>
									</a>
									<a id="draft-load" className="btn btn-white btn-icon d-none" onClick={() => this.onload()}>
										<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-refresh" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
											<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
											<path d="M20 11a8.1 8.1 0 0 0 -15.5 -2m-.5 -4v4h4"></path>
											<path d="M4 13a8.1 8.1 0 0 0 15.5 2m.5 4v-4h-4"></path>
										</svg>
									</a>
									<div className="dropdown">
										<button type="button" className="btn btn-primary d-none d-sm-inline-block dropdown-toggle" data-bs-toggle="dropdown">
											<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
												<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
												<line x1="12" y1="5" x2="12" y2="19"></line>
												<line x1="5" y1="12" x2="19" y2="12"></line>
											</svg>
											Novo bloco
										</button>
										<button type="button" className="btn btn-primary d-sm-none dropdown-toggle" data-bs-toggle="dropdown">
											<svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-plus" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
												<path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
												<line x1="12" y1="5" x2="12" y2="19"></line>
												<line x1="5" y1="12" x2="19" y2="12"></line>
											</svg>
										</button>
										<div className="dropdown-menu">
											<a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#modal-banners-type">
												<Icon svg="Banners" me="2" />
												Banners
												<Badge type="Banners" id="" ms="auto" />
											</a>
											<a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#modal-info-type">
												<Icon svg="Info" me="2" />
												Informação
												<Badge type="Info" id="" ms="auto" />
											</a>
											<a className="dropdown-item" onClick={() => this.newBlock('Grid')}>
												<Icon svg="Grid" me="2" />
												Grelha
												<Badge type="Grid" id="" ms="auto" />
											</a>
											<a className="dropdown-item" onClick={() => this.newBlock('Swiper')}>
												<Icon svg="Swiper" me="2" />
												Swiper
												<Badge type="Swiper" id="" ms="auto" />
											</a>
											<a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#modal-accordion-type">
												<Icon svg="Accordion" me="2" />
												Acordeão
												<Badge type="Accordion" id="" ms="auto" />
											</a>
											<a className="dropdown-item" onClick={() => this.newBlock('Products')}>
												<Icon svg="Products" me="2" />
												Produtos
												<Badge type="Products" id="" ms="auto" />
											</a>
											<div class="dropdown-divider" />
											<a className="dropdown-item" data-bs-toggle="modal" data-bs-target="#modal-clone">
												<Icon svg="Copy" me="2" />
												Clonar bloco
											</a>
										</div>
									</div>

									<Link to="/workplace/sitemap" className="btn d-none d-sm-inline-block">
										<Icon svg="Gestorsitemap" me="2" />
										Gestor de sitemap
									</Link>
									<Link to="/workplace/sitemap" className="btn btn-white d-sm-none btn-icon">
										<Icon svg="Gestorsitemap" me="-1" />
									</Link>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="page-body">
					<div className="container-xl">



						<div className="row">
							<div className="col-md-3 d-sm-none">
								<label className="form-label">Idiomas disponíveis</label>
								{
									this.state.languages.length > 0 &&

									<div className="btn-group w-100">
										<button type="button" className={`btn ${this.state.languages[0].code == this.state.active_language && 'btn-primary'}`} onClick={() => this.switchLang(this.state.languages[0].code)}>
											<span className={`flag flag-xs flag-country-${this.state.languages[0].flag.toLowerCase()}`} />
											{this.state.languages[0].lang}
										</button>
										{
											this.state.languages.length > 1 &&

											<button type="button" className={`btn ${this.state.languages[1].code == this.state.active_language && 'btn-primary'}`} onClick={() => this.switchLang(this.state.languages[1].code)}>
												<span className={`flag flag-xs flag-country-${this.state.languages[1].flag.toLowerCase()}`} />
												{this.state.languages[1].lang}
											</button>
										}
										{
											this.state.languages.length > 2 &&

											<div className="btn-group " role="group">
												<button id="btnGroupDrop1" type="button" className="btn dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">Outros</button>
												<div className="dropdown-menu dropdown-menu-end" style={{ position: 'absolute', inset: '0px auto auto 0px', margin: '0px', transform: 'translate(-87px, 38px)' }} data-popper-placement="bottom-end">
													{
														this.state.languages.map((item, i) => {
															if (i > 1) {
																return <a className={`dropdown-item ${item.code == this.state.active_language && 'btn-primary'}`} onClick={() => this.switchLang(item.code)}>
																	<span className={`flag flag-xs flag-country-${item.flag.toLowerCase()}`} />
																	{item.lang}
																</a>
															}
														})
													}
												</div>
											</div>
										}
									</div>
								}
							</div>

							<div className="col-auto d-none d-sm-inline-block">
								<label className="form-label">Idiomas disponíveis</label>
								<div className="btn-group w-50">
									{
										this.state.languages.map(item => {
											return <button type="button" className={`btn ${item.code == this.state.active_language && 'btn-primary'}`} onClick={() => this.switchLang(item.code)}>
												<span className={`flag flag-xs flag-country-${item.flag.toLowerCase()}`} />
												{item.lang}
											</button>
										})
									}
								</div>
							</div>
						</div>

						<div className="row gx-lg-4">
							<div className="col-md-8 order-2 order-md-1">
								{
									this.state.load.map(item => {
										switch (item.block) {
											case "Banners":
												return <Banners load={item} />
												break;
											case "Info":
												return <Info load={item} />
												break;
											case "Grid":
												return <Grid load={item} />
												break;
											case "Swiper":
												return <Swiper load={item} />
												break;
											case "Products":
												return <Products load={item} />
												break;
											case "Accordion":
												return <Accordion load={item} />
												break;
										}
									})
								}
							</div>
							<div className="d-lg-block col-md-4 order-1 order-md-2">
								<div className="card mt-4">
									<div className="card-header">
										<h3 className="card-title">
											<svg xmlns="http://www.w3.org/2000/svg" className="me-1 icon icon-infco icon-infco-layout-distribute-horizontal" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
												<path stroke="none" d="M0 0h24v24H0z" fill="none" />
												<line x1={4} y1={4} x2={20} y2={4} />
												<line x1={4} y1={20} x2={20} y2={20} />
												<rect x={6} y={9} width={12} height={6} rx={2} />
											</svg>
											Layout da página
										</h3>
									</div>
									<div className="card-body">
										<div id="layout-warning" data-bs-toggle="modal" data-bs-target="#modal-warning" />
										<div className="form-selectgroup form-selectgroup-boxes d-flex flex-column">
											<ul id="sortable" class="sortable-container">
												<a onClick={() => this.layoutSave()} className="request_save d-none" />
												{
													this.state.load.map(item => {
														return <li className="sortable-item"><Item type={item.block} id={item.id} status={item.status} /></li>
													})
												}
											</ul>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="modal modal-blur fade" id="modal-banners-type" tabIndex={-1} role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Tipo de banner</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								<div className="form-selectgroup-boxes row mb-3">

									<div className="col-lg-6">
										<label className="form-selectgroup-item">
											<input type="radio" className="form-selectgroup-input" name="modal-banner-type" defaultValue="1" defaultChecked />
											<span className="form-selectgroup-label d-flex align-items-center p-3">
												<span className="me-3">
													<span className="form-selectgroup-check" />
												</span>
												<span className="form-selectgroup-label-content">
													<span className="form-selectgroup-title strong mb-1">Banner simples</span>
												</span>
											</span>
										</label>
									</div>

									<div className="col-lg-6">
										<label className="form-selectgroup-item">
											<input type="radio" className="form-selectgroup-input" name="modal-banner-type" defaultValue="2" />
											<span className="form-selectgroup-label d-flex align-items-center p-3">
												<span className="me-3">
													<span className="form-selectgroup-check" />
												</span>
												<span className="form-selectgroup-label-content">
													<span className="form-selectgroup-title strong mb-1">Slideshow</span>
												</span>
											</span>
										</label>
									</div>

								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-white me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={() => this.newBlock('Banners')}>Confirmar</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal modal-blur fade" id="modal-info-type" tabIndex={-1} role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Layout do bloco</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								<div className="form-selectgroup-boxes row mb-3">

									<div className="col-lg-6">
										<label className="form-selectgroup-item">
											<input type="radio" className="form-selectgroup-input" name="modal-info-type" defaultValue="1" defaultChecked />
											<span className="form-selectgroup-label d-flex align-items-center p-3">
												<span className="me-3">
													<span className="form-selectgroup-check" />
												</span>
												<span className="form-selectgroup-label-content">
													<span className="form-selectgroup-title strong mb-1">Texto corrido</span>
												</span>
											</span>
										</label>
									</div>

									<div className="col-lg-6">
										<label className="form-selectgroup-item">
											<input type="radio" className="form-selectgroup-input" name="modal-info-type" defaultValue="2" />
											<span className="form-selectgroup-label d-flex align-items-center p-3">
												<span className="me-3">
													<span className="form-selectgroup-check" />
												</span>
												<span className="form-selectgroup-label-content">
													<span className="form-selectgroup-title strong mb-1">Texto em grelha</span>
												</span>
											</span>
										</label>
									</div>

								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-white me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-yellow" data-bs-dismiss="modal" onClick={() => this.newBlock('Info')}>Confirmar</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal modal-blur fade" id="modal-accordion-type" tabIndex={-1} role="dialog" aria-hidden="true">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Tipo de acordeão</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								<div className="form-selectgroup-boxes row mb-3">

									<div className="col-lg-6">
										<label className="form-selectgroup-item">
											<input type="radio" className="form-selectgroup-input" name="modal-info-type" defaultValue="1" defaultChecked />
											<span className="form-selectgroup-label d-flex align-items-center p-3">
												<span className="me-3">
													<span className="form-selectgroup-check" />
												</span>
												<span className="form-selectgroup-label-content">
													<span className="form-selectgroup-title strong mb-1">Padrão</span>
												</span>
											</span>
										</label>
									</div>

									<div className="col-lg-6">
										<label className="form-selectgroup-item">
											<input type="radio" className="form-selectgroup-input" name="modal-info-type" defaultValue="2" />
											<span className="form-selectgroup-label d-flex align-items-center p-3">
												<span className="me-3">
													<span className="form-selectgroup-check" />
												</span>
												<span className="form-selectgroup-label-content">
													<span className="form-selectgroup-title strong mb-1">Numérico</span>
												</span>
											</span>
										</label>
									</div>

								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-white me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-orange" data-bs-dismiss="modal" onClick={() => this.newBlock('Accordion')}>Confirmar</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal modal-blur fade" id="modal-clone" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-header">
								<h5 className="modal-title">Clonar bloco existente</h5>
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
							</div>
							<div className="modal-body">
								<div className="mb-3">
									<label className="form-label">Selecione o bloco que pretende clonar</label>
									<div className="mb-3">
										<select name="modal-clone-p" className="form-select" onChange={() => this.cloneSelect()}>
											<option value="" selected disabled>Página onde está o bloco</option>
											{
												this.state.pages.map(item => {
													if (item.url != null && item.url != "" && item.url.indexOf('.') === -1) {
														return <option value={item.id}>{item.title}</option>
													}
												})
											}
										</select>
									</div>
									<div className="mb-3">
										<select name="modal-clone-l" className="form-select" onChange={() => this.cloneSelect()}>
											<option value="" selected disabled>Língua onde está o bloco</option>
											{
												this.state.languages.map(item => {
													return <option value={item.code}>{item.lang}</option>
												})
											}
										</select>
									</div>
									{
										//this.state.blocks.length > 0 &&

										<div className="mb-3">
											<select name="modal-clone-b" className="form-select">
												<option value="" selected disabled>Selecione o bloco</option>
												{
													this.state.blocks.map(item => {
														return <option value={JSON.stringify([item.id, item.block])}>{item.id}</option>
													})
												}
											</select>
										</div>
									}
								</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-white me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-success" data-bs-dismiss="modal" onClick={() => this.newClone()}>Confirmar</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal modal-blur fade" id="modal-confirmation" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-sm modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-status bg-danger"></div>
							<div className="modal-body text-center py-4">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
								<svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-danger icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
								<div className="modal-title">Tem a certeza?</div>
								<div>Esta ação é irreversível! Pretende continuar?</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn bg-white me-auto" data-bs-dismiss="modal">Cancelar</button>
								<button type="button" className="btn btn-danger" data-bs-dismiss="modal" onClick={() => this.deleteBlock()}>Eliminar</button>
							</div>
						</div>
					</div>
				</div>
				<div className="modal modal-blur fade" id="modal-warning" tabIndex={-1} aria-modal="true" role="dialog">
					<div className="modal-dialog modal-sm modal-dialog-centered" role="document">
						<div className="modal-content">
							<div className="modal-status bg-yellow"></div>
							<div className="modal-body text-center py-4">
								<button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close" />
								<svg xmlns="http://www.w3.org/2000/svg" className="icon mb-2 text-yellow icon-lg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round"><path stroke="none" d="M0 0h24v24H0z" fill="none" /><path d="M12 9v2m0 4v.01" /><path d="M5 19h14a2 2 0 0 0 1.84 -2.75l-7.1 -12.25a2 2 0 0 0 -3.5 0l-7.1 12.25a2 2 0 0 0 1.75 2.75" /></svg>
								<div className="modal-title">Proibido!</div>
								<div>Não pode alterar o layout sem publicar a versão draft.</div>
							</div>
							<div className="modal-footer">
								<button type="button" className="btn btn-yellow" data-bs-dismiss="modal">OK</button>
							</div>
						</div>
					</div>
				</div>
			</>
		);
	}
}

export default withRouter(props => <Page {...props} />);