import React from 'react';
import Icon from '../components/Icons';
import Badge from '../components/Badges';
import api from '../utils/api';

function Item(props) {

  function title(params) {
    switch (params) {
      case "Banners":
        return "Banners"
        break;
      case "Info":
        return "Informação"
        break;
      case "Grid":
        return "Grelha"
        break;
      case "Swiper":
        return "Swiper"
        break;
      case "Products":
        return "Produtos"
        break;
      case "Accordion":
        return "Acordeão"
        break;
      default:
        return (null)
    }
  }

  function status(params) {
    if(props.status < 2) {
		api.post("editlayout", {
      action: 'status',
      id: props.id,
      block: props.type,
			status: (params.checked?'1':'0')
		}, {
			headers: {
				"X-Requested-With": "XMLHttpRequest",
			}
		}).then((response) => {
      
		}, (error) => {
			alert('Erro');
		});
  } else {
    if(params.checked) {
      params.checked = false;
    } else {
      params.checked = true;
    }
    document.getElementById('layout-warning').click();
  }
  }

  return (
    <label className="form-selectgroup-item flex-fill">
      <input type="checkbox" name="form-project-manager[]" defaultValue={props.id} className="form-selectgroup-input" defaultChecked={props.status == "1" ? true : false} onChange={e => status(e.target)} />
      <div className="form-selectgroup-label d-flex align-items-center p-3">
        <div className="me-3">
          <span className="form-selectgroup-check" />
        </div>
        <div className="col form-selectgroup-label-content d-flex align-items-center">
          <Icon svg={props.type} me="3" />
          <div>
            <div className="font-weight-medium">{title(props.type)}</div>
            <Badge type={props.type} id={props.id} ms="0" />
          </div>
        </div>
        <div className="col-auto">
          <svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round" className="icon icon-infco icon-infco-grid-dots">
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <circle cx={5} cy={5} r={1} />
            <circle cx={12} cy={5} r={1} />
            <circle cx={5} cy={12} r={1} />
            <circle cx={12} cy={12} r={1} />
            <circle cx={5} cy={19} r={1} />
            <circle cx={12} cy={19} r={1} />
          </svg>
        </div>
      </div>
    </label>
  )
}

export default Item;