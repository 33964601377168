import React from 'react';
import save from "./save";
import api from '../../../utils/api';

class Footer extends React.Component {
    constructor(props) {
        super(props)

        this.state = {}
    }

    draft(params) {
        if(params == "forward") {
            api.post("draft", {
                cmd: "forward",
                id: this.props.load.id,
                block: this.props.load.block,
                hl: this.props.load.hl
            }, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            }).then((response) => {
                document.getElementById('draft-load').dataset.load = JSON.stringify(response.data);
                document.getElementById('draft-load').click();
            }, (error) => {
                alert('Erro');
            });
        } else {
            api.post("draft", {
                cmd: "backward",
                id: this.props.load.id,
                block: this.props.load.block,
                hl: this.props.load.hl
            }, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            }).then((response) => {
                document.getElementById('draft-load').dataset.load = JSON.stringify(response.data);
                document.getElementById('draft-load').click();
            }, (error) => {
                alert('Erro');
            });
        }
    }

    render() {
        return (
            <div className="modal-footer">
                {
                    parseInt(this.props.load.status) < 2 ?
                        <a className="btn btn-success" onClick={() => this.draft('forward')} data-bs-dismiss="modal">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-upload" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2"></path>
                                <polyline points="7 9 12 4 17 9"></polyline>
                                <line x1={12} y1={4} x2={12} y2={16}></line>
                            </svg>
                            Carregar rascunho
                        </a>
                        :
                        <a className="btn" onClick={() => this.draft('backward')} data-bs-dismiss="modal">
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-infco icon-infco-arrow-back-up" width={24} height={24} viewBox="0 0 24 24" stroke-width={2} stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                                <path d="M9 13l-4 -4l4 -4m-4 4h11a4 4 0 0 1 0 8h-1"></path>
                            </svg>
                            Voltar ao original
                        </a>
                }
                <a className="btn btn-primary" onClick={() => save(this.props, 'draft')} data-bs-dismiss="modal">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-device-floppy" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M6 4h10l4 4v10a2 2 0 0 1 -2 2h-12a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2"></path>
                        <circle cx="12" cy="14" r="2"></circle>
                        <polyline points="14 4 14 8 8 8 8 4"></polyline>
                    </svg>
                    Draft
                </a>
                <a className="btn btn-dark ms-auto" onClick={() => save(this.props, 'publish')} data-bs-dismiss="modal">
                    <svg xmlns="http://www.w3.org/2000/svg" class="icon icon-infco icon-infco-world-upload" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                        <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                        <path d="M21 12a9 9 0 1 0 -9 9"></path>
                        <path d="M3.6 9h16.8"></path>
                        <path d="M3.6 15h8.4"></path>
                        <path d="M11.578 3a17 17 0 0 0 0 18"></path>
                        <path d="M12.5 3c1.719 2.755 2.5 5.876 2.5 9"></path>
                        <path d="M18 21v-7m3 3l-3 -3l-3 3"></path>
                    </svg>
                    Publicar
                </a>
            </div>
        )
    }
}

export default Footer;