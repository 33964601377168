import React from 'react';
import api from "../../../utils/api";
import {notify} from "../../../utils/notify";
import fontag from '../../../utils/fontag';

function Save(props, cmd) {
    var obj = props.load;
    for (var key in obj) {
        if (obj[key] instanceof Array) {
            obj[key] = JSON.stringify(obj[key]);
        }
        if(obj.hasOwnProperty('background')) {
            obj.background = document.querySelectorAll('#block-background-'+obj.id+' input:checked').length > 0 ? document.querySelectorAll('#block-background-'+obj.id+' input:checked')[0].value : "";
        }
        if(document.querySelectorAll('#modal-info-items-tab-'+obj.id+' .active').length) {
            if(obj.block == "Grid" || obj.block == "Swiper") {
                var target = document.querySelectorAll('#modal-info-items-tab-'+obj.id+' .active')[0].dataset.id;
        
                for (var i = 0; i < obj.items.length; i++) {
                    if(obj.items[i].id == target) {
                        obj.items[i].text = document.querySelectorAll('#editor-'+obj.id+' div[contenteditable="true"]')[0].innerHTML;
                    }
                }
            }
        }

    }

    if(obj.title) {
        if(obj.title.indexOf('<font ') === -1) {
            obj.title = '<font color="'+document.querySelector('[name="modal-'+obj.id+'-color-title"]').value+'">'+obj.title+'</font>';
        } else {
            obj.title = fontag(obj.title,'t');
            obj.title = '<font color="'+document.querySelector('[name="modal-'+obj.id+'-color-title"]').value+'">'+obj.title+'</font>'; 
        }
    } else {
        obj.title = "";
    }

    if(obj.description) {
        if(obj.description.indexOf('<font ') === -1) {
            obj.description = '<font color="'+document.querySelector('[name="modal-'+obj.id+'-color-description"]').value+'">'+obj.description+'</font>';
        } else {
            obj.description = fontag(obj.description,'t');
            obj.description = '<font color="'+document.querySelector('[name="modal-'+obj.id+'-color-description"]').value+'">'+obj.description+'</font>';
        }
    } else {
        obj.description = "";
    }

    if(obj.text) {
        if(obj.text.indexOf('<font ') === -1) {
            obj.text = '<font color="'+document.querySelector('[name="modal-'+obj.id+'-color-text"]').value+'">'+obj.text+'</font>';
        } else {
            obj.text = fontag(obj.text,'t');
            obj.text = '<font color="'+document.querySelector('[name="modal-'+obj.id+'-color-text"]').value+'">'+obj.text+'</font>';
        }
    } else {
        obj.text = "";
    }

    const url = (cmd == "publish" ? "publish" : "clone");


    function draft(params) {
        if(params == "forward") {
            api.post("draft", {
                cmd: "forward",
                id: obj.id,
                block: obj.block,
                hl: obj.hl
            }, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            }).then((response) => {
                document.getElementById('draft-load').dataset.load = JSON.stringify(response.data);
                document.getElementById('draft-load').click();
            }, (error) => {
                alert('Erro');
            });
        } else {
            api.post("draft", {
                cmd: "backward",
                id: obj.id,
                block: obj.block,
                hl: obj.hl
            }, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest"
                }
            }).then((response) => {
                document.getElementById('draft-load').dataset.load = JSON.stringify(response.data);
                document.getElementById('draft-load').click();
            }, (error) => {
                alert('Erro');
            });
        }
    }

    notify('loading', 'Aguarde um momento...', Infinity, false)
    switch (obj.block) {
        case "Banners":
            api.post(url, obj, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "cmd": cmd
                }
            }).then((response) => {
                if(cmd != "preview") {
                    draft('backward');
                }
				notify('success', 'Atualizado com sucesso!', 6000, false)
			}, (error) => {
				notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
            });
            break;
        case "Info":
            api.post(url, obj, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "cmd": cmd
                }
            }).then((response) => {
                if(cmd != "preview") {
                    draft('backward');
                }
				notify('success', 'Atualizado com sucesso!', 6000, false)
			}, (error) => {
				notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
            });
            break;
        case "Grid":
            api.post(url, obj, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "cmd": cmd
                }
            }).then((response) => {
                if(cmd != "preview") {
                    draft('backward');
                }
				notify('success', 'Atualizado com sucesso!', 6000, false)
			}, (error) => {
				notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
            });
            break;
        case "Swiper":
            api.post(url, obj, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "cmd": cmd
                }
            }).then((response) => {
                if(cmd != "preview") {
                    draft('backward');
                }
				notify('success', 'Atualizado com sucesso!', 6000, false)
			}, (error) => {
				notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
            });
            break;
        case "Accordion":
            api.post(url, obj, {
                headers: {
                    "X-Requested-With": "XMLHttpRequest",
                    "cmd": cmd
                }
            }).then((response) => {
                if(cmd != "preview") {
                    draft('backward');
                }
				notify('success', 'Atualizado com sucesso!', 6000, false)
			}, (error) => {
				notify('error', 'Algo correu mal! Tente novamente.', 6000, false)
            });

    }
}

export default Save;